// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import idx from 'idx';
import type { RouterHistory } from 'react-router-dom';
import {
  createCompany,
  updateCompany,
  fetchCompaniesIfNeeded,
} from '../../redux/modules/companies';
import CompanyForm from './CompanyForm';

import type { GlobalState } from '../../redux/modules/index';
import type {
  CompaniesState,
  Company,
  CompanyForm as CompanyFormType,
} from '../../redux/modules/companies';

type Dispatch = (action: any) => any;

type StateProps = {
  companies: CompaniesState,
  form: { company: Object },
};

type ActionProps = {
  createCompany: (form: CompanyFormType, successCallback: () => void) => any,
  updateCompany: (companyId: string, form: CompanyFormType, successCallback: () => void) => any,
  fetchCompaniesIfNeeded: () => any,
};

type Props = StateProps &
  ActionProps & {
    t: (key: string, ?Object) => string,
    history: RouterHistory,
    companyId?: string, // eslint-disable-line
  };

class CompanyFormContainer extends Component<Props> {
  constructor(props: Props) {
    super(props);
    props.fetchCompaniesIfNeeded();
  }
  render() {
    const { t, history, companyId, companies } = this.props;
    let initialValues;

    if (companyId) {
      const getCompanyInfo = (): ?Company =>
        idx(companies, _ => _.list.entities.companies[companyId]);

      initialValues = getCompanyInfo();
    }

    return (
      <CompanyForm
        t={t}
        companyId={companyId}
        initialValues={initialValues}
        history={history}
        onSubmit={(form: CompanyFormType) => {
          const successCallback = () => {
            history.push('/admin/companies');
          };

          if (companyId) {
            this.props.updateCompany(companyId, form, successCallback);
          } else {
            this.props.createCompany(form, successCallback);
          }
        }}
      />
    );
  }
}

// $FlowFixMe
const mapStateToProps = (state: GlobalState): StateProps => ({
  form: state.form,
  companies: state.companies,
});

// $FlowFixMe
const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
  createCompany: bindActionCreators(createCompany, dispatch),
  updateCompany: bindActionCreators(updateCompany, dispatch),
  fetchCompaniesIfNeeded: bindActionCreators(fetchCompaniesIfNeeded, dispatch),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(CompanyFormContainer);
