// @flow
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCurrentUser } from '../../../redux/modules/auth';
import { fetchOrderRowDiff } from '../../../redux/modules/orderRows';
import { fetchLocationsIfNeeded } from '../../../redux/modules/locations';
import { fetchOrdersIfNeeded } from '../../../redux/modules/orders';
import OrderRowDiff from './OrderRowDiff';

import type { AuthState } from '../../../redux/modules/auth';
import type { GlobalState } from '../../../redux/modules/index';
import type { OrdersState } from '../../../redux/modules/orders';
import type { OrderRowsState } from '../../../redux/modules/orderRows';
import type { LocationsState } from '../../../redux/modules/locations';

type Dispatch = (action: any) => any;

type StateProps = {
  auth: AuthState,
  orders: OrdersState,
  orderRows: OrderRowsState,
  locations: LocationsState,
};

type ActionProps = {
  fetchOrderRowDiff: (orderRowId: string) => any,
  fetchOrdersIfNeeded: (orderType?: string) => any,
  fetchLocationsIfNeeded: () => any,
  getCurrentUser: () => any,
};

// $FlowFixMe
const mapStateToProps = (state: GlobalState): StateProps => ({
  auth: state.auth,
  orders: state.orders,
  orderRows: state.orderRows,
  locations: state.locations,
});

// $FlowFixMe
const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
  fetchOrderRowDiff: bindActionCreators(fetchOrderRowDiff, dispatch),
  fetchOrdersIfNeeded: bindActionCreators(fetchOrdersIfNeeded, dispatch),
  fetchLocationsIfNeeded: bindActionCreators(fetchLocationsIfNeeded, dispatch),
  getCurrentUser: bindActionCreators(getCurrentUser, dispatch),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(OrderRowDiff);
