// @flow
import React, { PureComponent } from 'react';
import ReactLabels from 'react-labeling';
import type { FieldProps } from 'redux-form';
import cx from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import type { LocationsState, LocationFormType } from '../../../redux/modules/locations';

import styles from './Labels.module.scss';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};

type LabelsProps = FieldProps & {
  t: string => string,
  label: string,
  required: boolean,
  locations: LocationsState,
  passByLocations: Array<*>,
  disabled?: boolean,
  createLocation: (location: LocationFormType, callback?: (locationId: number) => void) => void,
};

type LabelsState = {
  labelMap: Object,
  selectedLabelKeys: Array<*>,
};

class Labels extends PureComponent<LabelsProps, LabelsState> {
  input: any;

  keyInit: boolean;
  mapInit: boolean;

  constructor(props: LabelsProps) {
    super(props);
    this.input = props.input;
    this.keyInit = false;
    this.mapInit = false;
  }

  state = {
    labelMap: {},
    selectedLabelKeys: [],
  };

  componentDidMount() {
    this.setLabelMap(this.props.locations);
    this.setSelectedLabelKeys(this.props.passByLocations);
  }

  componentWillReceiveProps(nextProps: LabelsProps) {
    this.setLabelMap(nextProps.locations);
    this.setSelectedLabelKeys(nextProps.passByLocations);
  }

  isObjectEmpty = (obj: Object) => Object.entries(obj).length === 0 && obj.constructor === Object;

  onLabelSubmit = (selectedLabelKeys: Array<string>, event: any) => {
    event.preventDefault();

    this.setState(
      {
        selectedLabelKeys,
      },
      () => {
        this.input.onChange(this.state.selectedLabelKeys);
      }
    );

    return false;
  };

  onCreateNew = (newLabel: any, event: any) => {
    event.preventDefault();

    this.props.createLocation(
      {
        title: newLabel,
      },
      (newlocationId: number) => {
        const newLabelMap = {
          ...this.state.labelMap,
          [`${newlocationId}`]: newLabel,
        };

        this.setState({
          labelMap: newLabelMap,
        });
      }
    );
  };

  setSelectedLabelKeys = (passByLocations: Array<*>) => {
    if (!this.keyInit && passByLocations) {
      this.setState({
        selectedLabelKeys: passByLocations,
      });

      this.keyInit = true;
    }
  };

  setLabelMap = (propsLocations: Object) => {
    if (!this.mapInit && propsLocations.list.entities) {
      const locations = propsLocations.list.entities.locations;
      const labelMap = {};

      Object.keys(locations).forEach(id => {
        labelMap[`${id}`] = locations[id].title;
      });

      this.setState({
        labelMap,
      });

      this.mapInit = true;
    }
  };

  onClickRemove = (event: SyntheticEvent<HTMLSpanElement>) => {
    const id = event.currentTarget.getAttribute('data-id');
    const selectedLabelKeys = this.state.selectedLabelKeys.filter(value => `${value}` !== id);

    this.setState(
      {
        selectedLabelKeys,
      },
      () => {
        this.input.onChange(this.state.selectedLabelKeys);
      }
    );
  };

  onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const selectedLabelKeys = reorder(
      this.state.selectedLabelKeys,
      result.source.index,
      result.destination.index
    );

    this.setState(
      {
        selectedLabelKeys,
      },
      () => {
        this.input.onChange(this.state.selectedLabelKeys);
      }
    );
  };

  renderSelectedLabels = () => {
    if (!this.state.selectedLabelKeys || !this.state.selectedLabelKeys.length) {
      return null;
    }

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              className={cx({
                [`${styles.rendered}`]: true,
                [`${styles.isDraggingOver}`]: snapshot.isDraggingOver,
              })}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {[...this.state.selectedLabelKeys].map((label, index) => (
                <Draggable
                  key={label}
                  draggableId={label}
                  index={index}
                  isDragDisabled={this.props.disabled}
                >
                  {(draggableProvided, draggableSnapshot) => (
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                      className={cx({
                        [`${styles.tag}`]: true,
                        [`${styles.block}`]: true,
                        [`${styles.isDragging}`]: draggableSnapshot.isDragging,
                      })}
                      key={label}
                    >
                      {this.state.labelMap[label]}
                      {!this.props.disabled && (
                        <span
                          className="fas fa-times remove"
                          onClick={this.onClickRemove}
                          data-id={label}
                          role="button"
                          tabIndex="-1"
                        />
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  render() {
    const {
      label,
      required,
      t,
      meta: { touched, error, warning },
    } = this.props;

    return (
      <div className={this.props.disabled ? styles.locationsDisabled : styles.locations}>
        <label>{label}</label>
        {required && <span className={styles.required}>*</span>}
        <div className={styles.panel}>
          {!this.isObjectEmpty(this.state.labelMap) && this.renderSelectedLabels()}
          {!this.isObjectEmpty(this.state.labelMap) && (
            <div className={styles.labeling}>
              <ReactLabels
                labelMap={this.state.labelMap}
                selectedLabelKeys={this.state.selectedLabelKeys}
                onSubmit={this.onLabelSubmit}
                onCreateNew={this.onCreateNew}
                inputPlaceholder={t('orderRowForm.placeholder.passByLocations')}
                textOnSubmit={t('orderRowForm.locationsSubmit')}
                textOnCreateNew={t('orderRowForm.locationCreate')}
              />
            </div>
          )}
        </div>
        {touched &&
          ((error && <span className={styles.error}>{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    );
  }
}

export default Labels;
