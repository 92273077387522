//@flow
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCurrentUser } from '../../../redux/modules/auth';
import {
  fetchOrdersIfNeeded,
  deleteOrder,
  fetchOrdersImmedaitely,
} from '../../../redux/modules/orders';
import { fetchOrderRowsIfNeeded } from '../../../redux/modules/orderRows';
import Orders from './Orders';

import type { GlobalState } from '../../../redux/modules/index';
import type { OrdersState } from '../../../redux/modules/orders';
import type { OrderRowsState } from '../../../redux/modules/orderRows';
import type { AuthState } from '../../../redux/modules/auth';

type Dispatch = (action: any) => any;

type StateProps = {
  orders: OrdersState,
  orderRows: OrderRowsState,
  auth: AuthState,
};

type ActionProps = {
  fetchOrdersIfNeeded: (orderType?: string) => any,
  fetchOrdersImmedaitely: (orderType?: string) => any,
  fetchOrderRowsIfNeeded: (orderId: string) => any,
  deleteOrder: (orderId: string) => any,
  getCurrentUser: () => any,
};

// $FlowFixMe
const mapStateToProps = (state: GlobalState): StateProps => ({
  orders: state.orders,
  orderRows: state.orderRows,
  auth: state.auth,
});

// $FlowFixMe
const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
  fetchOrdersIfNeeded: bindActionCreators(fetchOrdersIfNeeded, dispatch),
  fetchOrdersImmedaitely: bindActionCreators(fetchOrdersImmedaitely, dispatch),
  fetchOrderRowsIfNeeded: bindActionCreators(fetchOrderRowsIfNeeded, dispatch),
  deleteOrder: bindActionCreators(deleteOrder, dispatch),
  getCurrentUser: bindActionCreators(getCurrentUser, dispatch),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(Orders);
