// @flow
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import type { FormProps } from 'redux-form';
import type { RouterHistory } from 'react-router-dom';
import idx from 'idx';

import DateField from '../../Shared/DateField';
import InputField from '../../Shared/InputField';
import SelectInputField from '../../Shared/SelectInputField';
import NoteField from '../../Shared/NoteField';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';

import { TGK_OPERATORS, HOTEL_ADMINS } from '../../../utils/jobTitleMapping';
import allowOnly from '../../../utils/allowOnly';

import type { CompaniesState } from '../../../redux/modules/companies';

import styles from './OrderForm.module.scss';

const validate = values => {
  const errors = {};

  if (!values.title) {
    errors.title = '必須項目です';
  }

  if (!values.fromTime) {
    errors.fromTime = '必須項目です';
  }

  return errors;
};

type DropdownItem = {
  value: any,
  text: string,
};

const hotelCompanyList = (companies: CompaniesState): Array<DropdownItem> => {
  const companiesListItems = idx(companies, _ => _.list.entities.companies);

  if (companiesListItems) {
    return Object.keys(companiesListItems).reduce((acc: any, cur: string) => {
      // id 3 is hotel
      if (companiesListItems[cur].companyType.id === 3) {
        return acc.concat({
          text: companiesListItems[cur].name,
          value: companiesListItems[cur].id,
        });
      }

      return [...acc];
    }, []);
  }

  return [];
};

type OrderFormProps = FormProps & {
  t: string => string,
  orderId?: string,
  history: RouterHistory,
};

const Form = (props: OrderFormProps) => {
  const { t, handleSubmit, reset, history, orderId, pristine, submitting, auth, companies } = props;

  const handleCancel = () => {
    reset();
    history.push('/orders');
  };

  const currentJobTitle = idx(auth, _ => _.currentUser.jobTypeTitle);
  const companyOptions = hotelCompanyList(companies);
  const fromTimeOptions = orderId
    ? {
        allowInput: false,
        dateFormat: 'Y/m/d',
        enableTime: false,
      }
    : {
        minDate: 'today',
        allowInput: false,
        dateFormat: 'Y/m/d',
        enableTime: false,
      };
  const breadcrumbsItems = [
    {
      link: '/orders',
      text: t('menu.orderList'),
    },
    {
      link: '',
      text: orderId ? t('orderForm.edit') : t('orderForm.create'),
    },
  ];

  return (
    <div>
      <Breadcrumbs items={breadcrumbsItems} />
      <form onSubmit={handleSubmit} className={styles.form}>
        <section className="form-title">
          <h2 className="title">{orderId ? t('orderForm.edit') : t('orderForm.create')}</h2>
          <p className="sub">全てご記入ください</p>
        </section>
        {allowOnly(currentJobTitle, TGK_OPERATORS) && !orderId ? (
          <fieldset>
            <Field
              name="title"
              component={SelectInputField}
              type="text"
              valueField="value"
              textField="text"
              options={companyOptions}
              label={t('orderForm.title')}
              placeholder={t('orderForm.placeholder.title')}
              required
              disabled={!allowOnly(currentJobTitle, TGK_OPERATORS)}
            />
          </fieldset>
        ) : (
          <fieldset>
            <Field
              name="title"
              component={InputField}
              type="text"
              label={t('orderForm.title')}
              placeholder={t('orderForm.placeholder.title')}
              required
              disabled={!allowOnly(currentJobTitle, HOTEL_ADMINS)}
            />
          </fieldset>
        )}
        <fieldset>
          <Field
            name="subtitle"
            component={InputField}
            type="text"
            label={t('orderForm.subtitle')}
            placeholder={t('orderForm.placeholder.subtitle')}
            disabled={!allowOnly(currentJobTitle, HOTEL_ADMINS)}
          />
        </fieldset>
        <fieldset>
          <Field
            name="fromTime"
            component={DateField}
            type="text"
            label={t('orderForm.fromTime')}
            placeholder={t('orderForm.placeholder.fromTime')}
            required
            disabled={!allowOnly(currentJobTitle, HOTEL_ADMINS)}
            options={fromTimeOptions}
          />
        </fieldset>
        <fieldset>
          <Field
            name="note"
            label={t('orderForm.note')}
            placeholder={t('orderForm.placeholder.note')}
            component={NoteField}
            disabled={!allowOnly(currentJobTitle, HOTEL_ADMINS)}
          />
        </fieldset>
        <div className="btn-group">
          <button className="btn" onClick={handleCancel} type="button">
            {t('button.cancel')}
          </button>
          <button
            className="btn primary"
            onSubmit={handleSubmit}
            type="submit"
            disabled={pristine || submitting}
          >
            {t('button.submit')}
          </button>
        </div>
      </form>
    </div>
  );
};

const OrderForm = reduxForm({
  // a unique name for the form
  form: 'order',
  validate,
  enableReinitialize: true, // Enble re-initialize for default title
})(Form);

export default OrderForm;
