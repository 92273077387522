// @flow
import React from 'react';
import DropdownList from 'react-widgets/lib/DropdownList';
import type { Node } from 'react';
import type { FieldProps } from 'redux-form';

import styles from './Shared.module.scss';

type Props = FieldProps & {
  className?: string,
  input: any,
  label: string,
  valueField: string,
  textField: string,
  placeholder: string,
  data: Array<*>,
  required: boolean,
  disabled: boolean,
  actionLink?: ?Function,
};

const SelectField = ({
  className,
  input,
  label,
  placeholder,
  data,
  valueField,
  textField,
  meta: { touched, error, warning },
  required,
  disabled,
  actionLink,
}: Props): Node => (
  <fieldset className={className}>
    <label>
      {label}
      {!required && !disabled && <span>（任意）</span>}
    </label>
    {actionLink && actionLink()}
    <DropdownList
      {...input}
      data={data}
      valueField={valueField}
      textField={textField}
      onChange={input.onChange}
      placeholder={placeholder}
      disabled={disabled}
    />
    {touched &&
      ((error && <span className={styles.error}>{error}</span>) ||
        (warning && <span>{warning}</span>))}
  </fieldset>
);

export default SelectField;
