// @flow
import React, { Fragment } from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import type { Match, Location } from 'react-router';
import { I18n } from 'react-i18next';
import type { Element } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import history from '../utils/history';
/* Components */
import Login from './Login/LoginContainer';
import Header from './Header/HeaderContainer';
import Menu from './Menu/MenuContainer';
import Admin from './Admin/Admin';
import Orders from './Order/Orders/OrdersContainer';
import OrderRows from './Order/OrderRows/OrderRowsContainer';
import OrderForm from './Order/OrderForm/OrderFormContainer';
import OrderRowForm from './Order/OrderRowForm/OrderRowFormContainer';
import ShuttleOrderRowForm from './Order/OrderRowForm/ShuttleOrderRowFormContainer';
import OrderRowType from './Order/OrderRowForm/OrderRowType';
import OrderRowDiff from './Order/OrderRowDiff/OrderRowDiffContainer';
import Account from './Account/AccountContainer';

import styles from './App.module.scss';

type ContentProps = {
  t: string => string,
  location: Location,
};

type MatchProps = {
  match: Match,
};

const Content = ({ t, location }: ContentProps): React$Node => (
  <Fragment>
    <Header t={t} />
    <Menu location={location} t={t} />
    <div className={styles.content}>
      <Route exact path="/orders" render={() => <Orders t={t} />} />
      <Route exact path="/orders/past" render={() => <Orders t={t} orderType="past" />} />
      <Route exact path="/orders/canceled" render={() => <Orders t={t} orderType="canceled" />} />
      <Route
        exact
        path="/orders/:orderId/rows"
        render={(props: MatchProps) => <OrderRows orderId={props.match.params.orderId} t={t} />}
      />
      <Route
        exact
        path="/orders/past/:orderId/rows"
        render={(props: MatchProps) => (
          <OrderRows orderId={props.match.params.orderId} t={t} orderType="past" />
        )}
      />
      <Route
        exact
        path="/orders/canceled/:orderId/rows"
        render={(props: MatchProps) => (
          <OrderRows orderId={props.match.params.orderId} t={t} orderType="canceled" />
        )}
      />
      <Route exact path="/orders/create" render={() => <OrderForm history={history} t={t} />} />
      <Route
        exact
        path="/orders/edit/:orderId"
        render={(props: MatchProps) => (
          <OrderForm history={history} orderId={props.match.params.orderId} t={t} />
        )}
      />
      <Route
        exact
        path="/orders/past/edit/:orderId"
        render={(props: MatchProps) => (
          <OrderForm
            history={history}
            orderId={props.match.params.orderId}
            t={t}
            orderType="past"
          />
        )}
      />
      <Route
        exact
        path="/orders/canceled/edit/:orderId"
        render={(props: MatchProps) => (
          <OrderForm
            history={history}
            orderId={props.match.params.orderId}
            t={t}
            orderType="canceled"
          />
        )}
      />
      <Route
        exact
        path="/orders/:orderId/rows/create"
        render={(props: MatchProps) => (
          <OrderRowType history={history} orderId={props.match.params.orderId} t={t} />
        )}
      />
      <Route
        exact
        path="/orders/:orderId/rows/edit/:rowId"
        render={(props: MatchProps) => (
          <OrderRowForm
            history={history}
            orderId={props.match.params.orderId}
            rowId={props.match.params.rowId}
            t={t}
          />
        )}
      />
      <Route
        exact
        path="/orders/:orderId/rows/edit/:rowId/shuttle"
        render={(props: MatchProps) => (
          <ShuttleOrderRowForm
            history={history}
            orderId={props.match.params.orderId}
            rowId={props.match.params.rowId}
            t={t}
          />
        )}
      />
      <Route
        exact
        path="/orders/:orderId/rows/diff/:rowId"
        render={(props: MatchProps) => (
          <OrderRowDiff
            history={history}
            orderId={props.match.params.orderId}
            rowId={props.match.params.rowId}
            t={t}
          />
        )}
      />
      <Route
        exact
        path="/orders/past/:orderId/rows/diff/:rowId"
        render={(props: MatchProps) => (
          <OrderRowDiff
            history={history}
            orderId={props.match.params.orderId}
            rowId={props.match.params.rowId}
            t={t}
            orderType="past"
          />
        )}
      />
      <Route
        exact
        path="/orders/canceled/:orderId/rows/diff/:rowId"
        render={(props: MatchProps) => (
          <OrderRowDiff
            history={history}
            orderId={props.match.params.orderId}
            rowId={props.match.params.rowId}
            t={t}
            orderType="canceled"
          />
        )}
      />
    </div>
  </Fragment>
);

const AdminContent = ({ t, location }: ContentProps): React$Node => (
  <Fragment>
    <Header t={t} />
    <Menu location={location} t={t} />
    <div className={styles.content}>
      <Route path="/admin" render={() => <Admin t={t} />} />
    </div>
  </Fragment>
);

type AccountPageProps = {
  t: string => string,
  location: Location,
};

const AccountPage = ({ t, location }: AccountPageProps): React$Node => (
  <Fragment>
    <Header t={t} />
    <Menu location={location} t={t} />
    <div className={styles.content}>
      <Account history={history} t={t} location={location} />
    </div>
  </Fragment>
);

function App(): Element<I18n> {
  return (
    <I18n>
      {t => (
        <Router history={history}>
          <Fragment>
            <Switch>
              <Route exact path="/login" render={() => <Login t={t} />} />
              <Route
                path="/orders"
                render={({ location }) => <Content t={t} location={location} />}
              />
              <Route
                path="/admin"
                render={({ location }) => <AdminContent t={t} location={location} />}
              />
              <Route
                path="/account"
                render={({ location }) => <AccountPage t={t} location={location} />}
              />
              <Redirect to="/orders" />
            </Switch>
            <ToastContainer />
          </Fragment>
        </Router>
      )}
    </I18n>
  );
}

export default App;
