// @flow

import React from 'react';
import { Field, reduxForm } from 'redux-form';

import type { Element } from 'react';
import type { FormProps, FieldProps } from 'redux-form';
import type { RouterHistory } from 'react-router-dom';

import styles from './Account.module.scss';

const validate = values => {
  const errors = {};

  if (!values.password) {
    errors.password = '必須項目です';
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = '必須項目です';
  }

  if (values.password && values.password !== values.confirmPassword && values.confirmPassword) {
    errors.password = 'パスオートが異なります';
    errors.confirmPassword = 'パスオートが異なります';
  }

  return errors;
};

type InputFieldProps = FieldProps & {
  label: string,
  placeholder: string,
  type: string,
  required: boolean,
};

const renderField = ({
  input,
  label,
  placeholder,
  type,
  meta: { touched, error, warning },
  required,
}: InputFieldProps): Element<'div'> => (
  <div>
    <label>
      {label}
      {!required && <span>（任意）</span>}
    </label>
    <div>
      <input {...input} placeholder={placeholder} type={type} />
      {touched &&
        ((error && <span className="error">{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
  </div>
);

type Props = FormProps & {
  history: RouterHistory,
};

const Form = (props: Props): Element<'form'> => {
  const { t, handleSubmit, reset, history, pristine, submitting } = props;

  const handleCancel = () => {
    reset();
    history.push('/');
  };

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <section className="form-title">
        <h2 className="title">{t('account.changePassword')}</h2>
        <p className="sub">全てご記入ください</p>
      </section>
      <fieldset>
        <Field
          name="password"
          component={renderField}
          type="password"
          label={t('account.password')}
          placeholder={t('account.placeholder.password')}
          required
        />
      </fieldset>
      <fieldset>
        <Field
          name="confirmPassword"
          component={renderField}
          type="password"
          label={t('account.confirmPassword')}
          placeholder={t('account.placeholder.confirmPassword')}
          required
        />
      </fieldset>
      <div className="btn-group">
        <button className="btn" onClick={handleCancel} type="button">
          {t('button.cancel')}
        </button>
        <button
          className="btn warning"
          onSubmit={handleSubmit}
          type="submit"
          disabled={pristine || submitting}
        >
          {t('account.updatePassword')}
        </button>
      </div>
    </form>
  );
};

const ChangePasswordForm = reduxForm({
  // a unique name for the form
  form: 'password',
  validate,
})(Form);

export default ChangePasswordForm;
