// @flow
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';

import type { Element } from 'react';
import type { FormProps } from 'redux-form';
import type { RouterHistory } from 'react-router-dom';

import InputField from '../Shared/InputField';
import SelectField from '../Shared/SelectField';
import DefaultPassword from '../DefaultPassword/DefaultPassword';
import allowOnly from '../../utils/allowOnly';
import jobTitleMapping, { ADMINS } from '../../utils/jobTitleMapping';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

import type { TypesState } from '../../redux/modules/types';
import type { CompaniesState } from '../../redux/modules/companies';

import styles from './UserForm.module.scss';

type DropDownItem = {
  text: string,
  value: any,
};

const companyList = (companies: CompaniesState): Array<DropDownItem> => {
  const entities = companies.list.entities;

  if (entities) {
    return Object.keys(entities.companies).map(id => ({
      text: entities.companies[id].name,
      value: entities.companies[id].id,
    }));
  }

  return [];
};

const jobList = (
  t: string => string,
  hasCompanyId?: any,
  types: TypesState,
  companies: CompaniesState
) => {
  let companyId;
  let companyTypeId;
  let jobTypes;

  if (hasCompanyId && companies.list.entities && types.jobTypesByCompanies.entities) {
    if (hasCompanyId.value) {
      companyId = hasCompanyId.value;
    } else {
      companyId = hasCompanyId;
    }

    companyTypeId = companies.list.entities.companies[companyId].companyType.id;
    jobTypes = types.jobTypesByCompanies.entities[companyTypeId];

    return Object.keys(jobTypes).map((id: string) => ({
      text: jobTitleMapping(jobTypes[id].id, t),
      value: jobTypes[id].id,
    }));
  }

  return [];
};

type Props = FormProps & {
  userId?: string,
  history: RouterHistory,
  hasCompanyId?: {
    text: string,
    value: any,
  },
  companies: CompaniesState,
  types: TypesState,
};

let Form = (props: Props): Element<'div'> => {
  const {
    t,
    handleSubmit,
    reset,
    history,
    companies,
    userId,
    pristine,
    submitting,
    hasCompanyId,
    hasJobTypeId,
    types,
  } = props;

  const handleCancel = () => {
    reset();
    history.push('/admin/users');
  };

  const companyOptions = companyList(companies);
  const jobOptions = jobList(t, hasCompanyId, types, companies);
  const jobTypeId = hasJobTypeId ? hasJobTypeId.value || hasJobTypeId : undefined;
  const breadcrumbsItems = [
    {
      link: '/admin/users',
      text: t('users.userList'),
    },
    {
      link: '',
      text: userId ? t('userForm.edit') : t('userForm.create'),
    },
  ];

  return (
    <div>
      <Breadcrumbs items={breadcrumbsItems} />
      <form onSubmit={handleSubmit} className={styles.form}>
        <section className="form-title">
          <h2 className="title">{userId ? t('userForm.edit') : t('userForm.create')}</h2>
          <p className="sub">全てご記入ください</p>
        </section>
        <fieldset>
          <Field
            name="company.id"
            component={SelectField}
            data={companyOptions}
            valueField="value"
            textField="text"
            label={t('userForm.company')}
            placeholder={t('userForm.placeholder.company')}
            required
            onChange={(e, newValue, previousValue) => {
              const newId = (newValue && newValue.value) || newValue;
              const previousId = (previousValue && previousValue.value) || previousValue;

              if (newId !== previousId) {
                // Reset the jobType Id
                props.change('jobType.id', null);
              }
            }}
          />
        </fieldset>
        <fieldset>
          <Field
            name="jobType.id"
            component={SelectField}
            data={jobOptions}
            valueField="value"
            textField="text"
            label={t('userForm.jobType')}
            placeholder={t('userForm.placeholder.jobType')}
            disabled={!hasCompanyId}
            required
          />
        </fieldset>
        {jobTypeId && (
          <section>
            <fieldset>
              <Field
                name="email"
                component={InputField}
                type="text"
                label={t('userForm.email')}
                placeholder={t('userForm.placeholder.email')}
                required={allowOnly(jobTitleMapping(jobTypeId), ADMINS)}
              />
            </fieldset>
            <fieldset>
              <Field
                name="lastName"
                component={InputField}
                type="text"
                label={t('userForm.lastName')}
                placeholder={t('userForm.placeholder.lastName')}
                required
              />
            </fieldset>
            <fieldset>
              <Field
                name="firstName"
                component={InputField}
                type="text"
                label={t('userForm.firstName')}
                placeholder={t('userForm.placeholder.firstName')}
                required
              />
            </fieldset>
            <fieldset>
              <Field
                name="mobilePhone"
                component={InputField}
                type="number"
                label={t('userForm.mobilePhone')}
                placeholder={t('userForm.placeholder.mobilePhone')}
                required
              />
            </fieldset>
            <fieldset>
              <Field
                name="localPhone"
                component={InputField}
                type="number"
                label={t('userForm.localPhone')}
                placeholder={t('userForm.placeholder.localPhone')}
              />
            </fieldset>
          </section>
        )}
        <div className="btn-group">
          <button className="btn" onClick={handleCancel} type="button">
            {t('button.cancel')}
          </button>
          <button
            className="btn primary"
            onSubmit={handleSubmit}
            type="submit"
            disabled={pristine || submitting}
          >
            {t('button.submit')}
          </button>
        </div>
      </form>
    </div>
  );
};

Form = reduxForm({
  // a unique name for the form
  form: 'user',
})(Form);

// Decorate with connect to read form values
const selector = formValueSelector('user'); // <-- same as form name

const UserForm = connect(state => {
  // can select values individually
  const hasCompanyId = selector(state, 'company.id');
  const hasJobTypeId = selector(state, 'jobType.id');

  return {
    hasCompanyId,
    hasJobTypeId,
  };
})(Form);

type UserFromWrapperProps = Props & {
  t: string => string,
  password: string,
};

const UserFormWrapper = (props: UserFromWrapperProps) => (
  <div>
    {props.password ? (
      <DefaultPassword
        password={props.password}
        t={props.t}
        done={() => {
          props.history.push('/admin/users');
        }}
      />
    ) : (
      <UserForm {...props} />
    )}
  </div>
);

export default UserFormWrapper;
