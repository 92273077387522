// @flow
import React from 'react';
import format from 'date-fns/format';
import jaLocale from 'date-fns/locale/ja';

import styles from '../OrderRows/OrderRows.module.scss';

type FlightCellProps = {
  t: string => string,
  data: any,
};

const FlightCell = ({ t, data }: FlightCellProps) => {
  const render = (no, status, time) =>
    `${status ? t(`orderRow.${status}`) : t('orderRow.none')} / ${no || t('orderRow.none')} / ${
      time ? format(new Date(time), 'YYYY-MM-DD HH:mm', { locale: jaLocale }) : t('orderRow.none')
    }`;
  const flightNoDiff = data.diff && data.diff.flightNo;
  const flightStatusDiff = data.diff && data.diff.flightStatus;
  const flightArrivalTimeDiff = data.diff && data.diff.flightArrivalTime;
  const flightNoBefore =
    flightNoDiff && flightNoDiff.before !== undefined ? flightNoDiff.before : data.flightNo;
  const flightNoAfter =
    flightNoDiff && flightNoDiff.after !== undefined ? flightNoDiff.after : data.flightNo;
  const flightStatusBefore =
    flightStatusDiff && flightStatusDiff.before !== undefined
      ? flightStatusDiff.before
      : data.flightStatus;
  const flightStatusAfter =
    flightStatusDiff && flightStatusDiff.after !== undefined
      ? flightStatusDiff.after
      : data.flightStatus;
  const flightArrivalTimeBefore =
    flightArrivalTimeDiff && flightArrivalTimeDiff.before !== undefined
      ? flightArrivalTimeDiff.before
      : data.flightArrivalTime;
  const flightArrivalTimeAfter =
    flightArrivalTimeDiff && flightArrivalTimeDiff.after !== undefined
      ? flightArrivalTimeDiff.after
      : data.flightArrivalTime;

  if (flightNoDiff || flightStatusDiff || flightArrivalTimeDiff) {
    return (
      <div className={styles.diff}>
        <div className={`${styles.cell} ${styles.before}`}>
          {render(flightNoBefore, flightStatusBefore, flightArrivalTimeBefore)}
        </div>
        <div className={`${styles.cell} ${styles.after}`}>
          {render(flightNoAfter, flightStatusAfter, flightArrivalTimeAfter)}
        </div>
      </div>
    );
  }

  return render(data.flightNo, data.flightStatus, data.flightArrivalTime);
};

export default FlightCell;
