// @flow
/* eslint no-use-before-define: 0 */

import axios from 'axios';
import { normalize, schema } from 'normalizr';
import { responseType, responseHandler, catchHandler } from '../modules/auth';
import baseUrl from '../../utils/baseUrl';

import type { GetState, ThunkAction, Dispatch } from './index';

type InitLocationsState = {
  isFetching: boolean,
  isPosting: boolean,
  didInvalidate: boolean,
  list: [],
};

export type LocationsState = {
  isFetching: boolean,
  isPosting: boolean,
  didInvalidate: boolean,
  list: NormalizedLocations,
};

export type Location = {
  createdAt: number,
  id: number,
  isDeleted: boolean,
  title: string,
  updatedAt: number,
};

export type LocationData = {
  createdAt?: number,
  updatedAt: number,
  title: string,
};

export type LocationFormType = {
  title: string,
};

export type LocationsAction =
  | RequestLocationsAction
  | ReceiveLocationsAction
  | InvalidLocationsAction
  | RequestCreateLocationAction
  | RequestUpdateLocationAction
  | CreateLocationSuccessAction
  | UpdateLocationSuccessAction;

const REQUEST_LOCATIONS = '@@TGK/ORDERS/REQUEST_LOCATIONS';
const RECEIVE_LOCATIONS = '@@TGK/ORDERS/RECEIVE_LOCATIONS';
const INVALIDATE_LOCATIONS = '@@TGK/ORDERS/INVALIDATE_LOCATIONS';
const REQUEST_CREATE_LOCATION = '@@TGK/ORDERS/REQUEST_CREATE_LOCATION';
const CREATE_LOCATION_SUCCESS = '@@TGK/ORDERS/CREATE_LOCATION_SUCCESS';
const REQUEST_UPDATE_LOCATION = '@@TGK/ORDERS/REQUEST_UPDATE_LOCATION';
const UPDATE_LOCATION_SUCCESS = '@@TGK/ORDERS/UPDATE_LOCATION_SUCCESS';

type NormalizedLocations = {
  entities: {
    locations: {
      [string | number]: Location,
    },
  },
  result: Array<any>,
};

const normalizer = (data: Array<Location>): NormalizedLocations => {
  const locationsSchema = new schema.Entity('locations');
  const locationsArray = new schema.Array(locationsSchema);

  return normalize(data, locationsArray);
};

const reducer = (
  state: InitLocationsState = {
    isFetching: false,
    isPosting: false,
    didInvalidate: false,
    list: [],
  },
  action: LocationsAction
) => {
  switch (action.type) {
    case INVALIDATE_LOCATIONS: {
      return Object.assign({}, state, {
        didInvalidate: true,
      });
    }
    case REQUEST_LOCATIONS: {
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    }
    case RECEIVE_LOCATIONS: {
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        list: normalizer(action.data),
      });
    }
    case REQUEST_CREATE_LOCATION:
    case REQUEST_UPDATE_LOCATION: {
      return Object.assign({}, state, {
        isPosting: true,
      });
    }
    case CREATE_LOCATION_SUCCESS:
    case UPDATE_LOCATION_SUCCESS: {
      return Object.assign({}, state, {
        isPosting: false,
      });
    }
    default: {
      return state;
    }
  }
};

type RequestLocationsAction = {
  type: '@@TGK/ORDERS/REQUEST_LOCATIONS',
};

const requestLocations = (): RequestLocationsAction => ({
  type: REQUEST_LOCATIONS,
});

type ReceiveLocationsAction = {
  type: '@@TGK/ORDERS/RECEIVE_LOCATIONS',
  data: Array<Location>,
};

const receiveLocations = (data: Array<Location>): ReceiveLocationsAction => ({
  type: RECEIVE_LOCATIONS,
  data,
});

type InvalidLocationsAction = {
  type: '@@TGK/ORDERS/INVALIDATE_LOCATIONS',
};

export const invalidateLocations = (): InvalidLocationsAction => ({
  type: INVALIDATE_LOCATIONS,
});

const fetchLocations = () => (dispatch: Dispatch) => {
  dispatch(requestLocations());

  return axios
    .get(`${baseUrl.host}/api/locations`, { withCredentials: true })
    .then(response => {
      if (dispatch(responseHandler(response)) === responseType.SUCCESS) {
        dispatch(receiveLocations(response.data));
      }
    })
    .catch(error => {
      catchHandler(error);
    });
};

const shouldFetchLocations = (locations: LocationsState | InitLocationsState): boolean => {
  if (locations.list.length === 0) {
    return true;
  } else if (locations.isFetching) {
    return false;
  }

  return locations.didInvalidate;
};

export const fetchLocationsIfNeeded = (): ThunkAction => (
  dispatch: Dispatch,
  getState: GetState
) => {
  if (shouldFetchLocations(getState().locations)) {
    return dispatch(fetchLocations());
  }

  return Promise.resolve();
};

type RequestCreateLocationAction = {
  type: '@@TGK/ORDERS/REQUEST_CREATE_LOCATION',
};

const requestCreateLocation = (): RequestCreateLocationAction => ({
  type: REQUEST_CREATE_LOCATION,
});

type CreateLocationSuccessAction = {
  type: '@@TGK/ORDERS/CREATE_LOCATION_SUCCESS',
};

const createLocationSuccess = (): CreateLocationSuccessAction => ({
  type: CREATE_LOCATION_SUCCESS,
});

export const createLocation = (
  form: LocationFormType,
  onSuccessCallback?: (locationId: number) => void
) => (dispatch: Dispatch) => {
  dispatch(requestCreateLocation());
  const data: LocationData = {
    title: form.title,
    createdAt: +new Date(), // Only create need this
    updatedAt: +new Date(),
  };

  return axios
    .post(`${baseUrl.host}/api/locations`, data, { withCredentials: true })
    .then(response => {
      if (dispatch(responseHandler(response)) === responseType.SUCCESS) {
        dispatch(invalidateLocations());
        dispatch(createLocationSuccess());

        if (onSuccessCallback) {
          onSuccessCallback(response.data.id);
        }
      }
    })
    .catch(error => {
      catchHandler(error);
    });
};

type RequestUpdateLocationAction = {
  type: '@@TGK/ORDERS/REQUEST_UPDATE_LOCATION',
};

const requestUpdateLocation = (): RequestUpdateLocationAction => ({
  type: REQUEST_UPDATE_LOCATION,
});

type UpdateLocationSuccessAction = {
  type: '@@TGK/ORDERS/UPDATE_LOCATION_SUCCESS',
};

const updateLocationSuccess = (): UpdateLocationSuccessAction => ({
  type: UPDATE_LOCATION_SUCCESS,
});

export const updateLocation = (
  id: string,
  form: LocationFormType,
  onSuccessCallback?: () => void
) => (dispatch: Dispatch) => {
  dispatch(requestUpdateLocation());
  const data: LocationData = {
    title: form.title,
    updatedAt: +new Date(),
  };

  return axios
    .put(`${baseUrl.host}/api/locations/${id}`, data, { withCredentials: true })
    .then(response => {
      if (dispatch(responseHandler(response)) === responseType.SUCCESS) {
        dispatch(invalidateLocations());
        dispatch(updateLocationSuccess());

        if (onSuccessCallback) {
          onSuccessCallback();
        }
      }
    })
    .catch(error => {
      catchHandler(error);
    });
};

export const deleteLocation = (id: string) => (dispatch: Dispatch) =>
  axios
    .delete(`${baseUrl.host}/api/locations/${id}`, { withCredentials: true })
    .then(response => {
      if (dispatch(responseHandler(response)) === responseType.SUCCESS) {
        dispatch(invalidateLocations());
        dispatch(fetchLocationsIfNeeded());
      }
    })
    .catch(error => {
      console.error(`Delete location ${id} failed`, error);
    });

export default reducer;
