// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import type { RouterHistory, Location } from 'react-router';
import idx from 'idx';
import { toast } from 'react-toastify';
import { updatePersonalInfo, updatePassword } from '../../redux/modules/users';
import { getCurrentUser, setUserLocalStorage } from '../../redux/modules/auth';
import Account from './Account';
import ChangePassword from './ChangePassword';

import type { GlobalState } from '../../redux/modules/index';
import type { CompaniesState } from '../../redux/modules/companies';
import type { TypesState } from '../../redux/modules/types';
import type { User, UserForm as UserFormType } from '../../redux/modules/users';
import type { AuthState } from '../../redux/modules/auth';

type Dispatch = (action: any) => any;

type ActionProps = {
  updatePersonalInfo: (id: string, form: UserFormType, successCallback: () => void) => any,
  updatePassword: (id: string, form: any, successCallback: () => void) => any,
  getCurrentUser: () => any,
  setUserLocalStorage: (user: User) => any,
};

type StateProps = {
  companies: CompaniesState,
  types: TypesState,
  auth: AuthState,
};

type Props = StateProps &
  ActionProps & {
    t: string => string,
    history: RouterHistory,
    location: Location,
  };

const AccountContainer = (props: Props) => {
  const { t, history, companies, types, auth, location } = props;
  const currentUser = idx(auth, _ => _.currentUser);
  let initialValues;

  if (currentUser) {
    initialValues = currentUser;
  }

  return location.pathname === '/account/changepw' ? (
    <ChangePassword
      t={t}
      history={history}
      onSubmit={form => {
        if (currentUser) {
          const successCallback = () => {
            toast.success(t('account.updateSuccess'));
            history.push('/');
          };

          props.updatePassword(currentUser.id, form, successCallback);
        }
      }}
    />
  ) : (
    <Account
      t={t}
      companies={companies}
      types={types}
      initialValues={initialValues}
      history={history}
      changePassword={location.pathname === '/account/changepw'}
      onSubmit={form => {
        if (currentUser) {
          const successCallback = () => {
            toast.success(t('account.updateSuccess'));
            // Update localStorage currentUser and store
            props.setUserLocalStorage(form);
            props.getCurrentUser();
            history.push('/');
          };

          props.updatePersonalInfo(currentUser.id, form, successCallback);
        }
      }}
    />
  );
};

// $FlowFixMe
const mapStateToProps = (state: GlobalState): StateProps => ({
  companies: state.companies,
  types: state.types,
  auth: state.auth,
});

// $FlowFixMe
const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
  updatePersonalInfo: bindActionCreators(updatePersonalInfo, dispatch),
  updatePassword: bindActionCreators(updatePassword, dispatch),
  getCurrentUser: bindActionCreators(getCurrentUser, dispatch),
  setUserLocalStorage: bindActionCreators(setUserLocalStorage, dispatch),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(AccountContainer);
