// @flow
import React from 'react';
import { Field, reduxForm } from 'redux-form';
// types
import type { Element } from 'react';
import type { FormProps } from 'redux-form';

import InputField from '../Shared/InputField';

import styles from './Login.module.scss';

type FormValues = {
  email: any,
  password: any,
};

type ErrorValues = {
  email?: '必須項目です' | 'メールアドレスを正しく入力してください',
  password?: '必須項目です',
};

const validate = (values: FormValues): ErrorValues => {
  const errors = {};

  if (!values.password) {
    errors.password = '必須項目です';
  }

  if (!values.email) {
    errors.email = '必須項目です';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'メールアドレスを正しく入力してください';
  }

  return errors;
};

type Props = FormProps & {
  t: (key: string, ?Object) => string,
};

const Form = ({ t, handleSubmit, pristine, submitting }: Props): Element<'div'> => {
  return (
    <div className={styles.root}>
      <form onSubmit={handleSubmit} className={styles.form}>
        <section className="form-title">
          <h2 className="title">{t('button.login')}</h2>
          <p className="sub">全てご記入ください</p>
        </section>
        <fieldset>
          <Field
            id="email"
            name="email"
            component={InputField}
            type="text"
            label={t('signin.email')}
            placeholder={t('signin.placeholder.email')}
            required
          />
        </fieldset>
        <fieldset>
          <Field
            id="password"
            name="password"
            component={InputField}
            type="password"
            label={t('signin.password')}
            placeholder={t('signin.placeholder.password')}
            required
          />
        </fieldset>
        <div className="btn-group">
          <button
            className="btn primary"
            onSubmit={handleSubmit}
            type="submit"
            disabled={pristine || submitting}
          >
            {t('button.login')}
          </button>
        </div>
      </form>
    </div>
  );
};

const SignIn = reduxForm({
  // a unique name for the form
  form: 'signin',
  validate,
})(Form);

export default SignIn;
