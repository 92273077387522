/* eslint-disable no-alert */
// @flow
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import idx from 'idx';

import OrderRowCards from './OrderRowCards';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import allowOnly from '../../../utils/allowOnly';
import { HOTEL_ADMINS } from '../../../utils/jobTitleMapping';

import type { AuthState } from '../../../redux/modules/auth';
import type { OrdersState } from '../../../redux/modules/orders';
import type { OrderRow, OrderRowsState } from '../../../redux/modules/orderRows';
import type { LocationsState } from '../../../redux/modules/locations';

import styles from './OrderRows.module.scss';

type Props = {
  t: string => string,
  orderId: string,
  orders: OrdersState,
  orderRows: OrderRowsState,
  locations: LocationsState,
  auth: AuthState,
  orderType: string,
  fetchOrderRowsIfNeeded: (orderId: string) => any,
  fetchOrdersIfNeeded: (orderType?: string) => any,
  fetchLocationsIfNeeded: () => any,
  deleteOrderRow: (orderRowId: string, orderId: string | number) => any,
  approveOrderRow: (orderRowId: string, orderId: string | number) => any,
  getCurrentUser: () => any,
};

type OrderRowsEntities = {
  [string]: OrderRow,
};

class OrderRows extends Component<Props> {
  componentDidMount() {
    this.props
      .getCurrentUser()
      .then(this.props.fetchLocationsIfNeeded)
      .then(() => {
        this.props.fetchOrderRowsIfNeeded(this.props.orderId);
        this.props.fetchOrdersIfNeeded(this.props.orderType);
      });
  }

  isEmptyObject = (obj: Object) => Object.keys(obj).length === 0 && obj.constructor === Object;

  filterOrderRows = (orderRows: OrderRowsEntities) => {
    const currentUser = this.props.auth.currentUser;
    const companyType = currentUser && currentUser.companyTypeTitle;
    const companyId = currentUser && currentUser.companyId;
    const filteredOrderRows = Object.keys(orderRows).reduce(
      (acc: OrderRowsEntities, cur: string) => {
        const busId = idx(orderRows, _ => _[cur].busCompany.id);

        if (
          (companyType === 'bus' && busId === companyId) ||
          companyType === 'tgk' ||
          companyType === 'hotel'
        ) {
          return {
            ...acc,
            [`${cur}`]: orderRows[cur],
          };
        }

        return { ...acc };
      },
      {}
    );

    return this.isEmptyObject(filteredOrderRows) ? null : filteredOrderRows;
  };

  render() {
    const {
      t,
      orderId,
      orderRows,
      orders,
      locations,
      deleteOrderRow,
      approveOrderRow,
      auth,
      orderType,
    } = this.props;
    const orderRowsEntities = idx(orderRows, _ => _.list[orderId].entities.rows);
    const filteredOrderRows = orderRowsEntities && this.filterOrderRows(orderRowsEntities);
    const locationsEntities = idx(locations, _ => _.list.entities.locations);
    const orderTitle = orderType
      ? idx(orders, _ => _[`${orderType}List`].entities.orders[orderId].title)
      : idx(orders, _ => _.list.entities.orders[orderId].title);
    const isLoading = orderRows.isFetching;
    const currentJobTypeTitle = idx(auth, _ => _.currentUser.jobTypeTitle);
    const Loading = (
      <div className="loading">
        <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw" />
        <span className="sr-only">{t('loading')}</span>
      </div>
    );
    const OrderRowsList =
      filteredOrderRows && locationsEntities ? (
        <OrderRowCards
          t={t}
          orderRows={filteredOrderRows}
          locations={locationsEntities}
          deleteOrderRow={deleteOrderRow}
          approveOrderRow={approveOrderRow}
          currentJobTypeTitle={currentJobTypeTitle}
          orderType={orderType}
        />
      ) : (
        <div>
          <div className={styles.empty}>{t('orderRow.empty')}</div>
        </div>
      );
    const breadcrumbItems = [
      {
        link: orderType ? `/orders/${orderType}` : '/orders',
        text: orderType ? t(`menu.${orderType}OrderList`) : t('menu.orderList'),
      },
      {
        link: '',
        text: orderTitle || '',
      },
    ];

    return (
      <div className={styles.root}>
        <Breadcrumbs items={breadcrumbItems} />
        <h2>{orderTitle}</h2>
        {allowOnly(currentJobTypeTitle, HOTEL_ADMINS) && (
          <div className="corner-btn-group">
            {!orderType && (
              <Link
                title={t('orderRow.edit')}
                to={`/orders/edit/${orderId}`}
                className="btn-manage"
              >
                <i className="fas fa-pen" />
              </Link>
            )}
            {!orderType && (
              <Link
                title={t('orderRow.create')}
                to={`/orders/${orderId}/rows/create`}
                className="btn-manage"
              >
                <i className="fas fa-plus" />
              </Link>
            )}
          </div>
        )}
        {isLoading ? Loading : OrderRowsList}
      </div>
    );
  }
}

export default OrderRows;
