/* eslint no-alert: 0 */
// @flow
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ReactTable from 'react-table';
import idx from 'idx';
import type { Element } from 'react';
import type { FormProps } from 'redux-form';

import { tableFilter } from '../../utils/filter';
import allowOnly from '../../utils/allowOnly';
import { TGK_ADMINS } from '../../utils/jobTitleMapping';

import styles from './Companies.module.scss';

type EmptyBlockType = {
  t: (key: string, ?Object) => string,
  type: string,
};

const EmptyBlock = ({ type, t }: EmptyBlockType): Element<'div'> => (
  <div className={styles.empty}>{t('company.empty', { type: t(`company.${type}`) })}</div>
);

type TableProps = {
  t: (key: string, ?Object) => string,
  entities: Object,
  deleteCompany: (id: string) => {},
  isSearching: boolean,
  currentUser: Object,
};

type TableData = Array<{
  name: string,
  address: string,
  phone: string,
  fax: string,
  edit: string,
  deleteId: string,
}>;

const Table = ({
  t,
  entities,
  deleteCompany,
  isSearching,
  currentUser,
}: TableProps): Element<'ReactTable'> => {
  const data: TableData = Object.keys(entities).map((id: string) => ({
    id,
    name: entities[id].name,
    address: entities[id].address,
    phone: entities[id].phone,
    fax: entities[id].fax,
    edit: `/admin/companies/edit/${id}`,
    deleteId: id,
  }));
  const columns = [
    {
      Header: t('company.id'),
      accessor: 'id',
      show: currentUser && allowOnly(currentUser.jobTypeTitle, TGK_ADMINS),
      width: 80,
      sortMethod: (a, b) => (+a > +b ? 1 : -1),
    },
    {
      Header: t('company.name'),
      accessor: 'name',
    },
    {
      Header: t('company.address'),
      accessor: 'address',
    },
    {
      Header: t('company.tel'),
      accessor: 'phone',
    },
    {
      Header: t('company.fax'),
      accessor: 'fax',
    },
    {
      Header: '',
      accessor: 'edit',
      Cell: props => <Link to={props.value} title={t('order.edit')} className="fas fa-pen" />,
      width: 44,
      sortable: false,
      filterable: false,
      className: 'manage',
    },
    {
      Header: '',
      accessor: 'deleteId',
      Cell: () => <i href="#" title={t('company.delete')} className="fas fa-times" />,
      width: 44,
      sortable: false,
      filterable: false,
      className: 'manage',
    },
  ];

  return (
    <ReactTable
      className="custom"
      data={data}
      columns={columns}
      minRows={0}
      showPagination={data.length > 20}
      filterable={isSearching}
      defaultFilterMethod={tableFilter}
      defaultSorted={[
        {
          id: 'id',
          desc: false,
        },
      ]}
      // Text
      previousText="前へ"
      nextText="次へ"
      loadingText="處理中..."
      noDataText="検索結果はありません"
      pageText="ページ"
      ofText="&nbsp;/&nbsp;"
      rowsText="行"
      getTdProps={(state, rowInfo, column) => ({
        onClick: (e, handleOriginal) => {
          if (column.id === 'deleteId') {
            const confirm = window.confirm(`Want to delete ${rowInfo.original.name}?`);

            if (confirm) {
              deleteCompany(rowInfo.original.deleteId);
            }
          }

          // IMPORTANT! React-Table uses onClick internally to trigger
          // events like expanding SubComponents and pivots.
          // By default a custom 'onClick' handler will override this functionality.
          // If you want to fire the original onClick handler, call the
          // 'handleOriginal' function.
          if (handleOriginal) {
            handleOriginal();
          }
        },
      })}
    />
  );
};

type Props = FormProps & {
  t: (key: string, ?Object) => string,
  companyId?: string,
  fetchCompaniesIfNeeded: () => {},
  deleteCompany: (id: string) => {},
};

type State = {
  isSearching: boolean,
};

class Companies extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isSearching: false,
    };
  }

  componentDidMount() {
    this.props.fetchCompaniesIfNeeded();
  }

  toggleSearching = () => {
    const searchingState = this.state.isSearching;

    this.setState({
      isSearching: !searchingState,
    });
  };

  render() {
    const { t, companies, deleteCompany, auth } = this.props;
    const isLoading = companies.isFetching;
    const loading = (
      <div className="loading">
        <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw" />
        <span className="sr-only">{t('loading')}</span>
      </div>
    );
    const currentUser = idx(auth, _ => _.currentUser);
    const getCompanies = (type: string): any => idx(companies, _ => _.listByType.entities[type]);

    const companiesTabPanel = type => {
      const companyList = getCompanies(type) ? (
        <Table
          entities={getCompanies(type)}
          deleteCompany={deleteCompany}
          t={t}
          isSearching={this.state.isSearching}
          currentUser={currentUser}
        />
      ) : (
        <EmptyBlock type={type} t={t} />
      );

      return <TabPanel>{isLoading ? loading : companyList}</TabPanel>;
    };

    return (
      <div className={styles.root}>
        <h2>会社リスト</h2>
        <div className="corner-btn-group">
          <a role="button" tabIndex="-1" className="btn-manage" onClick={this.toggleSearching}>
            {this.state.isSearching ? (
              <i className="fas fa-times" />
            ) : (
              <i title={t('company.search')} className="fa fa-search" />
            )}
          </a>
          <Link title={t('company.create')} to="/admin/companies/create" className="btn-manage">
            <i className="fas fa-plus" />
          </Link>
        </div>
        <Tabs selectedTabClassName={styles.selectedTab}>
          <TabList className={styles.tabList}>
            <Tab>{t('company.hotel')}</Tab>
            <Tab>{t('company.bus')}</Tab>
            <Tab>{t('company.agent')}</Tab>
          </TabList>
          {companiesTabPanel('hotel')}
          {companiesTabPanel('bus')}
          {companiesTabPanel('agent')}
        </Tabs>
      </div>
    );
  }
}

export default Companies;
