// @flow
import React from 'react';
import type { Node } from 'react';
import type { FieldProps } from 'redux-form';

import styles from './Shared.module.scss';

type TextAreaFieldProps = FieldProps & {
  label: string,
  placeholder: string,
  type: string,
  required: boolean,
  disabled?: boolean,
};

const NoteField = ({
  input,
  label,
  placeholder,
  meta: { touched, error, warning },
  required,
  disabled,
}: TextAreaFieldProps): Node => (
  <div>
    <label>
      {label}
      {!required && <span>（任意）</span>}
    </label>

    <div>
      <textarea {...input} placeholder={placeholder} disabled={disabled} />
      {touched &&
        ((error && <span className={styles.error}>{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  </div>
);

export default NoteField;
