// @flow
import React from 'react';
import type { Node } from 'react';
import type { FieldProps } from 'redux-form';

import styles from './Shared.module.scss';

type InputFieldProps = FieldProps & {
  label: string,
  placeholder: string,
  type: string,
  required: boolean,
  disabled?: boolean,
};

const InputField = ({
  input,
  label,
  placeholder,
  type,
  meta: { touched, error, warning },
  required,
  disabled,
}: InputFieldProps): Node => (
  <div>
    {label && (
      <label>
        {label}
        {!required && !disabled && <span>（任意）</span>}
      </label>
    )}
    <div>
      <input {...input} placeholder={placeholder} type={type} disabled={disabled} />
      {touched &&
        ((error && <span className={styles.error}>{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  </div>
);

export default InputField;
