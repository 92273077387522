// @flow
import React from 'react';
import type { Element } from 'react';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';

import { tableFilter } from '../../utils/filter';

import type { Location } from '../../redux/modules/locations';

type TableProps = {
  t: (string, any) => string,
  locationsEntities: { [string | number]: Location },
  deleteLocation: (locationId: string) => void,
  isSearching: boolean,
};

const LocationTable = ({
  t,
  locationsEntities,
  deleteLocation,
  isSearching,
}: TableProps): Element<ReactTable> => {
  const data = Object.keys(locationsEntities).map(id => ({
    id,
    title: locationsEntities[id].title,
    edit: `/admin/locations/edit/${id}`,
    deleteId: id,
  }));

  const columns = [
    {
      Header: t('locations.id'),
      accessor: 'id',
      width: 80,
      sortMethod: (a, b) => (+a > +b ? 1 : -1),
    },
    {
      Header: t('locations.title'),
      accessor: 'title',
    },
    {
      Header: '',
      accessor: 'edit',
      Cell: props => <Link title={t('locations.edit')} to={props.value} className="fas fa-pen" />,
      width: 44,
      sortable: false,
      filterable: false,
      className: 'manage',
    },
    {
      Header: '',
      accessor: 'deleteId',
      Cell: () => <i className="fas fa-times" title={t('locations.delete')} />,
      width: 44,
      sortable: false,
      filterable: false,
      className: 'manage',
    },
  ];

  return (
    <ReactTable
      className="custom"
      data={data}
      columns={columns}
      minRows={0}
      showPagination={data.length > 20}
      filterable={isSearching}
      defaultFilterMethod={tableFilter}
      defaultSorted={[
        {
          id: 'id',
          desc: false,
        },
      ]}
      // Text
      previousText="前へ"
      nextText="次へ"
      loadingText="處理中..."
      noDataText="検索結果はありません"
      pageText="ページ"
      ofText="&nbsp;/&nbsp;"
      rowsText="行"
      getTdProps={(state, rowInfo, column) => ({
        onClick: (e, handleOriginal) => {
          if (column.id === 'deleteId') {
            // eslint-disable-next-line
            const confirm = window.confirm(
              t('locations.deleteMsg', {
                location: rowInfo.original.title,
              })
            );

            if (confirm) {
              deleteLocation(rowInfo.original.deleteId);
            }
          }

          // IMPORTANT! React-Table uses onClick internally to trigger
          // events like expanding SubComponents and pivots.
          // By default a custom 'onClick' handler will override this functionality.
          // If you want to fire the original onClick handler, call the
          // 'handleOriginal' function.
          if (handleOriginal) {
            handleOriginal();
          }
        },
      })}
    />
  );
};

export default LocationTable;
