//@flow
/* eslint no-use-before-define: 0 */

import axios from 'axios';
import { normalize, schema } from 'normalizr';
import { responseType, responseHandler, catchHandler } from '../modules/auth';
import baseUrl from '../../utils/baseUrl';
import fetchLocalUser from '../../utils/fetchLocalUser';
import allowOnly from '../../utils/allowOnly';
import { BUS_ONLY_OPERATORS } from '../../utils/jobTitleMapping';

import type { GetState, ThunkAction, Dispatch } from './index';

type InitOrderRowsState = {
  +isFetching: boolean,
  +isPosting: boolean,
  +didInvalidate: boolean,
  +list: [],
  +orderRowDiff: {
    +orderRowId: string,
    +diff: [],
  },
};

export type OrderRow = {
  approvedAt?: number,
  approvedBy?: number,
  busCompany: {
    id: number,
    name: string,
    phone: string,
    companyType: {
      id: number,
      title: string,
    },
  },
  busOrderId: number,
  busPlate?: string,
  createdAt: number,
  diff: Array<*>,
  driverUser?: {
    id: number,
    company: {
      id: number,
      name: string,
      phone: string,
    },
    email: string,
    firstName: string,
    lastName: string,
    jobType: {
      id: number,
      title: string,
    },
    mobilePhone: string,
  },
  flightArrivalTime: number,
  flightNo: string,
  flightStatus: string,
  fromTime: number,
  id: number,
  isDeleted: boolean,
  note?: string,
  numberOfPeople?: number,
  passByLocations: Array<{ locationId: number, sequence: number }>,
  title: string,
  tourGuideUser?: {
    id: number,
    company: {
      id: number,
      name: string,
      phone: string,
    },
    email: string,
    firstName: string,
    lastName: string,
    jobType: {
      id: number,
      title: string,
    },
    mobilePhone: string,
  },
  price?: number,
  cost?: number,
  isShuttle: boolean,
  updatedAt: number,
  updatedBy: number,
};

export type OrderRowFormData = {
  approvedAt: number,
  approvedBy: number,
  busCompany: {
    value: number,
    label: string,
  },
  busOrderId: number,
  busPlate?: string,
  createdAt?: number,
  diff: Array<*>,
  driverUser?: {
    value: number,
    label: string,
  },
  flightArrivalTime: number,
  flightNo: string,
  flightStatus: string,
  fromTime: Array<*>,
  id: number,
  isDeleted: boolean,
  note?: string,
  numberOfPeople: number,
  passByLocations: Array<{ locationId: number, sequence: number }>,
  title: string,
  tourGuideUser?: {
    value: number,
    label: string,
  },
  price?: number,
  cost?: number,
  updatedAt: number,
  updatedBy: number,
};

export type OrderRowsState = {
  +isFetching: boolean,
  +isPosting: boolean,
  +didInvalidate: boolean,
  +list: NormalizedOrderRows,
  +orderRowDiff: {
    +orderRowId: string,
    +diff: Array<OrderRow>,
  },
};

export type OrderRowsAction =
  | RequestOrderRowsAction
  | ReceiveOrderRowsAction
  | InvalidateOrderRowsAction
  | RequestCreateOrderRowAction
  | CreateOrderRowSuccessAction
  | RequestUpdateOrderRowAction
  | UpdateOrderRowSuccessAction
  | RequestDeleteOrderRowAction
  | DeleteOrderRowSuccessAction
  | RequestApproveOrderRowAction
  | ApproveOrderRowSuccessAction
  | RequestOrderRowDiffAction
  | ReceiveOrderRowDiffAction;

const REQUEST_ORDER_ROWS = '@@TGK/ORDERS/REQUEST_ORDER_ROWS';
const RECEIVE_ORDER_ROWS = '@@TGK/ORDERS/RECEIVE_ORDER_ROWS';
const INVALIDATE_ORDER_ROWS = '@@TGK/ORDERS/INVALIDATE_ORDER_ROWS';

const REQUEST_CREATE_ORDER_ROW = '@@TGK/ORDERS/REQUEST_CREATE_ORDER_ROW';
const CREATE_ORDER_ROW_SUCCESS = '@@TGK/ORDERS/CREATE_ORDER_ROW_SUCCESS';

const REQUEST_UPDATE_ORDER_ROW = '@@TGK/ORDERS/REQUEST_UPDATE_ORDER_ROW';
const UPDATE_ORDER_ROW_SUCCESS = '@@TGK/ORDERS/UPDATE_ORDER_ROW_SUCCESS';

const REQUEST_DELETE_ORDER_ROW = '@@TGK/ORDERS/REQUEST_DELETE_ORDER_ROW';
const DELETE_ORDER_ROW_SUCCESS = '@@TGK/ORDERS/DELETE_ORDER_ROW_SUCCESS';

const REQUEST_APPROVE_ORDER_ROW = '@@TGK/ORDERS/REQUEST_APPROVE_ORDER_ROW';
const APPROVE_ORDER_ROW_SUCCESS = '@@TGK/ORDERS/APPROVE_ORDER_ROW_SUCCESS';

const REQUEST_ORDER_ROW_DIFF = '@@TGK/ORDERS/REQUEST_ORDER_ROW_DIFF';
const RECEIVE_ORDER_ROW_DIFF = '@@TGK/ORDERS/RECEIVE_ORDER_ROW_DIFF';

type NormalizedOrderRows = {
  [string]: {
    entities: {
      rows: {
        [string]: OrderRow,
      },
    },
    result: Array<any>,
  },
};

const normalizer = (data: Array<OrderRow>): NormalizedOrderRows => {
  const rowsSchema = new schema.Entity('rows');
  const rowsArray = new schema.Array(rowsSchema);

  return normalize(data, rowsArray);
};

const reducer = (
  state: InitOrderRowsState = {
    isFetching: false,
    isPosting: false,
    didInvalidate: false,
    list: [],
    orderRowDiff: {
      orderRowId: '',
      diff: [],
    },
  },
  action: OrderRowsAction
) => {
  switch (action.type) {
    case INVALIDATE_ORDER_ROWS: {
      return Object.assign({}, state, {
        didInvalidate: true,
      });
    }
    case REQUEST_ORDER_ROWS: {
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    }
    case RECEIVE_ORDER_ROWS: {
      const updatedList = { [`${action.orderId}`]: normalizer(action.data) };

      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        list: updatedList,
      });
    }
    case REQUEST_UPDATE_ORDER_ROW:
    case REQUEST_CREATE_ORDER_ROW: {
      return Object.assign({}, state, {
        isPosting: true,
      });
    }
    case UPDATE_ORDER_ROW_SUCCESS:
    case CREATE_ORDER_ROW_SUCCESS: {
      return Object.assign({}, state, {
        isPosting: false,
      });
    }
    case REQUEST_ORDER_ROW_DIFF: {
      return Object.assign({}, state, {
        isFetching: true,
      });
    }
    case RECEIVE_ORDER_ROW_DIFF: {
      return Object.assign({}, state, {
        isFetching: false,
        orderRowDiff: {
          orderRowId: action.orderRowId,
          diff: action.data,
        },
      });
    }
    default: {
      return state;
    }
  }
};

type RequestOrderRowsAction = {
  type: '@@TGK/ORDERS/REQUEST_ORDER_ROWS',
  orderId: string,
};

const requestOrderRows = (orderId: string): RequestOrderRowsAction => ({
  type: REQUEST_ORDER_ROWS,
  orderId,
});

type ReceiveOrderRowsAction = {
  type: '@@TGK/ORDERS/RECEIVE_ORDER_ROWS',
  data: Array<OrderRow>,
  orderId: string,
};

const receiveOrderRows = (data: Array<OrderRow>, orderId: string): ReceiveOrderRowsAction => ({
  type: RECEIVE_ORDER_ROWS,
  data,
  orderId,
});

type InvalidateOrderRowsAction = {
  type: '@@TGK/ORDERS/INVALIDATE_ORDER_ROWS',
};

export const invalidateOrderRows = (): InvalidateOrderRowsAction => ({
  type: INVALIDATE_ORDER_ROWS,
});

// Fetch OrderRows
const fetchOrderRows = (orderId: string) => (dispatch: Dispatch) => {
  dispatch(requestOrderRows(orderId));

  return axios
    .get(`${baseUrl.host}/api/bus-orders/${orderId}/bus-order-rows`, {
      withCredentials: true,
    })
    .then(response => {
      if (dispatch(responseHandler(response)) === responseType.SUCCESS) {
        dispatch(receiveOrderRows(response.data, orderId));
      }
    })
    .catch(error => {
      catchHandler(error);
    });
};

const shouldFetchOrderRows = (orderId: string, orderRows: OrderRowsState): boolean => {
  if (!orderRows.list[orderId]) {
    return true;
  } else if (orderRows.isFetching) {
    return false;
  }

  return orderRows.didInvalidate;
};

export const fetchOrderRowsIfNeeded = (orderId: string): ThunkAction => (
  dispatch: Dispatch,
  getState: GetState
) => {
  if (shouldFetchOrderRows(orderId, getState().orderRows)) {
    return dispatch(fetchOrderRows(orderId));
  }

  return Promise.resolve();
};

type RequestCreateOrderRowAction = {
  type: '@@TGK/ORDERS/REQUEST_CREATE_ORDER_ROW',
};

// Create OrderRow
const requestCreateOrderRow = (): RequestCreateOrderRowAction => ({
  type: REQUEST_CREATE_ORDER_ROW,
});

type CreateOrderRowSuccessAction = {
  type: '@@TGK/ORDERS/CREATE_ORDER_ROW_SUCCESS',
};

const createOrderRowSuccess = (): CreateOrderRowSuccessAction => ({
  type: CREATE_ORDER_ROW_SUCCESS,
});

export const createOrderRow = (
  orderId: string,
  form: OrderRowFormData,
  successCallback: () => void,
  isShuttle: boolean
) => (dispatch: Dispatch) => {
  dispatch(requestCreateOrderRow());
  const currentUser = fetchLocalUser();

  if (!currentUser) {
    return false;
  }

  const passByLocations = form.passByLocations
    ? form.passByLocations.map((value, index) => ({
        locationId: +value,
        sequence: index,
      }))
    : [];

  const data = {
    busOrderId: +orderId,
    busCompanyId: form.busCompany ? form.busCompany.value : null,
    title: form.title,
    fromTime: form.fromTime,
    numberOfPeople: form.numberOfPeople,
    driver: form.driverUser ? form.driverUser.value : null,
    tourGuide: form.tourGuideUser ? form.tourGuideUser.value : null,
    busPlate: form.busPlate,
    flightStatus: form.flightStatus,
    flightNo: form.flightNo,
    flightArrivalTime: form.flightArrivalTime
      ? +new Date(form.flightArrivalTime)
      : form.flightArrivalTime,
    passByLocations,
    note: form.note,
    isShuttle,
    createdAt: +new Date(), // Only create need this
    updatedAt: +new Date(),
    updatedBy: currentUser.id,
  };

  return axios
    .post(`${baseUrl.host}/api/bus-order-rows`, data, { withCredentials: true })
    .then(response => {
      if (dispatch(responseHandler(response)) === responseType.SUCCESS) {
        dispatch(invalidateOrderRows());
        dispatch(createOrderRowSuccess());
        successCallback();
      }
    })
    .catch(error => {
      catchHandler(error);
    });
};

// Update OrderRow
type RequestUpdateOrderRowAction = {
  type: '@@TGK/ORDERS/REQUEST_UPDATE_ORDER_ROW',
};

const requestUpdateOrderRow = (): RequestUpdateOrderRowAction => ({
  type: REQUEST_UPDATE_ORDER_ROW,
});

type UpdateOrderRowSuccessAction = {
  type: '@@TGK/ORDERS/UPDATE_ORDER_ROW_SUCCESS',
};

const updateOrderRowSuccess = (): UpdateOrderRowSuccessAction => ({
  type: UPDATE_ORDER_ROW_SUCCESS,
});

export const updateOrderRow = (
  rowId: string,
  orderId: string,
  form: OrderRowFormData,
  successCallback: () => void
) => (dispatch: Dispatch) => {
  dispatch(requestUpdateOrderRow());

  const currentUser = fetchLocalUser();

  if (!currentUser) {
    return false;
  }

  let data;

  // TO FIX: Remove work around
  if (allowOnly(currentUser.jobTypeTitle, BUS_ONLY_OPERATORS)) {
    data = {
      driver: form.driverUser ? form.driverUser.value : null,
      busPlate: form.busPlate,
      updatedAt: +new Date(),
      updatedBy: currentUser.id,
    };
  } else {
    const passByLocations = form.passByLocations
      ? form.passByLocations.map((value, index) => ({
          locationId: +value,
          sequence: index,
        }))
      : [];

    data = {
      busOrderId: orderId,
      busCompanyId: form.busCompany ? form.busCompany.value : null,
      title: form.title,
      fromTime: form.fromTime,
      numberOfPeople: form.numberOfPeople,
      driver: form.driverUser ? form.driverUser.value : null,
      tourGuide: form.tourGuideUser ? form.tourGuideUser.value : null,
      busPlate: form.busPlate,
      flightStatus: form.flightStatus,
      flightNo: form.flightNo,
      flightArrivalTime: form.flightArrivalTime
        ? +new Date(form.flightArrivalTime)
        : form.flightArrivalTime,
      passByLocations,
      note: form.note,
      price: form.price,
      cost: form.cost,
      updatedAt: +new Date(),
      updatedBy: currentUser.id,
    };
  }

  return axios
    .put(`${baseUrl.host}/api/bus-order-rows/${rowId}`, data, {
      withCredentials: true,
    })
    .then(response => {
      if (dispatch(responseHandler(response)) === responseType.SUCCESS) {
        dispatch(invalidateOrderRows());
        dispatch(updateOrderRowSuccess());
        successCallback();
      }
    })
    .catch(error => {
      catchHandler(error);
    });
};

// Delete OrderRow
type RequestDeleteOrderRowAction = {
  type: '@@TGK/ORDERS/REQUEST_DELETE_ORDER_ROW',
};

const requestDeleteOrderRow = (): RequestDeleteOrderRowAction => ({
  type: REQUEST_DELETE_ORDER_ROW,
});

type DeleteOrderRowSuccessAction = {
  type: '@@TGK/ORDERS/DELETE_ORDER_ROW_SUCCESS',
};

const deleteOrderRowSuccess = (): DeleteOrderRowSuccessAction => ({
  type: DELETE_ORDER_ROW_SUCCESS,
});

export const deleteOrderRow = (orderRowId: string, orderId: string) => (dispatch: Dispatch) => {
  dispatch(requestDeleteOrderRow());

  const data = {
    isDeleted: true,
  };

  return axios
    .put(`${baseUrl.host}/api/bus-order-rows/${orderRowId}`, data, {
      withCredentials: true,
    })
    .then(response => {
      if (dispatch(responseHandler(response)) === responseType.SUCCESS) {
        dispatch(deleteOrderRowSuccess());
        dispatch(invalidateOrderRows());
      }
    })
    .then(() => {
      dispatch(fetchOrderRowsIfNeeded(orderId));
    })
    .catch(error => {
      catchHandler(error);
    });
};

// Approve OrderRow
type RequestApproveOrderRowAction = {
  type: '@@TGK/ORDERS/REQUEST_APPROVE_ORDER_ROW',
};

const requestApproveOrderRow = (): RequestApproveOrderRowAction => ({
  type: REQUEST_APPROVE_ORDER_ROW,
});

type ApproveOrderRowSuccessAction = {
  type: '@@TGK/ORDERS/APPROVE_ORDER_ROW_SUCCESS',
};

const approveOrderRowSuccess = (): ApproveOrderRowSuccessAction => ({
  type: APPROVE_ORDER_ROW_SUCCESS,
});

export const approveOrderRow = (orderRowId: string, orderId: string) => (dispatch: Dispatch) => {
  dispatch(requestApproveOrderRow());

  return axios
    .put(`${baseUrl.host}/api/bus-order-rows/approve/${orderRowId}`, {}, { withCredentials: true })
    .then(response => {
      if (dispatch(responseHandler(response)) === responseType.SUCCESS) {
        dispatch(approveOrderRowSuccess());
        dispatch(invalidateOrderRows());
      }
    })
    .then(() => {
      dispatch(fetchOrderRowsIfNeeded(orderId));
    })
    .catch(error => {
      catchHandler(error);
    });
};

// Get orderRow diff
type RequestOrderRowDiffAction = {
  type: '@@TGK/ORDERS/REQUEST_ORDER_ROW_DIFF',
};

const requestOrderRowDiff = (): RequestOrderRowDiffAction => ({
  type: REQUEST_ORDER_ROW_DIFF,
});

type ReceiveOrderRowDiffAction = {
  type: '@@TGK/ORDERS/RECEIVE_ORDER_ROW_DIFF',
  orderRowId: string,
  data: Array<OrderRow>,
};

const receiveOrderRowDiff = (
  orderRowId: string,
  data: Array<OrderRow>
): ReceiveOrderRowDiffAction => ({
  type: RECEIVE_ORDER_ROW_DIFF,
  orderRowId,
  data,
});

export const fetchOrderRowDiff = (orderRowId: string) => (dispatch: Dispatch) => {
  dispatch(requestOrderRowDiff());

  return axios
    .get(`${baseUrl.host}/api/bus-order-row-revisions/${orderRowId}`, {
      withCredentials: true,
    })
    .then(response => {
      if (dispatch(responseHandler(response)) === responseType.SUCCESS) {
        dispatch(receiveOrderRowDiff(response.data.busOrderRowId, response.data.data));
      }
    })
    .catch(error => {
      catchHandler(error);
    });
};

export default reducer;
