require('dotenv').config();

console.log('NODE_ENV', process.env.NODE_ENV);

const baseUrl = {
  host:
    process.env.NODE_ENV === 'production'
      ? 'https://api.tgk.tours'
      : 'http://localhost:3012',
  site:
    process.env.NODE_ENV === 'production'
      ? 'https://admin.tgk.tours/'
      : 'http://localhost:3999',
};

export default baseUrl;
