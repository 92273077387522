// @flow
import React, { Component } from 'react';
import type { Element } from 'react';
import { withRouter } from 'react-router-dom';
import format from 'date-fns/format';
import jaLocale from 'date-fns/locale/ja';
import idx from 'idx';

import FlightCell from './FlightCell';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';

import allowOnly from '../../../utils/allowOnly';
import { TGK_ADMINS } from '../../../utils/jobTitleMapping';

import type { AuthState } from '../../../redux/modules/auth';
import type { OrdersState } from '../../../redux/modules/orders';
import type { OrderRow, OrderRowsState } from '../../../redux/modules/orderRows';
import type { User } from '../../../redux/modules/users';
import type { Location, LocationsState } from '../../../redux/modules/locations';

import styles from '../OrderRows/OrderRows.module.scss';

type NormalizedLocationsType = {
  [string | number]: Location,
};

type OrderRowWithDiff = any;

type LocationsCellProps = {
  locations: NormalizedLocationsType,
  passByLocations: any,
};

const LocationsCell = ({ locations, passByLocations }: LocationsCellProps) => {
  if (!(passByLocations && passByLocations.length)) return '';

  return passByLocations.map(location => (
    <div key={location.locationId} className={styles.location}>
      {locations[location.locationId].title}
    </div>
  ));
};

type PersonCellProps = {
  t: string => string,
  person: User,
};

const PersonCell = ({ t, person }: PersonCellProps) => {
  if (!person) return t('orderRow.none');

  return `${person.lastName} ${person.firstName} / tel: ${person.mobilePhone ||
    t('orderRow.none')}`;
};

type DiffCellProps = {
  diffField: string,
  data: OrderRowWithDiff,
  render: (data: any) => any,
};

const DiffCell = ({ diffField, data, render }: DiffCellProps): Element<'div'> => {
  const diff = data.diff && data.diff[`${diffField}`];
  let before;
  let after;

  if (diff) {
    if (diffField === 'passByLocations') {
      before = diff.before ? JSON.parse(diff.before) : '';
      after = diff.after ? JSON.parse(diff.after) : '';
    } else {
      before = diff.before;
      after = diff.after;
    }

    return (
      <div className={styles.diff}>
        <div className={`${styles.cell} ${styles.before}`}>{render(before)}</div>
        <div className={`${styles.cell} ${styles.after}`}>{render(after)}</div>
      </div>
    );
  }

  return <div className={styles.cell}>{render(data[`${diffField}`])}</div>;
};

type ActionCellProps = {
  t: string => string,
  actionType: string,
  log: OrderRowWithDiff,
};

const ActionCell = ({ t, actionType, log }: ActionCellProps) => {
  const userMapping = {
    approve: 'approvedUser',
    update: 'updatedUser',
    create: 'updatedUser',
  };

  return (
    <h3 className={styles['card-title']}>
      <span>{t(`orderRow.actionTypeLog.${actionType}`)} / </span>
      <PersonCell person={log[`${userMapping[actionType]}`]} t={t} />
    </h3>
  );
};

type DiffCardProps = {
  t: string => string,
  logs: Array<OrderRowWithDiff>,
  locations: NormalizedLocationsType,
  currentJobTypeTitle: ?string,
};

const DiffCard = ({ t, logs, locations, currentJobTypeTitle }: DiffCardProps) =>
  logs.map((log, index) => (
    <div key={index} className="card-box">
      <time className={styles.time}>
        最後更新：
        {format(new Date(log.updatedAt), 'YYYY/MM/DD HH:mm', {
          locale: jaLocale,
        })}
      </time>
      <ActionCell t={t} log={log} actionType={log.actionType} />
      <div key={log.id} className={styles.card}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className="busCompanyId">
                <div className={styles.cell}>{t('orderRow.busCompany')}</div>
              </th>
              <th className="fromTime">
                <div className={styles.cell}>{t('orderRow.fromTime')}</div>
              </th>
              <th className="title">
                <div className={styles.cell}>{t('orderRow.title')}</div>
              </th>
              <th className="numberOfPeople">
                <div className={styles.cell}>{t('orderRow.numberOfPeople')}</div>
              </th>
              <th className="driver">
                <div className={styles.cell}>{t('orderRow.driver')}</div>
              </th>
              <th className="busPlate">
                <div className={styles.cell}>{t('orderRow.busPlate')}</div>
              </th>
              <th className="tourGuide">
                <div className={styles.cell}>{t('orderRow.tourGuide')}</div>
              </th>
              <th className="flight">
                <div className={styles.cell}>{t('orderRow.flightInfo')}</div>
              </th>
              {allowOnly(currentJobTypeTitle, TGK_ADMINS) && (
                <th className="cost">
                  <div className={styles.cell}>{t('orderRow.cost')}</div>
                </th>
              )}
              {allowOnly(currentJobTypeTitle, TGK_ADMINS) && (
                <th className="price">
                  <div className={styles.cell}>{t('orderRow.price')}</div>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="busCompanyId">
                <DiffCell
                  diffField="busCompany"
                  data={log}
                  render={data => {
                    if (data && data.name) {
                      return data.name;
                    }

                    return t('orderRow.none');
                  }}
                />
              </td>
              <td className="fromTime">
                <DiffCell
                  diffField="fromTime"
                  data={log}
                  render={data =>
                    format(new Date(data), 'YYYY/MM/DD HH:mm', {
                      locale: jaLocale,
                    })
                  }
                />
              </td>
              <td className="title">
                <DiffCell diffField="title" data={log} render={data => data} />
              </td>
              <td className="numberOfPeople">
                <DiffCell diffField="numberOfPeople" data={log} render={data => data} />
              </td>
              <td className="driver">
                <DiffCell
                  diffField="driverUser"
                  data={log}
                  render={data => <PersonCell person={data} t={t} />}
                />
              </td>
              <td className="busPlate">
                <DiffCell
                  diffField="busPlate"
                  data={log}
                  render={data => data || t('orderRow.none')}
                />
              </td>
              <td className="tourGuide">
                <DiffCell
                  diffField="tourGuideUser"
                  data={log}
                  render={data => <PersonCell person={data} t={t} />}
                />
              </td>
              <td className="rt-td flight">
                <FlightCell t={t} data={log} />
              </td>
              {allowOnly(currentJobTypeTitle, TGK_ADMINS) && (
                <td className="cost">
                  <DiffCell
                    diffField="cost"
                    data={log}
                    render={data => data || t('orderRow.none')}
                  />
                </td>
              )}
              {allowOnly(currentJobTypeTitle, TGK_ADMINS) && (
                <td className="price">
                  <DiffCell
                    diffField="price"
                    data={log}
                    render={data => data || t('orderRow.none')}
                  />
                </td>
              )}
            </tr>
          </tbody>
        </table>
        <div className={styles.row}>
          <DiffCell
            diffField="passByLocations"
            data={log}
            render={data => <LocationsCell locations={locations} passByLocations={data} />}
          />
        </div>
        {log.note && (
          <div className={styles.row}>
            <DiffCell
              diffField="note"
              data={log}
              render={data => <div className={styles.note}>{`${t('orderRow.note')}${data}`}</div>}
            />
          </div>
        )}
      </div>
    </div>
  ));

const loopFields = (prev: OrderRow, current: OrderRow): Object => {
  const diffObj = {};

  Object.keys(current).forEach((key: string) => {
    if (key === 'passByLocations') {
      const afterString = JSON.stringify(current[key]);
      const beforeString = JSON.stringify(prev[key]);

      if (afterString !== beforeString) {
        diffObj[key] = {
          after: afterString,
          before: beforeString,
        };
      }
    } else if (key === 'driverUser' || key === 'tourGuideUser' || key === 'busCompany') {
      const currentId = current[key] && current[key].id;
      const prevId = prev[key] && prev[key].id;

      if (currentId !== prevId) {
        diffObj[key] = {
          before: prev[key],
          after: current[key],
        };
      }
    } else if (current[key] !== prev[key]) {
      diffObj[key] = {
        before: prev[key],
        after: current[key],
      };
    }
  });

  return diffObj;
};

const makeDiff = (logs: Array<*>) => {
  let updatedLogs = [];

  if (logs && logs.length > 0) {
    updatedLogs = logs.map((log, index) => {
      const updatedLog = log;

      if (index < logs.length - 1) {
        return {
          ...updatedLog,
          diff: loopFields(logs[index + 1], logs[index]),
        };
      }

      return updatedLog;
    });
  }

  return updatedLogs;
};

type OrderRowDiffProps = {
  t: (string, any) => string,
  rowId: string,
  orders: OrdersState,
  orderRows: OrderRowsState,
  locations: LocationsState,
  auth: AuthState,
  fetchOrderRowDiff: (orderRowId: string) => any,
  fetchOrdersIfNeeded: (orderType?: string) => any,
  fetchLocationsIfNeeded: () => any,
  getCurrentUser: () => any,
  history: Object,
  orderType?: string, // eslint-disable-line
};

class OrderRowDiff extends Component<OrderRowDiffProps> {
  componentDidMount() {
    this.props.getCurrentUser().then(() => {
      this.props.fetchLocationsIfNeeded();
      this.props.fetchOrdersIfNeeded(this.props.orderType);
      this.props.fetchOrderRowDiff(this.props.rowId);
    });
  }

  render() {
    const { t, orderRows, orders, locations, auth, orderType } = this.props;
    const isLoading = orderRows.isFetching;
    const Loading = (
      <div className="loading">
        <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw" />
        <span className="sr-only">{t('loading')}</span>
      </div>
    );
    const logs = makeDiff(orderRows.orderRowDiff.diff);
    const locationsEntities = idx(locations, _ => _.list.entities.locations);
    const currentJobTypeTitle = idx(auth, _ => _.currentUser.jobTypeTitle);
    const OrderRowDiffList = logs.length > 0 && locationsEntities && (
      <DiffCard
        t={this.props.t}
        logs={logs}
        locations={locationsEntities}
        currentJobTypeTitle={currentJobTypeTitle}
      />
    );
    const orderId = idx(orderRows, _ => _.orderRowDiff.diff[0].busOrderId);
    let orderTitle = '';

    if (orderId) {
      orderTitle = orderType
        ? idx(orders, _ => _[`${orderType}List`].entities.orders[orderId].title)
        : idx(orders, _ => _.list.entities.orders[orderId].title);
    }

    const breadcrumbsItems =
      orderId && orderTitle
        ? [
            {
              link: orderType ? `/orders/${orderType}` : '/orders',
              text: orderType ? t(`menu.${orderType}OrderList`) : t('menu.orderList'),
            },
            {
              link: orderType ? `/orders/${orderType}/${orderId}/rows` : `/orders/${orderId}/rows`,
              text: orderTitle,
            },
            {
              link: '',
              text: t('orderRow.changeLog', {
                title: logs[0] && logs[0].title,
              }),
            },
          ]
        : [
            {
              link: '/orders',
              text: t('menu.orderList'),
            },
            {
              link: '',
              text: t('orderRow.changeLog', {
                title: logs[0] && logs[0].title,
              }),
            },
          ];

    return (
      <div className={styles.root}>
        <Breadcrumbs items={breadcrumbsItems} />
        <h2>{t('orderRow.changeLog', { title: logs[0] && logs[0].title })}</h2>
        <div className="corner-btn-group">
          <button
            title="前頁"
            className="btn-manage"
            onClick={event => {
              event.preventDefault();

              this.props.history.goBack();
            }}
          >
            <i className="fas fa-long-arrow-alt-left" />
          </button>
        </div>

        {isLoading ? Loading : OrderRowDiffList}
      </div>
    );
  }
}

export default withRouter(OrderRowDiff);
