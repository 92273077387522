// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import type { RouterHistory } from 'react-router-dom';
import idx from 'idx';

import OrderForm from './OrderForm';

import { createOrder, updateOrder, fetchOrdersIfNeeded } from '../../../redux/modules/orders';
import { getCurrentUser } from '../../../redux/modules/auth';
import { fetchCompaniesIfNeeded } from '../../../redux/modules/companies';
import allowOnly from '../../../utils/allowOnly';
import { TGK_OPERATORS } from '../../../utils/jobTitleMapping';

import type { GlobalState } from '../../../redux/modules/index';
import type { OrdersState, Order } from '../../../redux/modules/orders';
import type { AuthState } from '../../../redux/modules/auth';
import type { CompaniesState } from '../../../redux/modules/companies';

type Dispatch = (action: any) => any;

type StateProps = {
  orders: OrdersState,
  auth: AuthState,
  companies: CompaniesState,
};

type ActionProps = {
  createOrder: (form: Order, successCallback: (newOrderId: string) => void) => any,
  updateOrder: (id: string, form: Order, successCallback: () => void) => any,
  fetchOrdersIfNeeded: (orderType?: string) => any,
  fetchCompaniesIfNeeded: () => any,
  getCurrentUser: () => any,
};

type Props = StateProps &
  ActionProps & {
    t: (key: string, ?Object) => string,
    history: RouterHistory,
    orderId?: string, // eslint-disable-line
    orderType?: string, // eslint-disable-line
  };

class OrderFormContainer extends Component<Props> {
  componentDidMount() {
    this.props
      .getCurrentUser()
      .then(this.fetchCompaniesForAdmin)
      .then(() => {
        this.props.fetchOrdersIfNeeded(this.props.orderType);
      });
  }

  fetchCompaniesForAdmin = () => {
    const currentJobTypeTitle = idx(this.props.auth, _ => _.currentUser.jobTypeTitle);

    if (allowOnly(currentJobTypeTitle, TGK_OPERATORS)) {
      return this.props.fetchCompaniesIfNeeded();
    }

    return Promise.resolve();
  };

  render() {
    const { t, history, orderId, orders, auth, companies, orderType } = this.props;
    const currentCompanyName = idx(auth, _ => _.currentUser.company.name);
    const currentCompanyType = idx(auth, _ => _.currentUser.companyTypeTitle);
    const orderList = orderType ? orders[`${orderType}List`] : orders.list;
    const initialValues =
      orderId && idx(orderList, _ => _.entities)
        ? idx(orderList, _ => _.entities.orders[orderId])
        : {
            title: t('orderForm.defaultTitle', {
              companyName: currentCompanyType === 'hotel' ? currentCompanyName : '',
            }),
          };

    return (
      <OrderForm
        t={t}
        orderId={orderId}
        initialValues={initialValues}
        history={history}
        auth={auth}
        companies={companies}
        onSubmit={form => {
          if (orderId) {
            const successCallback = () => {
              history.push('/orders');
            };

            this.props.updateOrder(orderId, form, successCallback);
          } else {
            const successCallback = newOrderId => {
              history.push(`/orders/${newOrderId}/rows`);
            };

            this.props.createOrder(form, successCallback);
          }
        }}
      />
    );
  }
}

// $FlowFixMe
const mapStateToProps = (state: GlobalState): StateProps => ({
  orders: state.orders,
  auth: state.auth,
  companies: state.companies,
});

// $FlowFixMe
const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
  createOrder: bindActionCreators(createOrder, dispatch),
  updateOrder: bindActionCreators(updateOrder, dispatch),
  fetchOrdersIfNeeded: bindActionCreators(fetchOrdersIfNeeded, dispatch),
  fetchCompaniesIfNeeded: bindActionCreators(fetchCompaniesIfNeeded, dispatch),
  getCurrentUser: bindActionCreators(getCurrentUser, dispatch),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(OrderFormContainer);
