// @flow

import React from 'react';
import { Field, reduxForm } from 'redux-form';

import type { Element } from 'react';
import type { FormProps, FieldProps } from 'redux-form';
import type { RouterHistory } from 'react-router-dom';

import styles from './Account.module.scss';

const validate = values => {
  const errors = {};

  if (!values.mobilePhone) {
    errors.mobilePhone = '必須項目です';
  }

  if (!values.lastName) {
    errors.lastName = '必須項目です';
  }

  if (!values.firstName) {
    errors.firstName = '必須項目です';
  }

  if (!values.email) {
    errors.email = '必須項目です';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'メールアドレスを正しく入力してください';
  }

  return errors;
};

type InputFieldProps = FieldProps & {
  label: string,
  placeholder: string,
  type: string,
  required: boolean,
};

const renderField = ({
  input,
  label,
  placeholder,
  type,
  meta: { touched, error, warning },
  required,
}: InputFieldProps): Element<'div'> => (
  <div>
    <label>
      {label}
      {!required && <span>（任意）</span>}
    </label>
    <div>
      <input {...input} placeholder={placeholder} type={type} />
      {touched &&
        ((error && <span className={styles.error}>{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  </div>
);

type Props = FormProps & {
  history: RouterHistory,
};

const Form = ({
  t,
  handleSubmit,
  reset,
  history,
  pristine,
  submitting,
}: Props): Element<'form'> => {
  const handleCancel = () => {
    reset();
    history.push('/');
  };

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <section className="form-title">
        <h2 className="title">{t('account.accountInfo')}</h2>
        <p className="sub">全てご記入ください</p>
      </section>
      <fieldset>
        <Field
          name="email"
          component={renderField}
          type="text"
          label={t('userForm.email')}
          placeholder={t('userForm.placeholder.email')}
          required
        />
      </fieldset>
      <fieldset>
        <Field
          name="lastName"
          component={renderField}
          type="text"
          label={t('userForm.lastName')}
          placeholder={t('userForm.placeholder.lastName')}
          required
        />
      </fieldset>
      <fieldset>
        <Field
          name="firstName"
          component={renderField}
          type="text"
          label={t('userForm.firstName')}
          placeholder={t('userForm.placeholder.firstName')}
          required
        />
      </fieldset>
      <fieldset>
        <Field
          name="mobilePhone"
          component={renderField}
          type="text"
          label={t('userForm.mobilePhone')}
          placeholder={t('userForm.placeholder.mobilePhone')}
          required
        />
      </fieldset>
      <fieldset>
        <Field
          name="localPhone"
          component={renderField}
          type="text"
          label={t('userForm.localPhone')}
          placeholder={t('userForm.placeholder.localPhone')}
        />
      </fieldset>
      <fieldset className="btn-group">
        <button className="btn" onClick={handleCancel} type="button">
          {t('button.cancel')}
        </button>
        <button
          className="btn primary"
          onSubmit={handleSubmit}
          type="submit"
          disabled={pristine || submitting}
        >
          {t('account.update')}
        </button>
      </fieldset>
    </form>
  );
};

const AccountForm = reduxForm({
  // a unique name for the form
  form: 'user',
  validate,
})(Form);

export default AccountForm;
