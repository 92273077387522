// @flow

import React, { PureComponent } from 'react';
import DropdownList from 'react-widgets/lib/DropdownList';

import type { FieldProps } from 'redux-form';

import styles from './Shared.module.scss';

type Props = FieldProps & {
  className?: string,
  input: any,
  label: string,
  valueField: string,
  textField: string,
  placeholder: string,
  type: string,
  options: Array<*>,
  required: boolean,
  disabled: boolean,
  actionLink?: ?Function,
};

type State = {
  input: string,
  select:
    | {
        text: string,
        value: any,
      }
    | '',
  combined: string,
};

type Select = {
  text: string,
  value: any,
};

export default class SelectInputField extends PureComponent<Props, State> {
  state: State;

  constructor(props: Props) {
    super(props);

    this.state = {
      input: props.input.value,
      select: '',
      combined: '',
    };
  }

  onSelectChange = (target: Select) => {
    this.setState(
      {
        select: target,
        combined: `${target.text} ${this.state.input}`,
      },
      () => {
        this.props.input.onChange(this.state.combined);
      }
    );
  };

  onInputChange = (e: any) => {
    this.setState(
      {
        input: e.target.value,
        combined: `${this.state.select && this.state.select.text} ${e.target.value}`,
      },
      () => {
        this.props.input.onChange(this.state.combined);
      }
    );
  };

  render() {
    const {
      className,
      // input,
      label,
      placeholder,
      type,
      options,
      valueField,
      textField,
      meta,
      required,
      disabled,
      actionLink,
    } = this.props;

    return (
      <fieldset className={className}>
        <label>
          {label}
          {!required && !disabled && <span>（任意）</span>}
        </label>
        {actionLink && actionLink()}
        <div className={styles.combined}>
          <div className={styles.select}>
            <DropdownList
              value={this.state.select}
              data={options}
              valueField={valueField}
              textField={textField}
              onChange={this.onSelectChange}
              placeholder="ホテル選択"
              disabled={disabled}
            />
          </div>
          <input
            value={this.state.input}
            placeholder={placeholder}
            type={type}
            disabled={disabled}
            onChange={this.onInputChange}
          />
        </div>

        {meta.touched &&
          ((meta.error && <span className={styles.error}>{meta.error}</span>) ||
            (meta.warning && <span>{meta.warning}</span>))}
      </fieldset>
    );
  }
}
