// @flow
import format from 'date-fns/format';
import jaLocale from 'date-fns/locale/ja';
import jobTitleMapping from './jobTitleMapping';

const formatDate = timestamp => format(new Date(timestamp), 'YYYY/MM/DD', { locale: jaLocale });

export const tableFilter = (filter: Object, row: Object, { t }: { t?: Object }) => {
  const keyword = String(filter.value).toLowerCase();
  let field = String(row[filter.id]);

  if (filter.id === 'issueBy') {
    field = `${row[filter.id].lastName}${row[filter.id].firstName}`;
  }

  if (['fromTime', 'toTime'].indexOf(filter.id) > -1) {
    return formatDate(+field) === formatDate(+keyword);
  }

  if (filter.id === 'jobType') {
    return (
      String(jobTitleMapping(field, t))
        .toLowerCase()
        .indexOf(keyword) > -1
    );
  }

  return (
    String(field)
      .toLowerCase()
      .indexOf(keyword) > -1
  );
};

export default {
  tableFilter,
};
