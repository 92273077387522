// @flow

import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import styles from './Breadbrumbs.module.scss';

type Item = {
  link: string,
  text: string,
};

type Props = {
  items: Array<Item>,
};

export default class BreadCrumbs extends PureComponent<Props> {
  render() {
    const breadcrumbsItems = this.props.items.map((item: Item, index: number) => {
      if (index < this.props.items.length - 1) {
        return (
          <div key={item.link} className={styles.breadcrumbItem}>
            <Link to={item.link}>{item.text}</Link>
            <span>/</span>
          </div>
        );
      }

      // Last item
      return (
        <div key={item.link} className={styles.breadcrumbItem}>
          <div>{item.text}</div>
        </div>
      );
    });

    return <div className={styles.breadcrumbs}>{breadcrumbsItems}</div>;
  }
}
