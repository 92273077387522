/* eslint-disable no-alert */
// @flow
import React from 'react';
import type { Element } from 'react';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';
import jaLocale from 'date-fns/locale/ja';
import cx from 'classnames';
import idx from 'idx';

import allowOnly from '../../../utils/allowOnly';
import { TGK_ADMINS, BUS_ONLY_OPERATORS, HOTEL_ADMINS } from '../../../utils/jobTitleMapping';

import type { OrderRow } from '../../../redux/modules/orderRows';
import type { User } from '../../../redux/modules/users';
import type { Location } from '../../../redux/modules/locations';

import styles from './OrderRows.module.scss';

type CardProps = {
  t: (string, any) => string,
  orderRows: {
    [string]: OrderRow,
  },
  locations: {
    [string | number]: Location,
  },
  approveOrderRow: (orderRowId: string, orderId: string | number) => void,
  deleteOrderRow: (orderRowId: string, orderId: string | number) => void,
  currentJobTypeTitle: ?string,
  orderType?: string,
};

type DiffCellProps = {
  field: string,
  data: any,
  render: (data: any) => any,
};

type PassByLocations = any;

const OrderRowCards = ({
  t,
  orderRows,
  locations,
  approveOrderRow,
  deleteOrderRow,
  currentJobTypeTitle,
  orderType,
}: CardProps) =>
  // $FlowFixMe
  Object.keys(orderRows)
    .sort((x, y) => {
      // the nearest on the top
      if (orderRows[x].fromTime && orderRows[y].fromTime) {
        return orderRows[x].fromTime - orderRows[y].fromTime;
      }

      return 0;
    })
    .map(id => {
      /* eslint-disable-next-line */
      const PersonCell = ({ person }: { person: User }) =>
        !person
          ? t('orderRow.none')
          : `${person.lastName} ${person.firstName} / ${person.mobilePhone || t('orderRow.none')}`;

      const FlightCell = ({ flightNo, flightStatus, flightArrivalTime }) => {
        const render = (no, status, time) =>
          `${status ? t(`orderRow.${status}`) : t('orderRow.none')} / ${no ||
            t('orderRow.none')} / ${
            time
              ? format(new Date(time), 'YYYY-MM-DD HH:mm', { locale: jaLocale })
              : t('orderRow.none')
          }`;

        return render(flightNo, flightStatus, flightArrivalTime);
      };

      const LocationsCell = ({ passByLocations }: { passByLocations: PassByLocations }) => {
        if (!passByLocations.length) return '';

        return passByLocations.map(location => (
          <div key={location.locationId} className={styles.location}>
            {idx(locations, _ => _[location.locationId].title)}
          </div>
        ));
      };

      const approve = () => {
        const confirm = window.confirm(
          `Want to approve current version of ${orderRows[id].title}?`
        );

        if (confirm) {
          approveOrderRow(id, orderRows[id].busOrderId);
        }
      };

      const Cell = ({ field, data, render }: DiffCellProps): Element<'div'> => (
        <div key={field} className={styles.cell}>
          {render(data)}
        </div>
      );

      if (orderRows[id]) {
        return (
          <div className="card-box" key={id}>
            <time className={styles.time}>
              配車時間：
              {format(new Date(orderRows[id].fromTime), 'YYYY/MM/DD HH:mm', {
                locale: jaLocale,
              })}
            </time>
            <h3 className={styles['card-title']}>{orderRows[id].title || t('orderRow.none')}</h3>
            <div key={id} className={styles.card}>
              <table className={styles.table}>
                <thead>
                  <tr>
                    {currentJobTypeTitle && allowOnly(currentJobTypeTitle, TGK_ADMINS) && (
                      <th className="id">
                        <div className={styles.cell}>{t('orderRow.id')}</div>
                      </th>
                    )}
                    <th className="busCompany">
                      <div className={styles.cell}>{t('orderRow.busCompany')}</div>
                    </th>
                    <th className="numberOfPeople">
                      <div className={styles.cell}>{t('orderRow.numberOfPeople')}</div>
                    </th>
                    <th className="driver">
                      <div className={styles.cell}>{t('orderRow.driver')}</div>
                    </th>
                    <th className="busPlate">
                      <div className={styles.cell}>{t('orderRow.busPlate')}</div>
                    </th>
                    <th className="tourGuide">
                      <div className={styles.cell}>{t('orderRow.tourGuide')}</div>
                    </th>
                    <th className="flight">
                      <div className={styles.cell}>{t('orderRow.flightInfo')}</div>
                    </th>
                    {allowOnly(currentJobTypeTitle, TGK_ADMINS) && (
                      <th className="cost">
                        <div className={styles.cell}>{t('orderRow.cost')}</div>
                      </th>
                    )}
                    {allowOnly(currentJobTypeTitle, TGK_ADMINS) && (
                      <th className="price">
                        <div className={styles.cell}>{t('orderRow.price')}</div>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {currentJobTypeTitle && allowOnly(currentJobTypeTitle, TGK_ADMINS) && (
                      <td>{id}</td>
                    )}
                    <td className="busCompany">
                      <Cell
                        field="busCompany"
                        data={idx(orderRows, _ => _[id].busCompany.name)}
                        render={data => {
                          if (data) {
                            return data;
                          }

                          return t('orderRow.none');
                        }}
                      />
                    </td>
                    <td className="numberOfPeople">
                      <Cell
                        field="numberOfPeople"
                        data={idx(orderRows, _ => _[id].numberOfPeople)}
                        render={data => data}
                      />
                    </td>
                    <td className="driver">
                      <Cell
                        field="driver"
                        data={idx(orderRows, _ => _[id].driverUser)}
                        render={data => <PersonCell person={data} />}
                      />
                    </td>
                    <td className="busPlate">
                      <Cell
                        field="busPlate"
                        data={idx(orderRows, _ => _[id].busPlate)}
                        render={data => data || t('orderRow.none')}
                      />
                    </td>
                    <td className="tourGuide">
                      <Cell
                        field="tourGuide"
                        data={idx(orderRows, _ => _[id].tourGuideUser)}
                        render={data => <PersonCell person={data} />}
                      />
                    </td>
                    <td className="rt-td flight">
                      <FlightCell
                        flightNo={idx(orderRows, _ => _[id].flightNo)}
                        flightStatus={idx(orderRows, _ => _[id].flightStatus)}
                        flightArrivalTime={idx(orderRows, _ => _[id].flightArrivalTime)}
                      />
                    </td>
                    {allowOnly(currentJobTypeTitle, TGK_ADMINS) && (
                      <td className="cost">
                        <Cell
                          field="cost"
                          data={idx(orderRows, _ => _[id].cost)}
                          render={data => data || t('orderRow.none')}
                        />
                      </td>
                    )}
                    {allowOnly(currentJobTypeTitle, TGK_ADMINS) && (
                      <td className="price">
                        <Cell
                          field="price"
                          data={idx(orderRows, _ => _[id].price)}
                          render={data => data || t('orderRow.none')}
                        />
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
              <div className={styles.row}>
                <Cell
                  field="passByLocationsString"
                  data={idx(orderRows, _ => _[id].passByLocations)}
                  render={data => <LocationsCell passByLocations={data} />}
                />
              </div>
              {idx(orderRows, _ => _[id].note) && (
                <div className={styles.row}>
                  <div className={styles.note}>{`${t('orderRow.note')}${orderRows[id].note ||
                    ''}`}</div>
                </div>
              )}
              <div className={styles.row}>
                <div className="btn-group">
                  {!orderType && (
                    <Link
                      className="btn"
                      to={
                        idx(orderRows, _ => _[id].isShuttle)
                          ? `/orders/${orderRows[id].busOrderId}/rows/edit/${id}/shuttle`
                          : `/orders/${orderRows[id].busOrderId}/rows/edit/${id}`
                      }
                      disabled={
                        !idx(orderRows, _ => _[id].approvedAt) &&
                        allowOnly(currentJobTypeTitle, BUS_ONLY_OPERATORS)
                      }
                    >
                      {t('orderRow.edit')}
                    </Link>
                  )}
                  <Link
                    className="btn"
                    to={
                      orderType
                        ? `/orders/${orderType}/${orderRows[id].busOrderId}/rows/diff/${id}`
                        : `/orders/${orderRows[id].busOrderId}/rows/diff/${id}`
                    }
                  >
                    {t('orderRow.history')}
                  </Link>
                  {!orderType &&
                    !idx(orderRows, _ => _[id].approvedAt) &&
                    allowOnly(currentJobTypeTitle, BUS_ONLY_OPERATORS) && (
                      <a
                        className={cx('btn', 'green')}
                        onClick={approve}
                        role="button"
                        tabIndex="-1"
                      >
                        {t('orderRow.approve')}
                      </a>
                    )}
                  {!orderType && allowOnly(currentJobTypeTitle, HOTEL_ADMINS) && (
                    <a
                      role="button"
                      tabIndex="-1"
                      className="btn red"
                      onClick={() => {
                        const confirm = window.confirm(
                          `${t('orderRow.cancelConfirm', {
                            title: orderRows[id].title,
                          })}`
                        );

                        if (confirm) {
                          deleteOrderRow(id, orderRows[id].busOrderId);
                        }
                      }}
                    >
                      {t('orderRow.cancel')}
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      }

      return null;
    });

export default OrderRowCards;
