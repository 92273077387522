// @flow
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import type { FormProps } from 'redux-form';
import type { RouterHistory } from 'react-router-dom';

import InputField from '../Shared/InputField';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

import styles from './LocationForm.module.scss';

const validate = values => {
  const errors = {};

  if (!values.title) {
    errors.title = '必須項目です';
  }

  return errors;
};

type LocationFormProps = FormProps & {
  t: string => string,
  locationId?: string,
  history: RouterHistory,
};

const Form = (props: LocationFormProps) => {
  const { t, handleSubmit, reset, history, pristine, submitting, locationId } = props;

  const handleCancel = () => {
    reset();
    history.push('/admin/locations');
  };

  const breadcrumbsItems = [
    {
      link: '/admin/locations',
      text: t('locations.locationList'),
    },
    {
      link: '',
      text: locationId ? t('locationForm.edit') : t('locationForm.create'),
    },
  ];

  return (
    <div>
      <Breadcrumbs items={breadcrumbsItems} />
      <form onSubmit={handleSubmit} className={styles.form}>
        <section className="form-title">
          <h2 className="title">
            {locationId ? t('locationForm.edit') : t('locationForm.create')}
          </h2>
          <p className="sub">全てご記入ください</p>
        </section>
        <fieldset>
          <Field
            name="title"
            component={InputField}
            type="text"
            label={t('locationForm.title')}
            placeholder={t('locationForm.placeholder.title')}
            required
          />
        </fieldset>
        <div className="btn-group">
          <button className="btn" onClick={handleCancel} type="button">
            {t('button.cancel')}
          </button>
          <button
            className="btn primary"
            onSubmit={handleSubmit}
            type="submit"
            disabled={pristine || submitting}
          >
            {t('button.submit')}
          </button>
        </div>
      </form>
    </div>
  );
};

const LocationForm = reduxForm({
  // a unique name for the form
  form: 'location',
  validate,
})(Form);

export default LocationForm;
