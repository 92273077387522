// @flow
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchLocationsIfNeeded, deleteLocation } from '../../redux/modules/locations';
import Locations from './Locations';

import type { GlobalState } from '../../redux/modules/index';
import type { AuthState } from '../../redux/modules/auth';
import type { LocationsState } from '../../redux/modules/locations';

type Dispatch = (action: any) => any;

type ActionProps = {
  fetchLocationsIfNeeded: () => any,
  deleteLocation: () => any,
};

type StateProps = {
  locations: LocationsState,
  auth: AuthState,
};

// $FlowFixMe
const mapStateToProps = (state: GlobalState): StateProps => ({
  auth: state.auth,
  locations: state.locations,
});

// $FlowFixMe
const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
  fetchLocationsIfNeeded: bindActionCreators(fetchLocationsIfNeeded, dispatch),
  deleteLocation: bindActionCreators(deleteLocation, dispatch),
});

// $FlowFixMe
const LocationContainer = connect(mapStateToProps, mapDispatchToProps)(Locations);

export default LocationContainer;
