export const locationNormalizer = location => `${location.locationId}`;

export const companySelectNormalizer = company => ({
  value: company.id,
  label: company.name,
});

export const userSelectNormalizer = user => ({
  value: user.id,
  label: `${user.lastName} ${user.firstName} (${user.mobilePhone})`,
});
