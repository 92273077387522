// @flow
import React from 'react';
import type { Node } from 'react';
import Flatpickr from 'react-flatpickr';
import type { FieldProps } from 'redux-form';

import styles from './Shared.module.scss';

type DateTimeFieldProps = FieldProps & {
  label: string,
  placeholder: string,
  type: string,
  required: boolean,
  disabled?: boolean,
  options: Object,
  defaultDate?: string,
};

const DateField = ({
  input,
  label,
  placeholder,
  meta: { touched, error, warning },
  required,
  disabled,
  options,
  defaultDate,
}: DateTimeFieldProps): Node => (
  <div>
    <label>
      {label}
      {!required && <span>（任意）</span>}
    </label>
    <div>
      <Flatpickr
        value={input.value || defaultDate}
        placeholder={placeholder}
        onReady={() => {
          if (!input.value && defaultDate) {
            input.onChange(+new Date(defaultDate));
          }
        }}
        onChange={value => {
          input.onChange(+new Date(value));
        }}
        options={
          options || {
            allowInput: false,
            dateFormat: 'Y/m/d',
          }
        }
        disabled={disabled}
      />
      {touched &&
        ((error && <span className={styles.error}>{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  </div>
);

export default DateField;
