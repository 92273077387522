// @flow
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import idx from 'idx';

import type { Location } from 'react-router';
import type { AuthState } from '../../redux/modules/auth';

import allowOnly from '../../utils/allowOnly';
import { TGK_OPERATORS, HOTEL_ADMINS } from '../../utils/jobTitleMapping';

import styles from './Menu.module.scss';

type Props = {
  t: (key: string, ?Object) => string,
  location: Location,
  auth: AuthState,
  getCurrentUser: () => void,
};

class Menu extends PureComponent<Props> {
  componentDidMount() {
    this.props.getCurrentUser();
  }

  render() {
    const { t, location, auth } = this.props;
    const currentJobTypeTitle = idx(auth, _ => _.currentUser.jobTypeTitle);
    const pathname = location.pathname;

    return (
      <div className={styles.root}>
        <h3 className={styles['list-title']}>注文</h3>
        <ul className={styles.menu}>
          <li className={pathname === '/orders' ? styles.selected : ''}>
            <Link to="/orders">{t('menu.orderList')}</Link>
          </li>
          {pathname.match('orders/edit') && (
            <li className={styles.selected}>
              <a className={styles.sub}>{t('menu.orderEdit')}</a>
            </li>
          )}
          <li className={pathname === '/orders/past' ? styles.selected : ''}>
            <Link to="/orders/past">{t('menu.pastOrderList')}</Link>
          </li>
          <li className={pathname === '/orders/canceled' ? styles.selected : ''}>
            <Link to="/orders/canceled">{t('menu.canceledOrderList')}</Link>
          </li>
          {allowOnly(currentJobTypeTitle, HOTEL_ADMINS) && (
            <li className={pathname === '/orders/create' ? styles.selected : ''}>
              <Link to="/orders/create">{t('menu.orderCreate')}</Link>
            </li>
          )}
        </ul>
        <h3 className={styles['list-title']}>プロフィール</h3>
        <ul className={styles.menu}>
          <li className={pathname.match('/account/info') ? styles.selected : ''}>
            <Link to="/account/info">{t('menu.account')}</Link>
          </li>
          <li className={pathname.match('/account/changepw') ? styles.selected : ''}>
            <Link to="/account/changepw">{t('menu.changepw')}</Link>
          </li>
        </ul>
        {allowOnly(currentJobTypeTitle, TGK_OPERATORS) && (
          <h3 className={styles['list-title']}>データベース</h3>
        )}
        {allowOnly(currentJobTypeTitle, TGK_OPERATORS) && (
          <ul className={styles.menu}>
            <li className={pathname.match('/admin') ? styles.selected : ''}>
              <Link to="/admin">{t('menu.admin')}</Link>
            </li>
            {pathname.match('/admin') && (
              <>
                <li className={pathname.match('/admin/users') ? styles.selected : ''}>
                  <Link to="/admin/users" className={styles.sub}>
                    {t('menu.adminUsers')}
                  </Link>
                </li>
                <li className={pathname.match('/admin/companies') ? styles.selected : ''}>
                  <Link to="/admin/companies" className={styles.sub}>
                    {t('menu.adminCompanies')}
                  </Link>
                </li>
                <li className={pathname.match('/admin/locations') ? styles.selected : ''}>
                  <Link to="/admin/locations" className={styles.sub}>
                    {t('menu.adminLocations')}
                  </Link>
                </li>
              </>
            )}
          </ul>
        )}
      </div>
    );
  }
}

export default Menu;
