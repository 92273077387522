// @flow
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchCompaniesIfNeeded, deleteCompany } from '../../redux/modules/companies';
import Companies from './Companies';

import type { GlobalState } from '../../redux/modules/index';
import type { CompaniesState } from '../../redux/modules/companies';
import type { AuthState } from '../../redux/modules/auth';

type Dispatch = (action: any) => any;

type ActionProps = {
  fetchCompaniesIfNeeded: () => any,
  deleteCompany: (companyId: string) => any,
};

type StateProps = {
  auth: AuthState,
  companies: CompaniesState,
};

// $FlowFixMe
const mapStateToProps = (state: GlobalState): StateProps => ({
  auth: state.auth,
  companies: state.companies,
});

// $FlowFixMe
const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
  fetchCompaniesIfNeeded: bindActionCreators(fetchCompaniesIfNeeded, dispatch),
  deleteCompany: bindActionCreators(deleteCompany, dispatch),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(Companies);
