// @flow
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchUsersIfNeeded, deleteUser } from '../../redux/modules/users';
import Users from './Users';

import type { GlobalState } from '../../redux/modules/index';
import type { UsersState } from '../../redux/modules/users';

type Dispatch = (action: any) => any;

type ActionProps = {
  fetchUsersIfNeeded: () => any,
  deleteUser: (userId: string) => any,
};

type StateProps = {
  users: UsersState,
};

// $FlowFixMe
const mapStateToProps = (state: GlobalState): StateProps => ({
  auth: state.auth,
  users: state.users,
});

// $FlowFixMe
const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
  fetchUsersIfNeeded: bindActionCreators(fetchUsersIfNeeded, dispatch),
  deleteUser: bindActionCreators(deleteUser, dispatch),
});

// $FlowFixMe
const UsersView = connect(mapStateToProps, mapDispatchToProps)(Users);

export default UsersView;
