// @flow
/* eslint no-alert: 0 */
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { boundMethod } from 'autobind-decorator';
import idx from 'idx';

import OrderTable from './OrderTable';
import allowOnly from '../../../utils/allowOnly';
import { HOTEL_ADMINS } from '../../../utils/jobTitleMapping';

import type { AuthState } from '../../../redux/modules/auth';
import type { OrdersState } from '../../../redux/modules/orders';

import styles from './Orders.module.scss';

type Props = {
  t: (key: string, ?Object) => string,
  orders: OrdersState,
  auth: AuthState,
  fetchOrdersIfNeeded: (type?: string) => void,
  fetchOrdersImmedaitely: (type?: string) => void,
  getCurrentUser: () => any,
  deleteOrder: (orderId: string) => void,
  orderType?: string, // eslint-disable-line
};

type State = {
  isSearching: boolean,
};

class Orders extends PureComponent<Props, State> {
  interval: IntervalID;

  state = {
    isSearching: false,
  };

  componentDidMount() {
    this.props.getCurrentUser().then(() => {
      this.props.fetchOrdersIfNeeded(this.props.orderType);
    });

    this.interval = setInterval(() => {
      this.props.fetchOrdersImmedaitely(this.props.orderType);
    }, 20000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  @boundMethod
  toggleSearching() {
    this.setState({
      isSearching: !this.state.isSearching,
    });
  }

  render() {
    const { t, orders, deleteOrder, auth, orderType } = this.props;
    const ordersEntities = orderType
      ? idx(orders, _ => _[`${orderType}List`].entities.orders)
      : idx(orders, _ => _.list.entities.orders);
    const currentUser = idx(auth, _ => _.currentUser);
    const currentJobTypeTitle = idx(auth, _ => _.currentUser.jobTypeTitle);
    const isLoading = orders.isFetching;
    const Loading = (
      <div className="loading">
        <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw" />
        <span className="sr-only">{t('loading')}</span>
      </div>
    );
    const OrderList =
      ordersEntities && currentUser ? (
        <OrderTable
          t={t}
          orders={ordersEntities}
          deleteOrder={deleteOrder}
          currentUser={currentUser}
          isSearching={this.state.isSearching}
          orderType={orderType}
        />
      ) : (
        <div className={styles.empty}>{t('order.empty')}</div>
      );

    return (
      <div className={styles.root}>
        <h2>{orderType ? t(`menu.${orderType}OrderList`) : t('menu.orderList')}</h2>
        <div className="corner-btn-group">
          <a role="button" tabIndex="-1" className="btn-manage" onClick={this.toggleSearching}>
            {this.state.isSearching ? (
              <i title={t('order.closeSearch')} className="fas fa-times" />
            ) : (
              <i title={t('order.search')} className="fa fa-search" />
            )}
          </a>
          {allowOnly(currentJobTypeTitle, HOTEL_ADMINS) && (
            <Link title={t('order.create')} to="/orders/create" className="btn-manage">
              <i className="fas fa-plus" />
            </Link>
          )}
        </div>
        {isLoading ? Loading : OrderList}
      </div>
    );
  }
}

export default Orders;
