import React, { PureComponent } from 'react';

import OrderRowFormContainer from './OrderRowFormContainer';
import ShuttleOrderRowFormContainer from './ShuttleOrderRowFormContainer';

import styles from '../OrderForm/OrderForm.module.scss';

export default class OrderRowType extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      type: 'normal',
    };
  }

  onChange = e => {
    this.setState({
      type: e.target.value,
    });
  };

  render() {
    const { t, history, orderId } = this.props;

    return (
      <div className={styles['form-container']}>
        <section className="form-title">
          <h2 className="title">{t('orderRowForm.create')}</h2>
          <p className="sub">全てご記入ください</p>
        </section>
        <fieldset>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                value="normal"
                checked={this.state.type === 'normal'}
                onChange={this.onChange}
              />
              {t('orderRowForm.normal')}
            </label>
            <label>
              <input
                type="radio"
                value="shuttle"
                checked={this.state.type === 'shuttle'}
                onChange={this.onChange}
              />
              {t('orderRowForm.shuttle')}
            </label>
          </div>
        </fieldset>
        {this.state.type === 'shuttle' ? (
          <ShuttleOrderRowFormContainer history={history} t={t} orderId={orderId} />
        ) : (
          <OrderRowFormContainer history={history} t={t} orderId={orderId} />
        )}
      </div>
    );
  }
}
