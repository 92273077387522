// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import type { RouterHistory } from 'react-router';
import idx from 'idx';
import generatePassword from 'password-generator';
import { SubmissionError } from 'redux-form';
import { createUser, updateUser, fetchUsersIfNeeded } from '../../redux/modules/users';
import { fetchCompaniesIfNeeded } from '../../redux/modules/companies';
import UserForm from './UserForm';
import allowOnly from '../../utils/allowOnly';
import jobTitleMapping, { ADMINS } from '../../utils/jobTitleMapping';

import type { GlobalState } from '../../redux/modules/index';
import type { CompaniesState } from '../../redux/modules/companies';
import type { TypesState } from '../../redux/modules/types';
import type { UsersState, UserForm as UserFormType } from '../../redux/modules/users';

type Dispatch = (action: any) => any;

type ActionProps = {
  createUser: (form: UserFormType, successCallback: () => void) => any,
  updateUser: (id: string, form: UserFormType, successCallback: () => void) => any,
  fetchUsersIfNeeded: () => any,
  fetchCompaniesIfNeeded: () => any,
};

type StateProps = {
  companies: CompaniesState,
  types: TypesState,
};

type Props = StateProps &
  ActionProps & {
    t: string => string,
    history: RouterHistory,
    userId?: string, // eslint-disable-line
    users?: UsersState, // eslint-disable-line
  };

type State = {
  password: string,
};

const validate = form => {
  const jobTypeId = form.jobType.id ? form.jobType.id.value || form.jobType.id : undefined;

  // Only admins requies email
  if (jobTypeId && !form.email && allowOnly(jobTitleMapping(jobTypeId), ADMINS)) {
    throw new SubmissionError({
      email: '必須項目です',
      _error: '全てご記入ください',
    });
  }

  // Email format check
  if (form.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(form.email)) {
    throw new SubmissionError({
      email: 'メールアドレスを正しく入力してください',
    });
  }

  // Other fields validation
  ['company', 'jobType', 'lastName', 'firstName', 'mobilePhone'].forEach(field => {
    if (!form[field]) {
      throw new SubmissionError({
        [`${field}`]: '必須項目です',
        _error: '全てご記入ください',
      });
    }

    if (field === 'company' || field === 'jobType') {
      if (!idx(form[field], _ => _.id.value) && !idx(form[field], _ => _.id)) {
        throw new SubmissionError({
          [`${field}`]: {
            id: '必須項目です',
          },
          _error: '全てご記入ください',
        });
      }
    }
  });
};

class UserFormContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      password: '',
    };
  }

  componentDidMount() {
    this.props.fetchCompaniesIfNeeded().then(this.props.fetchUsersIfNeeded);
  }

  showDefaultPassword = (password: string) => {
    this.setState({
      password,
    });
  };

  render() {
    const { t, history, userId, users, companies, types } = this.props;
    let initialValues;

    if (userId) {
      initialValues = idx(users, _ => _.list.entities.users[userId]);
    }

    return (
      <UserForm
        t={t}
        userId={userId}
        companies={companies}
        types={types}
        initialValues={initialValues}
        history={history}
        password={this.state.password}
        onSubmit={form => {
          validate(form);

          if (userId) {
            const successCallback = () => {
              history.push('/admin/users');
            };

            this.props.updateUser(userId, form, successCallback);
          } else {
            const defaultPasswordForm = form;
            const password = generatePassword(12, false);

            defaultPasswordForm.password = password;

            const successCallback = () => {
              this.showDefaultPassword(password);
            };

            this.props.createUser(form, successCallback);
          }
        }}
      />
    );
  }
}

// $FlowFixMe
const mapStateToProps = (state: GlobalState): StateProps => ({
  users: state.users,
  companies: state.companies,
  types: state.types,
});

// $FlowFixMe
const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
  createUser: bindActionCreators(createUser, dispatch),
  updateUser: bindActionCreators(updateUser, dispatch),
  fetchUsersIfNeeded: bindActionCreators(fetchUsersIfNeeded, dispatch),
  fetchCompaniesIfNeeded: bindActionCreators(fetchCompaniesIfNeeded, dispatch),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(UserFormContainer);
