/* eslint-disable no-alert */
// @flow
import React from 'react';
import type { Element } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import idx from 'idx';
import format from 'date-fns/format';
import jaLocale from 'date-fns/locale/ja';
import Flatpickr from 'react-flatpickr';

import allowOnly from '../../../utils/allowOnly';
import { tableFilter } from '../../../utils/filter';

import { TGK_ADMINS, HOTEL_ADMINS } from '../../../utils/jobTitleMapping';

type TableData = Array<{
  fromTime: string,
  title: {
    title: string,
    url: string,
  },
  issueBy: number,
  edit: string,
  deleteId: string,
}>;

type Props = {
  t: (key: string, ?Object) => string,
  orders: Object,
  deleteOrder: (orderId: string) => void,
  currentUser: Object,
  isSearching: boolean,
  orderType?: string, // eslint-disable-line
};

const OrderTable = ({
  t,
  orders,
  deleteOrder,
  currentUser,
  isSearching,
  orderType,
}: Props): Element<ReactTable> => {
  const data: TableData = Object.keys(orders).map(id => ({
    id,
    fromTime: orders[id].fromTime,
    title: {
      title: orders[id].title,
      url: orderType ? `/orders/${orderType}/${id}/rows` : `/orders/${id}/rows`,
    },
    issueBy: orders[id].issueUser,
    edit: orderType ? `/orders/${orderType}/edit/${id}` : `/orders/edit/${id}`,
    deleteId: id,
  }));

  const columns = [
    {
      Header: t('order.id'),
      accessor: 'id',
      show: currentUser && allowOnly(currentUser.jobTypeTitle, TGK_ADMINS),
      width: 80,
      sortMethod: (a, b) => (+a > +b ? 1 : -1),
    },
    {
      Header: t('order.fromTime'),
      accessor: 'fromTime',
      width: 110,
      // eslint-disable-next-line
      Cell: props =>
        !props.value ? '' : format(new Date(props.value), 'YYYY/MM/DD', { locale: jaLocale }),
      /* filterMethod: (filterInput, row) => {
        if (!filterInput.value) return true;
        return filterInput.value >= row.time.startTime && filterInput.value <= row.time.endTime;
      }, */
      Filter: ({ filter, onChange }) => (
        <Flatpickr
          data-enable-time
          value={filter && filter.value}
          onChange={value => {
            const selectDate = value[0] && +value[0];

            onChange(selectDate);
          }}
          options={{
            dateFormat: 'Y/m/d',
          }}
        />
      ),
    },
    {
      Header: t('order.title'),
      accessor: 'title',
      className: 'align-left',
      Cell: props => (
        <Link to={props.value.url} title={t('order.details')}>
          {props.value.title}
        </Link>
      ),
      filterMethod: (filter, row) => {
        const target = row[filter.id].title.toLowerCase();
        const keyword = filter.value.toLowerCase();

        return target.match(keyword);
      },
    },
    {
      Header: t('order.issueBy'),
      accessor: 'issueBy',
      Cell: props => {
        if (!props.value) {
          return '';
        }

        return `${props.value.lastName} ${props.value.firstName}`;
      },
      width: 100,
    },
    {
      Header: '',
      accessor: 'edit',
      Cell: props => <Link to={props.value} title={t('order.edit')} className="fas fa-pen" />,
      width: 44,
      sortable: false,
      filterable: false,
      show: allowOnly(currentUser.jobTypeTitle, HOTEL_ADMINS) && !orderType,
      className: 'manage',
    },
    {
      Header: '',
      accessor: 'deleteId',
      // eslint-disable-next-line
      Cell: () => <i title={t('order.cancel')} className="fas fa-times" />,
      width: 44,
      sortable: false,
      filterable: false,
      show: allowOnly(currentUser.jobTypeTitle, HOTEL_ADMINS) && !orderType,
      className: 'manage',
    },
  ];

  return (
    <ReactTable
      className="custom"
      data={data}
      columns={columns}
      minRows={3}
      filterable={isSearching}
      defaultFilterMethod={tableFilter}
      defaultSorted={[
        {
          id: 'fromTime',
          desc: true,
        },
      ]}
      // Text
      previousText={t('order.prevPage')}
      nextText={t('order.nextPage')}
      loadingText={t('loading')}
      noDataText={t('order.empty')}
      pageText={t('order.page')}
      ofText="&nbsp;/&nbsp;"
      rowsText={t('order.rowsText')}
      getTdProps={(state, rowInfo, column) => ({
        onClick: (e, handleOriginal) => {
          if (column.id === 'deleteId') {
            const title = idx(rowInfo, _ => _.original.title.title);
            const confirm = window.confirm(
              `${t('order.cancelConfirm', { title: title || '注文' })}`
            );

            if (confirm) {
              deleteOrder(rowInfo.original.deleteId);
            }
          }

          // IMPORTANT! React-Table uses onClick internally to trigger
          // events like expanding SubComponents and pivots.
          // By default a custom 'onClick' handler will override this functionality.
          // If you want to fire the original onClick handler, call the
          // 'handleOriginal' function.
          if (handleOriginal) {
            handleOriginal();
          }
        },
      })}
    />
  );
};

export default OrderTable;
