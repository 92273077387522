// @flow
import React, { Component } from 'react';
import styles from './DefaultPassword.module.scss';

type DefaultPasswordProps = {
  t: string => string,
  password: string,
  done: () => void,
};

export default class DefaultPassword extends Component<DefaultPasswordProps> {
  input: any = React.createRef();

  clickToCopy = () => {
    this.input.current.select();
    document.execCommand('copy');
  };

  render() {
    const { t, password, done } = this.props;

    return (
      <div className={styles['default-password']}>
        <h3>{t('userForm.success.title')}</h3>
        <p>{t('userForm.success.msg')}</p>
        <div>
          <label>{t('userForm.success.password')}</label>
          <input
            className={styles.defaultPassword}
            ref={this.input}
            type="text"
            value={password}
            readOnly
          />
          <div className="btn-group">
            <button className="btn primary" onClick={this.clickToCopy}>
              {t('userForm.success.clickToCopy')}
            </button>
            <button className="btn" onClick={done}>
              {t('userForm.success.done')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
