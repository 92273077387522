// @flow
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCurrentUser } from '../../../redux/modules/auth';
import {
  fetchOrderRowsIfNeeded,
  deleteOrderRow,
  approveOrderRow,
} from '../../../redux/modules/orderRows';
import { fetchOrdersIfNeeded } from '../../../redux/modules/orders';
import { fetchLocationsIfNeeded } from '../../../redux/modules/locations';
import OrderRows from './OrderRows';

import type { GlobalState } from '../../../redux/modules/index';
import type { AuthState } from '../../../redux/modules/auth';
import type { OrdersState } from '../../../redux/modules/orders';
import type { OrderRowsState } from '../../../redux/modules/orderRows';
import type { LocationsState } from '../../../redux/modules/locations';

type Dispatch = (action: any) => any;

type StateProps = {
  auth: AuthState,
  orders: OrdersState,
  orderRows: OrderRowsState,
  locations: LocationsState,
};

type ActionProps = {
  fetchOrdersIfNeeded: (type?: string) => any,
  fetchOrderRowsIfNeeded: (orderId: string) => any,
  fetchLocationsIfNeeded: () => any,
  deleteOrderRow: (orderRowId: string, orderId: string) => any,
  approveOrderRow: (orderRowId: string, orderId: string) => any,
  getCurrentUser: () => any,
};

// $FlowFixMe
const mapStateToProps = (state: GlobalState): StateProps => ({
  auth: state.auth,
  orders: state.orders,
  orderRows: state.orderRows,
  locations: state.locations,
});

// $FlowFixMe
const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
  fetchOrderRowsIfNeeded: bindActionCreators(fetchOrderRowsIfNeeded, dispatch),
  fetchOrdersIfNeeded: bindActionCreators(fetchOrdersIfNeeded, dispatch),
  fetchLocationsIfNeeded: bindActionCreators(fetchLocationsIfNeeded, dispatch),
  deleteOrderRow: bindActionCreators(deleteOrderRow, dispatch),
  approveOrderRow: bindActionCreators(approveOrderRow, dispatch),
  getCurrentUser: bindActionCreators(getCurrentUser, dispatch),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(OrderRows);
