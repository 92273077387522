// @flow
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout } from '../../redux/modules/auth';
import Header from './Header';

import type { AuthState } from '../../redux/modules/auth';
import type { GlobalState } from '../../redux/modules/index';

type Dispatch = (action: any) => any;

type StateProps = {
  auth: AuthState,
};

type ActionProps = {
  logout: void => any,
};

// $FlowFixMe
const mapStateToProps = (state: GlobalState): StateProps => ({
  auth: state.auth,
});

// $FlowFixMe
const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
  logout: bindActionCreators(logout, dispatch),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(Header);
