import idx from 'idx';
import history from './history';

const fetchLocalUser = () => {
  let currentUser;

  try {
    currentUser = JSON.parse(window.localStorage.getItem('user'));
  } catch (err) {
    console.log('Parse current user fail', err);
  }

  if (!currentUser || !idx(currentUser, _ => _.company.id)) {
    history.push('/login');
    console.log('No current user');

    return false;
  }

  return currentUser;
};

export default fetchLocalUser;
