// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import idx from 'idx';
import type { RouterHistory } from 'react-router-dom';
import {
  createLocation,
  updateLocation,
  fetchLocationsIfNeeded,
} from '../../redux/modules/locations';
import LocationForm from './LocationForm';

import type { GlobalState } from '../../redux/modules/index';
import type { LocationsState, Location, LocationFormType } from '../../redux/modules/locations';

type Dispatch = (action: any) => any;

type StateProps = {
  locations: LocationsState,
  form: { location: Object },
};

type ActionProps = {
  createLocation: (form: LocationFormType, callback?: () => void) => any,
  updateLocation: (locationId: string, form: LocationFormType, callback?: () => void) => any,
  fetchLocationsIfNeeded: () => any,
};

type Props = StateProps &
  ActionProps & {
    t: (key: string, ?Object) => string,
    history: RouterHistory,
    locationId?: string, // eslint-disable-line
  };

class LocationFormContainer extends Component<Props> {
  constructor(props: Props) {
    super(props);
    props.fetchLocationsIfNeeded();
  }
  render() {
    const { t, history, locationId, locations } = this.props;
    let initialValues;

    if (locationId) {
      const getLocationInfo = (): ?Location =>
        idx(locations, _ => _.list.entities.locations[locationId]);

      initialValues = getLocationInfo();
    }

    return (
      <LocationForm
        t={t}
        locationId={locationId}
        initialValues={initialValues}
        history={history}
        onSubmit={(form: LocationFormType) => {
          if (locationId) {
            this.props.updateLocation(locationId, form, () => {
              history.push('/admin/locations');
            });
          } else {
            this.props.createLocation(form, () => {
              history.push('/admin/locations');
            });
          }
        }}
      />
    );
  }
}

// $FlowFixMe
const mapStateToProps = (state: GlobalState): StateProps => ({
  form: state.form,
  locations: state.locations,
});

// $FlowFixMe
const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
  createLocation: bindActionCreators(createLocation, dispatch),
  updateLocation: bindActionCreators(updateLocation, dispatch),
  fetchLocationsIfNeeded: bindActionCreators(fetchLocationsIfNeeded, dispatch),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(LocationFormContainer);
