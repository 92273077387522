// @flow
import React from 'react';
import { Link } from 'react-router-dom';

import type { Element } from 'react';
import type { AuthState } from '../../redux/modules/auth';

import styles from './Header.module.scss';

type LogoutProps = {
  t: (key: string, ?Object) => string,
  logout: () => void,
};

const Logout = ({ t, logout }: LogoutProps): Element<'a'> => (
  <a onClick={logout} role="link" tabIndex="-1">
    {t('logout')}
  </a>
);

type GreetingProps = {
  t: (key: string, ?Object) => string,
  firstName?: string,
  lastName?: string,
};

const Greeting = ({ t, firstName, lastName }: GreetingProps): Element<'p'> => (
  <p>
    {t('greeting', {
      name: `${lastName || ''} ${firstName || ''}`,
    })}
  </p>
);

Greeting.defaultProps = {
  firstName: '',
  lastName: '',
};

type Props = {
  t: (key: string, ?Object) => string,
  logout: () => void,
  auth: AuthState,
};

const Header = ({ t, logout, auth }: Props): Element<'div'> => (
  <div className={styles.root}>
    <Link to="/" className={styles.logo} />
    <div className={styles.info}>
      {auth.currentUser && (
        <Greeting
          t={t}
          firstName={auth.currentUser.firstName}
          lastName={auth.currentUser.lastName}
        />
      )}
      <Logout t={t} logout={logout} />
    </div>
  </div>
);

export default Header;
