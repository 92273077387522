// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import generatePassword from 'password-generator';
import { createUser, fetchDriversAndTourGuides } from '../../redux/modules/users';
import { fetchAgentsAndBusCompanies } from '../../redux/modules/companies';
import { fetchTypesIfNeeded } from '../../redux/modules/types';
import { showModal } from '../../redux/modules/modal';
import UserModal from './UserModal';

import type { GlobalState } from '../../redux/modules/index';
import type { CompaniesState } from '../../redux/modules/companies';
import type { TypesState } from '../../redux/modules/types';
import type { UserForm as UserFormType } from '../../redux/modules/users';
import type { ModalState } from '../../redux/modules/modal';

type Dispatch = (action: any) => any;

type ActionProps = {
  createUser: (form: UserFormType, successCallback: () => void) => any,
  fetchDriversAndTourGuides: () => any,
  fetchAgentsAndBusCompanies: () => any,
  fetchTypesIfNeeded: () => any,
  showModal: (status: boolean) => any,
};

type StateProps = {
  companies: CompaniesState,
  types: TypesState,
  modal: ModalState,
};

type Props = StateProps &
  ActionProps & {
    t: string => string,
  };

type State = {
  password: string,
};

class UserModalContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      password: '',
    };
  }

  componentDidMount() {
    this.props.fetchAgentsAndBusCompanies();
    this.props.fetchTypesIfNeeded();
  }

  showDefaultPassword = (password: string) => {
    this.setState({
      password,
    });
  };

  render() {
    const { t, companies, types, modal } = this.props;
    let initialValues;

    if (modal.info) {
      initialValues = modal.info;
    }

    return (
      <UserModal
        t={t}
        companies={companies}
        types={types}
        initialValues={initialValues}
        close={() => {
          this.props.showModal(false);
        }}
        password={this.state.password}
        onSubmit={form => {
          const defaultPasswordForm = form;
          const password = generatePassword(12, false);

          defaultPasswordForm.password = password;

          const successCallback = () => {
            this.showDefaultPassword(password);
            this.props.fetchDriversAndTourGuides();
          };

          this.props.createUser(defaultPasswordForm, successCallback);
        }}
      />
    );
  }
}

// $FlowFixMe
const mapStateToProps = (state: GlobalState): StateProps => ({
  companies: state.companies,
  types: state.types,
  modal: state.modal,
});

// $FlowFixMe
const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
  createUser: bindActionCreators(createUser, dispatch),
  fetchDriversAndTourGuides: bindActionCreators(fetchDriversAndTourGuides, dispatch),
  fetchAgentsAndBusCompanies: bindActionCreators(fetchAgentsAndBusCompanies, dispatch),
  fetchTypesIfNeeded: bindActionCreators(fetchTypesIfNeeded, dispatch),
  showModal: bindActionCreators(showModal, dispatch),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(UserModalContainer);
