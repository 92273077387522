// @flow
import React from 'react';
import type { Element } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';

import jobTitleMapping, { TGK_ADMINS } from '../../utils/jobTitleMapping';
import { tableFilter } from '../../utils/filter';
import allowOnly from '../../utils/allowOnly';

import type { User } from '../../redux/modules/users';

type TableProps = {
  t: string => string,
  userEntities: { [string]: User },
  deleteUser: (userId: string) => void,
  isSearching: boolean,
  currentUser: Object,
};

const UserTable = ({
  t,
  userEntities,
  deleteUser,
  isSearching,
  currentUser,
}: TableProps): Element<ReactTable> => {
  const data = Object.keys(userEntities).map(id => ({
    id,
    name: `${userEntities[id].lastName || ''} ${userEntities[id].firstName || ''}`,
    mobilePhone: userEntities[id].mobilePhone,
    localPhone: userEntities[id].localPhone,
    email: userEntities[id].email,
    company: userEntities[id].company.name,
    edit: `/admin/users/edit/${id}`,
    jobType: userEntities[id].jobType.id,
    deleteId: id,
  }));

  const columns = [
    {
      Header: t('users.id'),
      accessor: 'id',
      show: currentUser && allowOnly(currentUser.jobTypeTitle, TGK_ADMINS),
      width: 80,
      sortMethod: (a, b) => (+a > +b ? 1 : -1),
    },
    {
      Header: t('users.name'),
      accessor: 'name',
    },
    {
      Header: t('users.mobilePhone'),
      accessor: 'mobilePhone',
    },
    {
      Header: t('users.email'),
      accessor: 'email',
    },
    {
      Header: t('users.company'),
      accessor: 'company',
      Cell: props => {
        if (!props.value) return '';

        return props.value;
      },
    },
    {
      Header: t('users.jobType'),
      accessor: 'jobType',
      Cell: props => {
        if (!props.value) {
          return '';
        }

        return jobTitleMapping(props.value, t);
      },
    },
    {
      Header: '',
      accessor: 'edit',
      Cell: props => <Link title={t('order.edit')} to={props.value} className="fas fa-pen" />,
      width: 44,
      sortable: false,
      filterable: false,
      className: 'manage',
    },
    {
      Header: '',
      accessor: 'deleteId',
      Cell: () => <i className="fas fa-times" title={t('users.delete')} />,
      width: 44,
      sortable: false,
      filterable: false,
      className: 'manage',
    },
  ];

  return (
    <ReactTable
      className="custom"
      data={data}
      columns={columns}
      minRows={0}
      showPagination={data.length > 20}
      filterable={isSearching}
      defaultFilterMethod={tableFilter}
      defaultSorted={[
        {
          id: 'id',
          desc: false,
        },
      ]}
      // Text
      previousText="前へ"
      nextText="次へ"
      loadingText="處理中..."
      noDataText="検索結果はありません"
      pageText="ページ"
      ofText="&nbsp;/&nbsp;"
      rowsText="行"
      getTdProps={(state, rowInfo, column) => ({
        onClick: (e, handleOriginal) => {
          if (column.id === 'deleteId') {
            // eslint-disable-next-line
            const confirm = window.confirm(`Want to delete ${rowInfo.original.name}?`);

            if (confirm) {
              deleteUser(rowInfo.original.deleteId);
            }
          }

          // IMPORTANT! React-Table uses onClick internally to trigger
          // events like expanding SubComponents and pivots.
          // By default a custom 'onClick' handler will override this functionality.
          // If you want to fire the original onClick handler, call the
          // 'handleOriginal' function.
          if (handleOriginal) {
            handleOriginal();
          }
        },
      })}
    />
  );
};

export default UserTable;
