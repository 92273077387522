// @flow
export const jobTitle = {
  TGK_ADMIN: 'tgkAdmin',
  TGK_OPERATOR: 'tgkOperator',
  AGENT_OPERATOR: 'agentOperator',
  TOUR_GUIDE: 'tourGuide',
  HOTEL_OPERATOR: 'hotelOperator',
  BUS_OPERATOR: 'busOperator',
  DRIVER: 'driver',
};

// Mapping jobId to title
const jobTitleMapping = (jobId: string, t?: string => string): string => {
  const mapping = {
    [`${1}`]: t ? t('job.tgkAdmin') : 'tgkAdmin',
    [`${2}`]: t ? t('job.tgkOperator') : 'tgkOperator',
    [`${3}`]: t ? t('job.agentOperator') : 'agentOperator',
    [`${4}`]: t ? t('job.tourGuide') : 'tourGuide',
    [`${5}`]: t ? t('job.hotelOperator') : 'hotelOperator',
    [`${6}`]: t ? t('job.busOperator') : 'busOperator',
    [`${7}`]: t ? t('job.driver') : 'driver',
  };

  return mapping[jobId];
};

export const TGK_ADMINS = [jobTitle.TGK_ADMIN];

export const TGK_OPERATORS = [jobTitle.TGK_ADMIN, jobTitle.TGK_OPERATOR];

export const BUS_ADMINS = [jobTitle.TGK_ADMIN, jobTitle.TGK_OPERATOR, jobTitle.BUS_OPERATOR];

export const HOTEL_ADMINS = [jobTitle.TGK_ADMIN, jobTitle.TGK_OPERATOR, jobTitle.HOTEL_OPERATOR];

export const BUS_ONLY_OPERATORS = [jobTitle.BUS_OPERATOR];

export const ADMINS = [
  jobTitle.TGK_ADMIN,
  jobTitle.TGK_OPERATOR,
  jobTitle.HOTEL_OPERATOR,
  jobTitle.BUS_OPERATOR,
];

export default jobTitleMapping;
