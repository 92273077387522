// @flow

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import idx from 'idx';

import UserTable from './UserTable';

import type { AuthState } from '../../redux/modules/auth';
import type { UsersState } from '../../redux/modules/users';

import styles from './Users.module.scss';

type Props = {
  t: string => string,
  auth: AuthState,
  users: UsersState,
  fetchUsersIfNeeded: () => void,
  deleteUser: (userId: string) => void,
};

type State = {
  isSearching: boolean,
};

class Users extends Component<Props, State> {
  state = {
    isSearching: false,
  };

  componentDidMount() {
    this.props.fetchUsersIfNeeded();
  }

  toggleSearching = () => {
    const searchingState = this.state.isSearching;

    this.setState({
      isSearching: !searchingState,
    });
  };

  render() {
    const { t, users, deleteUser, auth } = this.props;
    const isLoading = users.isFetching;
    const userEntities = idx(users, _ => _.list.entities.users);
    const Loading = (
      <div className="loading">
        <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw" />
        <span className="sr-only">{t('loading')}</span>
      </div>
    );
    const currentUser = idx(auth, _ => _.currentUser);
    const UserList = userEntities ? (
      <UserTable
        t={t}
        title={t('users.userList')}
        userEntities={userEntities}
        deleteUser={deleteUser}
        isSearching={this.state.isSearching}
        currentUser={currentUser}
      />
    ) : (
      <div className={styles.empty}>{t('users.empty')}</div>
    );

    return (
      <div className={styles.root}>
        <h2>{t('users.userList')}</h2>
        <div className="corner-btn-group">
          <a role="button" tabIndex="-1" className="btn-manage" onClick={this.toggleSearching}>
            {this.state.isSearching ? (
              <i className="fas fa-times" />
            ) : (
              <i title={t('users.search')} className="fa fa-search" />
            )}
          </a>
          <Link title={t('users.create')} to="/admin/users/create" className="btn-manage">
            <i className="fas fa-plus" />
          </Link>
        </div>
        {isLoading ? Loading : UserList}
      </div>
    );
  }
}

export default Users;
