// @flow
import React from 'react';
import type { Node } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import type { Match, RouterHistory } from 'react-router-dom';

import Companies from '../Companies/CompaniesContainer';
import CompanyForm from '../Companies/CompanyFormContainer';
import Users from '../Users/UsersContainer';
import UserForm from '../Users/UserFormContainer';
import Locations from '../Locations/LocationContainer';
import LocationForm from '../LocationForm/LocationFormContainer';

import styles from './Admin.module.scss';

type Props = {
  t: (key: string, ?Object) => string,
};

type RouteProps = {
  history: RouterHistory,
};

type RouteWithParamsProps = {
  history: RouterHistory,
  match: Match,
};

function Admin(props: Props): Node {
  const { t } = props;

  return (
    <Switch>
      <Route
        exact
        path="/admin/companies"
        render={() => (
          <div className={styles.content}>
            <Companies t={t} />
          </div>
        )}
      />
      <Route
        exact
        path="/admin/users"
        render={() => (
          <div className={styles.content}>
            <Users t={t} />
          </div>
        )}
      />
      <Route
        exact
        path="/admin/locations"
        render={() => (
          <div className={styles.content}>
            <Locations t={t} />
          </div>
        )}
      />
      <Route
        path="/admin/companies/create"
        render={(routeProps: RouteProps) => (
          <div className={styles.content}>
            <CompanyForm history={routeProps.history} t={t} />
          </div>
        )}
      />
      <Route
        path="/admin/companies/edit/:companyId"
        render={(routeProps: RouteWithParamsProps) => (
          <div className={styles.content}>
            <CompanyForm
              history={routeProps.history}
              companyId={routeProps.match.params.companyId || undefined}
              t={t}
            />
          </div>
        )}
      />
      <Route
        path="/admin/users/create"
        render={(routeProps: RouteProps) => (
          <div className={styles.content}>
            <UserForm history={routeProps.history} t={t} />
          </div>
        )}
      />
      <Route
        path="/admin/users/edit/:userId"
        render={(routeProps: RouteWithParamsProps) => (
          <div className={styles.content}>
            <UserForm
              history={routeProps.history}
              userId={routeProps.match.params.userId || undefined}
              t={t}
            />
          </div>
        )}
      />
      <Route
        path="/admin/locations/create"
        render={(routeProps: RouteProps) => (
          <div className={styles.content}>
            <LocationForm history={routeProps.history} t={t} />
          </div>
        )}
      />
      <Route
        path="/admin/locations/edit/:locationId"
        render={(routeProps: RouteWithParamsProps) => (
          <div className={styles.content}>
            <LocationForm
              history={routeProps.history}
              locationId={routeProps.match.params.locationId || undefined}
              t={t}
            />
          </div>
        )}
      />
      <Redirect to="/admin/users" />
    </Switch>
  );
}

export default Admin;
