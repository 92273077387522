// @flow
import React from 'react';
import { Field, reduxForm } from 'redux-form';

import type { FormProps } from 'redux-form';
import type { Element } from 'react';
import type { RouterHistory } from 'react-router-dom';

import SelectField from '../Shared/SelectField';
import InputField from '../Shared/InputField';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

import styles from './CompanyForm.module.scss';

type FormValues = {
  companyTypeId: any,
  name: any,
  address: any,
  phone: any,
  fax: any,
};

type Errors = {
  companyTypeId?: '必須項目です',
  name?: '必須項目です',
  phone?: '必須項目です',
  fax?: '必須項目です',
};

const EMPTY_ERROR_TEXT = '必須項目です';

const validate = (values: FormValues): Errors => {
  const errors = {};

  ['name', 'companyTypeId', 'phone', 'fax'].forEach(field => {
    if (!values[field]) {
      errors[field] = EMPTY_ERROR_TEXT;
    }
  });

  return errors;
};

type Props = FormProps & {
  t: (key: string, ?Object) => string,
  history: RouterHistory,
  companyId?: string,
};

type CompanyTypes = Array<{
  text: string,
  value: number,
}>;

const Form = (props: Props): Element<'div'> => {
  const { t, handleSubmit, reset, history, pristine, submitting, companyId } = props;

  const handleCancel = () => {
    reset();
    history.push('/admin/companies');
  };

  const companyTypes: CompanyTypes = [
    { text: 'TGK', value: 1 },
    { text: t('company.agent'), value: 2 },
    { text: t('company.hotel'), value: 3 },
    { text: t('company.bus'), value: 4 },
  ];
  const breadcrumbsItems = [
    {
      link: '/admin/companies',
      text: t('company.companyList'),
    },
    {
      link: '',
      text: companyId ? t('companyForm.edit') : t('companyForm.create'),
    },
  ];

  return (
    <div>
      <Breadcrumbs items={breadcrumbsItems} />
      <form onSubmit={handleSubmit} className={styles.form}>
        <section className="form-title">
          <h2 className="title">{companyId ? t('companyForm.edit') : t('companyForm.create')}</h2>
          <p className="sub">全てご記入ください</p>
        </section>
        <fieldset>
          <Field
            name="companyTypeId"
            component={SelectField}
            data={companyTypes}
            valueField="value"
            textField="text"
            label={t('companyForm.type')}
            placeholder={t('companyForm.placeholder.type')}
            required
          />
        </fieldset>
        <fieldset>
          <Field
            name="name"
            component={InputField}
            type="text"
            label={t('companyForm.name')}
            placeholder={t('companyForm.placeholder.name')}
            required
          />
        </fieldset>
        <fieldset>
          <Field
            name="address"
            component={InputField}
            type="text"
            label={t('companyForm.address')}
            placeholder={t('companyForm.placeholder.address')}
          />
        </fieldset>
        <fieldset>
          <Field
            name="phone"
            component={InputField}
            type="number"
            label={t('companyForm.tel')}
            placeholder={t('companyForm.placeholder.tel')}
            required
          />
        </fieldset>
        <fieldset>
          <Field
            name="fax"
            component={InputField}
            type="number"
            label={t('companyForm.fax')}
            placeholder={t('companyForm.placeholder.fax')}
            required
          />
        </fieldset>
        <div className="btn-group">
          <button className="btn" onClick={handleCancel} type="button">
            {t('button.cancel')}
          </button>
          <button
            className="btn primary"
            onSubmit={handleSubmit}
            type="submit"
            disabled={pristine || submitting}
          >
            {t('button.submit')}
          </button>
        </div>
      </form>
    </div>
  );
};

const CompanyForm = reduxForm({
  form: 'company',
  validate,
})(Form);

export default CompanyForm;
