// @flow
/* eslint no-use-before-define: 0 */

import axios from 'axios';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import idx from 'idx';
import history from '../../utils/history';
import baseUrl from '../../utils/baseUrl';
import fetchLocalUser from '../../utils/fetchLocalUser';
import type { GetState, ThunkAction, Dispatch } from './index';
import jobTitleMapping from '../../utils/jobTitleMapping';
import { invalidateOrders } from './orders';
import { invalidateCompanies } from './companies';
import { invalidateUsers } from './users';
import { invalidateOrderRows } from './orderRows';
import { invalidateLocations } from './locations';

import type { User } from '../modules/users';

export type AuthAction =
  | RequestLoginAction
  | LoginSuccessAction
  | RequestLogoutAction
  | LogoutSuccessAction
  | SetCurrentUserAction
  | SetAuthErrorAction;

export type AuthState = {
  isPosting: boolean,
  isLogin: boolean,
  currentUser: ?Object,
  authError: string,
};

const REQUEST_LOGIN = '@@TGK/AUTH/REQUEST_LOGIN';
const LOGIN_SUCCESS = '@@TGK/AUTH/LOGIN_SUCCESS';
const REQUEST_LOGOUT = '@@TGK/AUTH/REQUEST_LOGOUT';
const LOGOUT_SUCCESS = '@@TGK/AUTH/LOGOUT_SUCCESS';
const SET_CURRENT_USER = '@@TGK/AUTH/SET_CURRENT_USER';
const SET_AUTH_ERROR = '@@TGK/AUTH/SET_AUTH_ERROR';

const reducer = (
  state: AuthState = {
    isPosting: false,
    isLogin: false,
    currentUser: null,
    authError: '',
  },
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case REQUEST_LOGIN:
    case REQUEST_LOGOUT: {
      return Object.assign({}, state, {
        isPosting: true,
      });
    }
    case LOGIN_SUCCESS: {
      return Object.assign({}, state, {
        isLogin: true,
      });
    }
    case LOGOUT_SUCCESS: {
      return Object.assign({}, state, {
        isLogin: false,
      });
    }
    case SET_CURRENT_USER: {
      return Object.assign({}, state, {
        currentUser: action.user,
      });
    }
    case SET_AUTH_ERROR: {
      return Object.assign({}, state, {
        authError: action.error,
      });
    }
    default: {
      return state;
    }
  }
};

export const setUserLocalStorage = (currentUserFromBackend: any) => () => {
  try {
    const currentUser = Object.assign({}, currentUserFromBackend, {
      companyId: idx(currentUserFromBackend, _ => _.company.id),
      companyTypeTitle: idx(currentUserFromBackend, _ => _.company.companyType.title),
      jobTypeTitle: jobTitleMapping(currentUserFromBackend.jobType.id),
    });

    window.localStorage.setItem('user', JSON.stringify(currentUser));

    return Promise.resolve();
  } catch (err) {
    return Promise.reject('Set localStorage currentUser wrong');
  }
};

const removeUserLocalStorage = () => () => {
  try {
    window.localStorage.removeItem('user');

    return Promise.resolve();
  } catch (err) {
    return Promise.reject('Remove localStorage currentUser wrong');
  }
};

type SetCurrentUserAction = {
  type: '@@TGK/AUTH/SET_CURRENT_USER',
  user: User | null,
};

export const setCurrentUser = (user: Object | null): SetCurrentUserAction => ({
  type: SET_CURRENT_USER,
  user,
});

export const getCurrentUser = () => (dispatch: Dispatch) => {
  const currentUser = fetchLocalUser();

  if (currentUser) {
    dispatch(setCurrentUser(currentUser));
  }

  return Promise.resolve();
};

type RequestLoginAction = {
  type: '@@TGK/AUTH/REQUEST_LOGIN',
};

const requestLogin = (): RequestLoginAction => ({
  type: REQUEST_LOGIN,
});

type LoginSuccessAction = {
  type: '@@TGK/AUTH/LOGIN_SUCCESS',
};

const loginSuccess = (): LoginSuccessAction => ({
  type: LOGIN_SUCCESS,
});

type Form = {
  email: string,
  password: any,
};

export const login = (form: Form) => (dispatch: Dispatch) => {
  dispatch(requestLogin());

  const data = {
    email: form.email,
    password: form.password,
  };

  return axios
    .post(`${baseUrl.host}/auth/login`, data, { withCredentials: true })
    .then(response => {
      if (dispatch(responseHandler(response, true)) === responseType.SUCCESS) {
        dispatch(loginSuccess());
        dispatch(setUserLocalStorage(response.data.user)).then(() => {
          dispatch(getCurrentUser());
          history.push('/orders');
        });
      }
    })
    .catch(error => {
      catchHandler(error, 'login');
    });
};

type RequestLogoutAction = {
  type: '@@TGK/AUTH/REQUEST_LOGOUT',
};

const requestLogout = (): RequestLogoutAction => ({
  type: REQUEST_LOGOUT,
});

type LogoutSuccessAction = {
  type: '@@TGK/AUTH/LOGOUT_SUCCESS',
};

const logoutSuccess = (): LogoutSuccessAction => ({
  type: LOGOUT_SUCCESS,
});

export const logout = (): ThunkAction => (dispatch: Dispatch) => {
  dispatch(requestLogout());

  return axios
    .get(`${baseUrl.host}/auth/logout`, { withCredentials: true })
    .then(response => {
      if (dispatch(responseHandler(response)) === responseType.SUCCESS) {
        dispatch(logoutSuccess());
        dispatch(invalidateOrders());
        dispatch(invalidateCompanies());
        dispatch(invalidateUsers());
        dispatch(invalidateOrderRows());
        dispatch(invalidateLocations());
        dispatch(removeUserLocalStorage()).then(() => {
          dispatch(setCurrentUser(null));
          history.push('/login');
        });
      }
    })
    .catch(error => {
      catchHandler(error);
    });
};

export const responseType = {
  SUCCESS: 'SUCCESS',
  UNAUTHORIZED: 'UNAUTHRIZED',
  ERROR: 'ERROR',
};

type SetAuthErrorAction = {
  type: '@@TGK/AUTH/SET_AUTH_ERROR',
  error: string,
};

const setAuthError = (error: string): SetAuthErrorAction => ({
  type: SET_AUTH_ERROR,
  error,
});

export const responseHandler = (response: Object, isLogin?: boolean) => (
  dispatch: Dispatch,
  getState: GetState
) => {
  if (response.data.status >= 400 || response.data.status < 200) {
    toast.error(response.data.message);

    if (response.data.status === 401) {
      history.push('/login');

      if (getState().auth.authError !== responseType.UNAUTHORIZED) {
        toast.error(response.data.message);
      }

      dispatch(setAuthError(responseType.UNAUTHORIZED));

      return responseType.UNAUTHORIZED;
    }

    return responseType.ERROR;
  }

  if (!isLogin) {
    fetchLocalUser();
  }

  dispatch(setAuthError(responseType.SUCCESS));

  return responseType.SUCCESS;
};

export const catchHandler = (error: Object, route?: string) => {
  if (error.response) {
    const errorCode = error.response.status;

    switch (errorCode) {
      case 401:
        if (route === 'login') {
          toast.error(i18next.t('signin.error.401'));
        } else {
          toast.error(i18next.t('error.401'));
        }

        break;
      default:
        toast.error(error.response.data.message);
    }
  }
};

export default reducer;
