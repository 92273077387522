// @flow
const allowOnly = (factor: any, whitelist: Array<*>) =>
  whitelist.reduce((acc, cur) => {
    let isAllow = acc;

    if (cur === factor) {
      isAllow = acc || true;
    } else {
      isAllow = acc || false;
    }

    return isAllow;
  }, false);

export default allowOnly;
