// @flow
/* eslint no-use-before-define: 0 */
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import auth from './auth';
import orders from './orders';
import orderRows from './orderRows';
import companies from './companies';
import users from './users';
import locations from './locations';
import types from './types';
import modal from './modal';

import type { AuthState, AuthAction } from './auth';
import type { OrdersState, OrdersAction } from './orders';
import type { OrderRowsState, OrderRowsAction } from './orderRows';
import type { TypesState, TypesAction } from './types';
import type { UsersState, UsersAction } from './users';
import type { CompaniesState, CompaniesAction } from './companies';
import type { LocationsState, LocationsAction } from './locations';
import type { ModalState, ModalAction } from './modal';

export type GlobalState = {
  auth: AuthState,
  orders: OrdersState,
  orderRows: OrderRowsState,
  companies: CompaniesState,
  users: UsersState,
  locations: LocationsState,
  types: TypesState,
  modal: ModalState,
  form: any,
};

export type GetState = () => GlobalState;
type Action =
  | AuthAction
  | CompaniesAction
  | OrdersAction
  | OrderRowsAction
  | TypesAction
  | UsersAction
  | LocationsAction
  | ModalAction;

export type PromiseAction = Promise<Action>;
export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;
export type Dispatch = (action: Action | ThunkAction | PromiseAction | Array<Action>) => any;

// $FlowFixMe
export default combineReducers({
  auth,
  orders,
  orderRows,
  companies,
  users,
  locations,
  types,
  modal,
  form: formReducer,
});
