// @flow
/* eslint no-use-before-define: 0 */

const SHOW_MODAL = '@@TGK/MODAL/SHOW';
const SET_MODAL_INFO = '@@TGK/MODAL/SET_INFO';

export type ModalState = {
  show: boolean,
  info: any,
};

export type ModalAction = ShowModalAction | SetModalInfoAction;

const reducer = (
  state: ModalState = {
    show: false,
    info: {},
  },
  action: ModalAction
) => {
  switch (action.type) {
    case SHOW_MODAL: {
      return {
        ...state,
        show: action.status,
      };
    }
    case SET_MODAL_INFO: {
      return {
        ...state,
        info: action.info,
      };
    }
    default: {
      return state;
    }
  }
};

type ShowModalAction = {
  type: '@@TGK/MODAL/SHOW',
  status: boolean,
};

export const showModal = (status: boolean): ShowModalAction => ({
  type: SHOW_MODAL,
  status,
});

type SetModalInfoAction = {
  type: '@@TGK/MODAL/SET_INFO',
  info: any,
};

export const setModalInfo = (info: any): SetModalInfoAction => ({
  type: SET_MODAL_INFO,
  info,
});

export default reducer;
