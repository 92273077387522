// @flow
/* eslint no-use-before-define: 0 */
import axios from 'axios';
import { normalize, schema } from 'normalizr';
import baseUrl from '../../utils/baseUrl';
import { responseType, responseHandler, catchHandler } from '../modules/auth';
import { fetchTypesIfNeeded } from '../modules/types';

import type { TypesState } from '../modules/types';
import type { GetState, ThunkAction, Dispatch } from './index';

type InitCompaniesState = {
  +isFetching: boolean,
  +isPosting: boolean,
  +didInvalidate: boolean,
  +list: [],
  +listByType: [],
  +agentsAndBusCompanies: [],
};

export type CompaniesState = {
  +isFetching: boolean,
  +isPosting: boolean,
  +didInvalidate: boolean,
  +list: NormalizedCompanies,
  +listByType: NormalizedCompaniesByTypes,
  +agentsAndBusCompanies: NormalizedCompanies,
};

export type CompaniesAction =
  | RequestCompaniesAction
  | ReceiveCompaniesAction
  | InvalidateCompaniesAction
  | RequestCreateCompanyAction
  | CreateCompanySuccessAction
  | RequestUpdateCompanyAction
  | UpdateCompanySuccessAction
  | RequestAgentsAndBusCompaniesAction
  | ReceiveAgentsAndBusCompaniesAction;

export type Company = {
  id: number,
  name: string,
  companyType: {
    id: number,
    title: string,
  },
  owner?: number,
  phone: string,
  fax: string,
  address?: string,
  createdAt: number,
  updatedAt: number,
  isDeleted: boolean,
};

export type CompanyForm = {
  name: string,
  companyTypeId: {
    value: number,
  },
  phone: string,
  fax: string,
  address?: string,
};

type CompanyDataToBackend = {
  name: string,
  companyTypeId: number,
  phone: string,
  fax: string,
  address?: string,
  createdAt?: number, // Only create need this
  updatedAt: number,
};

const REQUEST_COMPANIES = '@@TGK/COMPANIES/REQUEST_COMPANIES';
const RECEIVE_COMPANIES = '@@TGK/COMPANIES/RECEIVE_COMPANIES';
const INVALIDATE_COMPANIES = '@@TGK/INVALIDATE_COMPANIES';

const REQUEST_CREATE_COMPANY = '@@TGK/COMPANIES/REQUEST_CREATE_COMPANY';
const CREATE_COMPANY_SUCCESS = '@@TGK/COMPANIES/CREATE_COMPANY_SUCCESS';

const REQUEST_UPDATE_COMPANY = '@@TGK/COMPANIES/REQUEST_UPDATE_COMPANY';
const UPDATE_COMPANY_SUCCESS = '@@TGK/COMPANIES/UPDATE_COMPANY_SUCCESS';

const REQUEST_AGENTS_AND_BUS_COMPANIES = '@@TGK/COMPANIES/REQUEST_AGENTS_AND_BUS_COMPANIES';
const RECEIVE_AGENTS_AND_BUS_COMPANIES = '@@TGK/COMPANIES/RECEIVE_AGENTS_AND_BUS_COMPANIES';

type NormalizedCompanies = {
  entities: {
    companies: {
      [string | number]: Company,
    },
  },
  result: Array<any>,
};
const normalizer = (data: Array<Company>): NormalizedCompanies => {
  const companiesSchema = new schema.Entity('companies');
  const companiesArray = new schema.Array(companiesSchema);

  return normalize(data, companiesArray);
};

type NormalizedCompaniesByTypes = {
  entities: {
    tgk: Object,
    bus: Object,
    agent: Object,
    hotel: Object,
  },
  result: Array<any>,
};
const normalizerByType = (data: Array<Company>, types: TypesState): NormalizedCompaniesByTypes => {
  const companyTypes = types.companyTypes.entities.companyTypes;
  const hotelSchema = new schema.Entity('hotel');
  const busSchema = new schema.Entity('bus');
  const agentSchema = new schema.Entity('agent');
  const tgkSchema = new schema.Entity('tgk');
  const companiesArray = new schema.Array(
    {
      hotel: hotelSchema,
      bus: busSchema,
      agent: agentSchema,
      tgk: tgkSchema,
    },
    input => `${companyTypes[input.companyTypeId].title}`
  );

  return normalize(data, companiesArray);
};

const reducer = (
  state: InitCompaniesState = {
    isFetching: false,
    isPosting: false,
    didInvalidate: false,
    list: [],
    listByType: [],
    agentsAndBusCompanies: [],
  },
  action: CompaniesAction
) => {
  switch (action.type) {
    case INVALIDATE_COMPANIES: {
      return Object.assign({}, state, {
        didInvalidate: true,
      });
    }
    case REQUEST_AGENTS_AND_BUS_COMPANIES:
    case REQUEST_COMPANIES: {
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    }
    case RECEIVE_COMPANIES: {
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        list: normalizer(action.data),
        listByType: normalizerByType(action.data, action.types),
      });
    }
    case REQUEST_UPDATE_COMPANY:
    case REQUEST_CREATE_COMPANY: {
      return Object.assign({}, state, {
        isPosting: true,
      });
    }
    case UPDATE_COMPANY_SUCCESS:
    case CREATE_COMPANY_SUCCESS: {
      return Object.assign({}, state, {
        isPosting: false,
      });
    }
    case RECEIVE_AGENTS_AND_BUS_COMPANIES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        agentsAndBusCompanies: normalizer(action.data),
      });
    default: {
      return state;
    }
  }
};

// Fetch Companies

type RequestCompaniesAction = {
  type: '@@TGK/COMPANIES/REQUEST_COMPANIES',
};

const requestCompanies = (): RequestCompaniesAction => ({
  type: REQUEST_COMPANIES,
});

type ReceiveCompaniesAction = {
  type: '@@TGK/COMPANIES/RECEIVE_COMPANIES',
  data: Array<Company>,
  types: TypesState,
};

const receiveCompanies = (data: Array<Company>, types: TypesState): ReceiveCompaniesAction => ({
  type: RECEIVE_COMPANIES,
  data,
  types,
});

type InvalidateCompaniesAction = {
  type: '@@TGK/INVALIDATE_COMPANIES',
};

export const invalidateCompanies = (): InvalidateCompaniesAction => ({
  type: INVALIDATE_COMPANIES,
});

const fetchCompanies = () => (dispatch: Dispatch, getState: GetState) => {
  dispatch(requestCompanies());

  return dispatch(fetchTypesIfNeeded())
    .then(() =>
      axios.get(`${baseUrl.host}/api/companies`, { withCredentials: true }).then(response => {
        if (dispatch(responseHandler(response)) === responseType.SUCCESS) {
          dispatch(receiveCompanies(response.data, getState().types));
        }
      })
    )
    .catch(error => {
      catchHandler(error);
    });
};

const shouldFetchCompanies = (companies: CompaniesState | InitCompaniesState): boolean => {
  if (companies.list.length === 0) {
    return true;
  } else if (companies.isFetching) {
    return false;
  }

  return companies.didInvalidate;
};

export const fetchCompaniesIfNeeded = (): ThunkAction => (
  dispatch: Dispatch,
  getState: GetState
) => {
  if (shouldFetchCompanies(getState().companies)) {
    return dispatch(fetchCompanies());
  }

  return Promise.resolve();
};

// Create Company
type RequestCreateCompanyAction = {
  type: '@@TGK/COMPANIES/REQUEST_CREATE_COMPANY',
};

const requestCreateCompany = (): RequestCreateCompanyAction => ({
  type: REQUEST_CREATE_COMPANY,
});

type CreateCompanySuccessAction = {
  type: '@@TGK/COMPANIES/CREATE_COMPANY_SUCCESS',
};

const createCompanySuccess = (): CreateCompanySuccessAction => ({
  type: CREATE_COMPANY_SUCCESS,
});

export const createCompany = (form: CompanyForm, successCallback: () => void) => (
  dispatch: Dispatch
) => {
  dispatch(requestCreateCompany());
  const data: CompanyDataToBackend = {
    name: form.name,
    companyTypeId: form.companyTypeId.value,
    phone: form.phone,
    fax: form.fax,
    address: form.address,
    createdAt: +new Date(), // Only create need this
    updatedAt: +new Date(),
  };

  return axios
    .post(`${baseUrl.host}/api/companies`, data, { withCredentials: true })
    .then(response => {
      if (dispatch(responseHandler(response)) === responseType.SUCCESS) {
        dispatch(createCompanySuccess());
        dispatch(invalidateCompanies());
        successCallback();
      }
    })
    .catch(error => {
      catchHandler(error);
    });
};

// Update Company
type RequestUpdateCompanyAction = {
  type: '@@TGK/COMPANIES/REQUEST_UPDATE_COMPANY',
};

const requestUpdateCompany = (): RequestUpdateCompanyAction => ({
  type: REQUEST_UPDATE_COMPANY,
});

type UpdateCompanySuccessAction = {
  type: '@@TGK/COMPANIES/UPDATE_COMPANY_SUCCESS',
};

const updateCompanySuccess = (): UpdateCompanySuccessAction => ({
  type: UPDATE_COMPANY_SUCCESS,
});

export const updateCompany = (id: string, form: CompanyForm, successCallback: () => void) => (
  dispatch: Dispatch
) => {
  dispatch(requestUpdateCompany());
  const data: CompanyDataToBackend = {
    name: form.name,
    companyTypeId: form.companyTypeId.value,
    phone: form.phone,
    fax: form.fax,
    address: form.address,
    updatedAt: +new Date(),
  };

  return axios
    .put(`${baseUrl.host}/api/companies/${id}`, data, { withCredentials: true })
    .then(response => {
      if (dispatch(responseHandler(response)) === responseType.SUCCESS) {
        dispatch(updateCompanySuccess());
        dispatch(invalidateCompanies());
        successCallback();
      }
    })
    .catch(error => {
      catchHandler(error);
    });
};

// Delete Company
export const deleteCompany = (id: string) => (dispatch: Dispatch) =>
  axios
    .delete(`${baseUrl.host}/api/companies/${id}`, { withCredentials: true })
    .then(response => {
      if (dispatch(responseHandler(response)) === responseType.SUCCESS) {
        dispatch(invalidateCompanies());
        dispatch(fetchCompaniesIfNeeded());
      }
    })
    .catch(error => {
      catchHandler(error);
    });

// Fetch agents and bus companies
type RequestAgentsAndBusCompaniesAction = {
  type: '@@TGK/COMPANIES/REQUEST_AGENTS_AND_BUS_COMPANIES',
};

const requestAgentsAndBusCompanies = (): RequestAgentsAndBusCompaniesAction => ({
  type: REQUEST_AGENTS_AND_BUS_COMPANIES,
});

type ReceiveAgentsAndBusCompaniesAction = {
  type: '@@TGK/COMPANIES/RECEIVE_AGENTS_AND_BUS_COMPANIES',
  data: Array<Company>,
};

const receiveAgentsAndBusCompanies = (data): ReceiveAgentsAndBusCompaniesAction => ({
  type: RECEIVE_AGENTS_AND_BUS_COMPANIES,
  data,
});

export const fetchAgentsAndBusCompanies = () => (dispatch: Dispatch) => {
  dispatch(requestAgentsAndBusCompanies());

  return axios
    .get(`${baseUrl.host}/api/agents-and-bus-companies`, {
      withCredentials: true,
    })
    .then(response => {
      if (dispatch(responseHandler(response)) === responseType.SUCCESS) {
        dispatch(receiveAgentsAndBusCompanies(response.data));
      }
    })
    .catch(error => {
      catchHandler(error);
    });
};

export default reducer;
