// @flow
/* eslint no-use-before-define: 0 */

import axios from 'axios';
import { normalize, schema } from 'normalizr';
import { responseType, responseHandler, catchHandler } from '../modules/auth';
import baseUrl from '../../utils/baseUrl';
import fetchLocalUser from '../../utils/fetchLocalUser';
import type { GetState, ThunkAction, Dispatch } from './index';

export type OrdersAction =
  | RequestOrdersAction
  | ReceiveOrdersAction
  | RequestPastOrdersAction
  | ReceivePastOrdersAction
  | RequestCanceledOrdersAction
  | ReceiveCanceledOrdersAction
  | InvalidateOrdersAction
  | RequestCreateOrderAction
  | CreateOrderSuccessAction
  | RequestUpdateOrderAction
  | UpdateOrderSuccessAction
  | RequestDeleteOrderAction
  | DeleteOrderSuccessAction;

export type InitOrderState = {
  +isFetching: boolean,
  +isPosting: boolean,
  +didInvalidate: boolean,
  +list: [],
  +pastList: [],
  +canceledList: [],
};

export type OrdersState = {
  +isFetching: boolean,
  +isPosting: boolean,
  +didInvalidate: boolean,
  +list: NormalizedOrders,
  +pastList: NormalizedOrders,
  +canceledList: NormalizedOrders,
};

export type Order = {
  busCompanyIds: Array<number>,
  busCompanyId: number,
  fromTime: number,
  id: number,
  issueCompany: {
    companyType: {
      id: number,
      title: string,
    },
    id: number,
    name: string,
    phone: string,
  },
  issueUser: {
    id: number,
    company: {
      id: number,
      name: string,
      phone: string,
    },
    email: string,
    firstName: string,
    lastName: string,
    jobType: {
      id: number,
      title: string,
    },
    mobilePhone: string,
  },
  note: any,
  subtitle: string,
  title: string,
  updatedAt: number,
  updatedBy: number,
  diff?: Array<*>,
  isDeleted: boolean,
};

type OrderDataToBackend = {
  issueCompanyId: number,
  issueUserId: number,
  title: string,
  subtitle: string,
  fromTime: number,
  toTime: number, // set to end of the day
  note: string,
  createdAt?: number, // Only create need this
  updatedAt: number,
  updatedBy: number,
};

const REQUEST_ORDERS = '@@TGK/ORDERS/REQUEST_ORDERS';
const RECEIVE_ORDERS = '@@TGK/ORDERS/RECEIVE_ORDERS';
const REQUEST_PAST_ORDERS = '@@TGK/ORDERS/REQUEST_PAST_ORDERS';
const RECEIVE_PAST_ORDERS = '@@TGK/ORDERS/RECEIVE_PAST_ORDERS';
const REQUEST_CANCELED_ORDERS = '@@TGK/ORDERS/REQUEST_CANCELED_ORDERS';
const RECEIVE_CANCELED_ORDERS = '@@TGK/ORDERS/RECEIVE_CANCELED_ORDERS';
const INVALIDATE_ORDERS = '@@TGK/ORDERS/INVALIDATE_ORDERS';

const REQUEST_CREATE_ORDER = '@@TGK/ORDERS/REQUEST_CREATE_ORDER';
const CREATE_ORDER_SUCCESS = '@@TGK/ORDERS/CREATE_ORDER_SUCCESS';

const REQUEST_UPDATE_ORDER = '@@TGK/ORDERS/REQUEST_UPDATE_ORDER';
const UPDATE_ORDER_SUCCESS = '@@TGK/ORDERS/UPDATE_ORDER_SUCCESS';

const REQUEST_DELETE_ORDER = '@@TGK/ORDERS/REQUEST_DELETE_ORDER';
const DELETE_ORDER_SUCCESS = '@@TGK/ORDERS/DELETE_ORDER_SUCCESS';

type NormalizedOrders = {
  entities: {
    orders: {
      [string | number]: Order,
    },
  },
  result: Array<any>,
};

const normalizer = (data: Array<Order>): NormalizedOrders => {
  const ordersSchema = new schema.Entity('orders');
  const ordersArray = new schema.Array(ordersSchema);

  return normalize(data, ordersArray);
};

const reducer = (
  state: InitOrderState = {
    isFetching: false,
    isPosting: false,
    didInvalidate: false,
    list: [],
    pastList: [],
    canceledList: [],
  },
  action: OrdersAction
) => {
  switch (action.type) {
    case INVALIDATE_ORDERS: {
      return Object.assign({}, state, {
        didInvalidate: true,
      });
    }
    case REQUEST_ORDERS:
    case REQUEST_PAST_ORDERS:
    case REQUEST_CANCELED_ORDERS: {
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    }
    case RECEIVE_ORDERS: {
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        list: normalizer(action.data),
      });
    }
    case RECEIVE_PAST_ORDERS: {
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        pastList: normalizer(action.data),
      });
    }
    case RECEIVE_CANCELED_ORDERS: {
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        canceledList: normalizer(action.data),
      });
    }
    case REQUEST_UPDATE_ORDER:
    case REQUEST_CREATE_ORDER: {
      return Object.assign({}, state, {
        isPosting: true,
      });
    }
    case UPDATE_ORDER_SUCCESS:
    case CREATE_ORDER_SUCCESS: {
      return Object.assign({}, state, {
        isPosting: false,
      });
    }
    default: {
      return state;
    }
  }
};

// ----- Actions -----
// Fetch Orders
type RequestOrdersAction = {
  type: '@@TGK/ORDERS/REQUEST_ORDERS',
};

const requestOrders = (): RequestOrdersAction => ({
  type: REQUEST_ORDERS,
});

type ReceiveOrdersAction = {
  type: '@@TGK/ORDERS/RECEIVE_ORDERS',
  data: Array<Order>,
};

const receiveOrders = (data: Array<Order>): ReceiveOrdersAction => ({
  type: RECEIVE_ORDERS,
  data,
});

type InvalidateOrdersAction = {
  type: '@@TGK/ORDERS/INVALIDATE_ORDERS',
};

export const invalidateOrders = (): InvalidateOrdersAction => ({
  type: INVALIDATE_ORDERS,
});

const fetchOrders = (): ThunkAction => dispatch => {
  dispatch(requestOrders());

  return axios
    .get(`${baseUrl.host}/api/bus-orders`, { withCredentials: true })
    .then(response => {
      if (dispatch(responseHandler(response)) === responseType.SUCCESS) {
        dispatch(receiveOrders(response.data));
      }
    })
    .catch(error => {
      catchHandler(error);
    });
};

// Fetch Past Orders
type RequestPastOrdersAction = {
  type: '@@TGK/ORDERS/REQUEST_PAST_ORDERS',
};

const requestPastOrders = (): RequestPastOrdersAction => ({
  type: REQUEST_PAST_ORDERS,
});

type ReceivePastOrdersAction = {
  type: '@@TGK/ORDERS/RECEIVE_PAST_ORDERS',
  data: Array<Order>,
};

const receivePastOrders = (data: Array<Order>): ReceivePastOrdersAction => ({
  type: RECEIVE_PAST_ORDERS,
  data,
});

const fetchPastOrders = (): ThunkAction => dispatch => {
  dispatch(requestPastOrders());

  return axios
    .get(`${baseUrl.host}/api/bus-orders?isPast=true`, {
      withCredentials: true,
    })
    .then(response => {
      if (dispatch(responseHandler(response)) === responseType.SUCCESS) {
        dispatch(receivePastOrders(response.data));
      }
    })
    .catch(error => {
      catchHandler(error);
    });
};

// Fetch Canceled Orders
type RequestCanceledOrdersAction = {
  type: '@@TGK/ORDERS/REQUEST_CANCELED_ORDERS',
};

const requestCanceledOrders = (): RequestCanceledOrdersAction => ({
  type: REQUEST_CANCELED_ORDERS,
});

type ReceiveCanceledOrdersAction = {
  type: '@@TGK/ORDERS/RECEIVE_CANCELED_ORDERS',
  data: Array<Order>,
};

const receiveCanceledOrders = (data: Array<Order>): ReceiveCanceledOrdersAction => ({
  type: RECEIVE_CANCELED_ORDERS,
  data,
});

const fetchCanceledOrders = (): ThunkAction => dispatch => {
  dispatch(requestCanceledOrders());

  return axios
    .get(`${baseUrl.host}/api/bus-orders?isDeleted=true`, {
      withCredentials: true,
    })
    .then(response => {
      if (dispatch(responseHandler(response)) === responseType.SUCCESS) {
        dispatch(receiveCanceledOrders(response.data));
      }
    })
    .catch(error => {
      catchHandler(error);
    });
};

const shouldFetchOrders = (orders: InitOrderState | OrdersState, orderType?: string) => {
  let list;

  if (orderType === 'past') {
    list = orders.pastList;
  } else if (orderType === 'canceled') {
    list = orders.canceledList;
  } else {
    list = orders.list;
  }

  if (list.length === 0) {
    return true;
  } else if (orders.isFetching) {
    return false;
  }

  return orders.didInvalidate;
};

export const fetchOrdersIfNeeded = (orderType?: string): ThunkAction => (
  dispatch: Dispatch,
  getState: GetState
) => {
  if (shouldFetchOrders(getState().orders, orderType)) {
    if (orderType === 'past') {
      return dispatch(fetchPastOrders());
    }

    if (orderType === 'canceled') {
      return dispatch(fetchCanceledOrders());
    }

    return dispatch(fetchOrders());
  }

  return Promise.resolve();
};

export const fetchOrdersImmedaitely = (orderType?: string): ThunkAction => (dispatch: Dispatch) => {
  if (orderType === 'past') {
    return dispatch(fetchPastOrders());
  }

  if (orderType === 'canceled') {
    return dispatch(fetchCanceledOrders());
  }

  return dispatch(fetchOrders());
};

// Create Order
type RequestCreateOrderAction = {
  type: '@@TGK/ORDERS/REQUEST_CREATE_ORDER',
};

const requestCreateOrder = (): RequestCreateOrderAction => ({
  type: REQUEST_CREATE_ORDER,
});

type CreateOrderSuccessAction = {
  type: '@@TGK/ORDERS/CREATE_ORDER_SUCCESS',
};

const createOrderSuccess = (): CreateOrderSuccessAction => ({
  type: CREATE_ORDER_SUCCESS,
});

export const createOrder = (form: Order, successCallback: (orderId: string) => void) => (
  dispatch: Dispatch
) => {
  dispatch(requestCreateOrder());
  const currentUser = fetchLocalUser();

  if (!currentUser) {
    return false;
  }

  const data: OrderDataToBackend = {
    issueCompanyId: currentUser.companyId,
    issueUserId: currentUser.id,
    title: form.title,
    subtitle: form.subtitle,
    fromTime: form.fromTime,
    toTime: +new Date(form.fromTime).setHours(23, 59, 59, 999), // set to end of the day
    note: form.note,
    createdAt: +new Date(), // Only create need this
    updatedAt: +new Date(),
    updatedBy: currentUser.id,
  };

  return axios
    .post(`${baseUrl.host}/api/bus-orders`, data, { withCredentials: true })
    .then(response => {
      if (dispatch(responseHandler(response)) === responseType.SUCCESS) {
        dispatch(invalidateOrders());
        dispatch(createOrderSuccess());
        successCallback(response.data.busOrderId);
      }
    })
    .catch(error => {
      catchHandler(error);
    });
};

// Update Order
type RequestUpdateOrderAction = {
  type: '@@TGK/ORDERS/REQUEST_UPDATE_ORDER',
};

const requestUpdateOrder = (): RequestUpdateOrderAction => ({
  type: REQUEST_UPDATE_ORDER,
});

type UpdateOrderSuccessAction = {
  type: '@@TGK/ORDERS/UPDATE_ORDER_SUCCESS',
};

const updateOrderSuccess = (): UpdateOrderSuccessAction => ({
  type: UPDATE_ORDER_SUCCESS,
});

export const updateOrder = (id: string, form: Order, successCallback: () => void): ThunkAction => (
  dispatch: Dispatch,
  getState: GetState
) => {
  dispatch(requestUpdateOrder());
  const currentUser = fetchLocalUser();

  if (!currentUser) {
    return false;
  }

  const data: OrderDataToBackend = {
    issueCompanyId: getState().orders.list.entities.orders[id].issueCompany.id,
    issueUserId: getState().orders.list.entities.orders[id].issueUser.id,
    title: form.title,
    subtitle: form.subtitle,
    fromTime: form.fromTime,
    toTime: +new Date(form.fromTime).setHours(23, 59, 59, 999), // set to end of the day
    note: form.note,
    updatedAt: +new Date(),
    updatedBy: currentUser.id,
  };

  return axios
    .put(`${baseUrl.host}/api/bus-orders/${id}`, data, {
      withCredentials: true,
    })
    .then(response => {
      if (dispatch(responseHandler(response)) === responseType.SUCCESS) {
        dispatch(invalidateOrders());
        dispatch(updateOrderSuccess());
        successCallback();
      }
    })
    .catch(error => {
      catchHandler(error);
    });
};

// Delete Order

type RequestDeleteOrderAction = {
  type: '@@TGK/ORDERS/REQUEST_DELETE_ORDER',
};

const requestDeleteOrder = (): RequestDeleteOrderAction => ({
  type: REQUEST_DELETE_ORDER,
});

type DeleteOrderSuccessAction = {
  type: '@@TGK/ORDERS/DELETE_ORDER_SUCCESS',
};

const deleteOrderSuccess = (): DeleteOrderSuccessAction => ({
  type: DELETE_ORDER_SUCCESS,
});

export const deleteOrder = (orderId: string): ThunkAction => (dispatch: Dispatch) => {
  dispatch(requestDeleteOrder());

  const data = {
    isDeleted: true,
  };

  return axios
    .put(`${baseUrl.host}/api/bus-orders/${orderId}`, data, {
      withCredentials: true,
    })
    .then(response => {
      if (dispatch(responseHandler(response)) === responseType.SUCCESS) {
        dispatch(deleteOrderSuccess());
        dispatch(invalidateOrders());
      }
    })
    .then(() => {
      dispatch(fetchOrdersIfNeeded());
    })
    .catch(error => {
      catchHandler(error);
    });
};

export default reducer;
