// @flow

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import idx from 'idx';

import LocationTable from './LocationTable';

import type { LocationsState } from '../../redux/modules/locations';

import styles from './Locations.module.scss';

type Props = {
  t: string => string,
  locations: LocationsState,
  fetchLocationsIfNeeded: () => void,
  deleteLocation: (locationId: string) => void,
  updateLocation: (locationId: string, title: string) => void,
};

type State = {
  isSearching: boolean,
};

class Locations extends Component<Props, State> {
  state = {
    isSearching: false,
  };

  componentDidMount() {
    this.props.fetchLocationsIfNeeded();
  }

  toggleSearching = () => {
    const searchingState = this.state.isSearching;

    this.setState({
      isSearching: !searchingState,
    });
  };

  render() {
    const { t, locations, deleteLocation, updateLocation } = this.props;
    const isLoading = locations.isFetching;
    const locationsEntities = idx(locations, _ => _.list.entities.locations);
    const Loading = (
      <div className="loading">
        <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw" />
        <span className="sr-only">{t('loading')}</span>
      </div>
    );
    const LocationList = locationsEntities ? (
      <LocationTable
        t={t}
        title={t('users.userList')}
        locationsEntities={locationsEntities}
        deleteLocation={deleteLocation}
        updateLocation={updateLocation}
        isSearching={this.state.isSearching}
      />
    ) : (
      <div className={styles.empty}>{t('users.empty')}</div>
    );

    return (
      <div className={styles.root}>
        <h2>{t('locations.locationList')}</h2>
        <div className="corner-btn-group">
          <a role="button" tabIndex="-1" className="btn-manage" onClick={this.toggleSearching}>
            {this.state.isSearching ? (
              <i className="fas fa-times" />
            ) : (
              <i title={t('users.search')} className="fa fa-search" />
            )}
          </a>
          <Link title={t('users.create')} to="/admin/locations/create" className="btn-manage">
            <i className="fas fa-plus" />
          </Link>
        </div>
        {isLoading ? Loading : LocationList}
      </div>
    );
  }
}

export default Locations;
