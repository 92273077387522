// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { login } from '../../redux/modules/auth';
import Login from './Login';

type Dispatch = (action: any) => any;

type Form = {
  email: string,
  password: any,
};

type ActionProps = {
  login: (form: Form) => any,
};

type Props = ActionProps & {
  t: (key: string, ?Object) => string,
};

function LoginContainer(props: Props) {
  return (
    <Login
      t={props.t}
      onSubmit={form => {
        props.login(form);
      }}
    />
  );
}

// $FlowFixMe
const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
  login: bindActionCreators(login, dispatch),
});

// $FlowFixMe
export default connect(undefined, mapDispatchToProps)(LoginContainer);
