// @flow
import axios from 'axios';
import idx from 'idx';
import { catchHandler } from './auth';
import { msgMapping } from '../../utils/msgType';
import baseUrl from '../../utils/baseUrl';
import type { GetState, Dispatch } from './index';

const directSendSMS = (msgType: string, mobilePhone: string, info: any) => (
  dispatch: Dispatch,
  getState: GetState
) => {
  const currentUser = idx(getState().auth, _ => _.currentUser);

  if (!currentUser) {
    return Promise.reject('No current user, login session expired');
  }

  const data = Object.assign(
    {},
    {
      to: mobilePhone,
    },
    msgMapping('sms', msgType, `${currentUser.lastName} ${currentUser.firstName}`, info)
  );

  // $FlowFixMe
  return axios.post(`${baseUrl.host}/api/sms`, data, { withCredentials: true }).catch(error => {
    catchHandler(error);
  });
};

export default directSendSMS;
