// @flow
import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import format from 'date-fns/format';
import jaLocale from 'date-fns/locale/ja';
import type { RouterHistory } from 'react-router-dom';
import idx from 'idx';
import cx from 'classnames';
import type { FormProps } from 'redux-form';

import { userSelectNormalizer, companySelectNormalizer } from './normalizers';
import DateField from '../../Shared/DateField';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import AutoSelectField from '../../Shared/AutoSelectField';
import InputField from '../../Shared/InputField';

import type { Order } from '../../../redux/modules/orders';
import type { UsersState } from '../../../redux/modules/users';
import type { CompaniesState } from '../../../redux/modules/companies';

import allowOnly from '../../../utils/allowOnly';
import { TGK_ADMINS, HOTEL_ADMINS, BUS_ADMINS } from '../../../utils/jobTitleMapping';

import styles from '../OrderForm/OrderForm.module.scss';
import labelStyles from './Labels.module.scss';

type DropdownItem = {
  value: any,
  text: string,
};

const userList = (
  users: UsersState,
  jobType: 'driver' | 'tourGuide',
  hasBusCompanyId?: number | { value: number, text: string }
): Array<DropdownItem> => {
  const userListItems = idx(users, _ => _.driversAndTourGuides.entities.users);

  if (userListItems) {
    return Object.keys(userListItems).reduce((acc: any, cur: string) => {
      if (userListItems[cur].jobType.title === jobType) {
        // Drivers list will be decided by busCompanyId
        if (hasBusCompanyId) {
          const companyId = (hasBusCompanyId && hasBusCompanyId.value) || hasBusCompanyId;

          if (userListItems[cur].company.id === companyId) {
            return acc.concat(userSelectNormalizer(userListItems[cur]));
          }

          return [...acc];
        }

        return acc.concat(userSelectNormalizer(userListItems[cur]));
      }

      return [...acc];
    }, []);
  }

  return [];
};

const companyList = (companies: CompaniesState): Array<DropdownItem> => {
  const companiesListItems = idx(companies, _ => _.agentsAndBusCompanies.entities.companies);

  if (companiesListItems) {
    return Object.keys(companiesListItems).reduce((acc: any, cur: string) => {
      // id 4 is busCompany
      if (companiesListItems[cur].companyType.id === 4) {
        return acc.concat(companySelectNormalizer(companiesListItems[cur]));
      }

      return [...acc];
    }, []);
  }

  return [];
};

type Props = FormProps & {
  t: string => string,
  orderId: string,
  order: Order,
  rowId: string,
  users: UsersState,
  companies: CompaniesState,
  history: RouterHistory,
  showModal: (status: boolean) => void,
  setModalInfo: (info: any) => void,
};

let Form = (props: Props) => {
  const {
    t,
    orderId,
    order,
    handleSubmit,
    reset,
    history,
    auth,
    submitting,
    pristine,
    error,
    users,
    companies,
    hasBusCompanyId,
  } = props;

  const handleCancel = () => {
    reset();
    history.push(`/orders/${orderId}/rows`);
  };

  const isLoading = false;
  const currentJobTypeTitle = idx(auth, _ => _.currentUser.jobTypeTitle);
  const orderTime = idx(order, _ => _.fromTime);
  // $FlowFixMe
  const orderStartTime = new Date(orderTime).setHours(0, 0, 0, 0);
  // $FlowFixMe
  const orderEndTime = new Date(orderTime).setHours(23, 59, 59, 999);
  const orderTitle = idx(order, _ => _.title);
  const breadcrumbItems = [
    {
      link: '/orders',
      text: t('menu.orderList'),
    },
    {
      link: `/orders/${orderId}/rows`,
      text: orderTitle || '',
    },
    {
      link: '',
      text: t('orderRowForm.shuttle') || '',
    },
  ];
  const driverOptions = userList(users, 'driver', hasBusCompanyId);
  const busCompaniesOptions = companyList(companies);

  return isLoading ? (
    <div className="loading">
      <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw" />
      <span className="sr-only">{t('loading')}</span>
    </div>
  ) : (
    <div>
      <Breadcrumbs items={breadcrumbItems} />
      <form onSubmit={handleSubmit} className={styles.form}>
        {order && (
          <section className="form-title">
            <h2 className="title">
              {format(new Date(order.fromTime), 'YYYY/MM/DD', {
                locale: jaLocale,
              })}{' '}
              {order.title}
            </h2>
            <p className="sub">全てご記入ください</p>
          </section>
        )}
        <fieldset>
          <Field
            name="title"
            component={InputField}
            type="text"
            label={t('orderRowForm.title')}
            placeholder={t('orderRowForm.placeholder.title')}
            required
            disabled={!allowOnly(currentJobTypeTitle, HOTEL_ADMINS)}
          />
        </fieldset>
        <fieldset>
          <Field
            name="fromTime"
            component={DateField}
            type="text"
            label={t('orderRowForm.fromTime')}
            placeholder={t('orderRowForm.placeholder.fromTime')}
            required
            defaultDate={orderStartTime}
            options={{
              noCalendar: true,
              minDate: orderStartTime,
              maxDate: orderEndTime,
              allowInput: false,
              enableTime: true,
              dateFormat: 'Y/m/d H:i',
              time_24hr: true,
            }}
            disabled={!allowOnly(currentJobTypeTitle, HOTEL_ADMINS)}
          />
        </fieldset>
        <fieldset>
          <div className={labelStyles.locations}>
            <label>{t('orderRowForm.passByLocations')}</label>
            <div className={labelStyles.panel}>
              <div
                className={cx({
                  [`${labelStyles.rendered}`]: true,
                  [`${labelStyles['full-width']}`]: true,
                })}
              >
                <div
                  className={cx({
                    [`${labelStyles.tag}`]: true,
                  })}
                >
                  {t('orderRowForm.shuttle')}
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <section className="form-section">
          <h3>{t('orderRowForm.busInfo')}</h3>
          <fieldset>
            <Field
              name="busCompany"
              component={AutoSelectField}
              options={busCompaniesOptions}
              label={t('orderRowForm.busCompanyId')}
              placeholder={t('orderRowForm.placeholder.busCompanyId')}
              required
              disabled={!allowOnly(currentJobTypeTitle, TGK_ADMINS)}
              onChange={(e, newValue, previousValue) => {
                const newId = (newValue && newValue.value) || newValue;
                const previousId = (previousValue && previousValue.value) || previousValue;

                props.setModalInfo({
                  busCompanyId: newId,
                });

                if (newId !== previousId) {
                  // Reset the driver
                  props.change('driverUser', null);
                }
              }}
            />
          </fieldset>
          <fieldset>
            <Field
              className="select-field"
              name="driverUser"
              component={AutoSelectField}
              options={driverOptions}
              label={t('orderRowForm.driver')}
              placeholder={t('orderRowForm.placeholder.driver')}
              disabled={!hasBusCompanyId || !allowOnly(currentJobTypeTitle, BUS_ADMINS)}
              actionLink={() => {
                const addUserLink =
                  hasBusCompanyId && allowOnly(currentJobTypeTitle, BUS_ADMINS) ? (
                    <a
                      role="button"
                      tabIndex="-1"
                      className="create-new"
                      onClick={() => {
                        const companyId =
                          (hasBusCompanyId && hasBusCompanyId.value) || hasBusCompanyId;

                        props.showModal(true);
                        props.setModalInfo({
                          company: {
                            id: companyId,
                          },
                        });
                      }}
                    >
                      {t('orderRowForm.addDriver')}
                    </a>
                  ) : null;

                return addUserLink;
              }}
            />
          </fieldset>
          <fieldset>
            <Field
              name="busPlate"
              component={InputField}
              type="text"
              label={t('orderRowForm.busPlate')}
              placeholder={t('orderRowForm.placeholder.busPlate')}
              disabled={!allowOnly(currentJobTypeTitle, BUS_ADMINS)}
            />
          </fieldset>
        </section>
        <div className="btn-group">
          {error && <span className={styles['form-error']}>{error}</span>}
          <button className="btn" onClick={handleCancel} type="button">
            {t('button.cancel')}
          </button>
          <button
            className="btn primary"
            onSubmit={handleSubmit}
            type="submit"
            disabled={submitting || pristine}
          >
            {t('button.submit')}
          </button>
        </div>
      </form>
    </div>
  );
};

Form = reduxForm({
  form: 'shuttleOrderRow',
})(Form);

// Decorate with connect to read form values
const selector = formValueSelector('shuttleOrderRow'); // <-- same as form name

const mapStateToProps = state => {
  // can select values individually
  const hasBusCompanyId = selector(state, 'busCompany');

  return {
    hasBusCompanyId,
  };
};

// $FlowFixMe
export default connect(mapStateToProps)(Form);
