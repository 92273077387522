// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import type { RouterHistory } from 'react-router-dom';
import idx from 'idx';
import { SubmissionError } from 'redux-form';
import { getCurrentUser } from '../../../redux/modules/auth';
import { fetchOrdersIfNeeded } from '../../../redux/modules/orders';
import {
  createOrderRow,
  updateOrderRow,
  fetchOrderRowsIfNeeded,
} from '../../../redux/modules/orderRows';
import { fetchDriversAndTourGuides } from '../../../redux/modules/users';
import { fetchLocationsIfNeeded, createLocation } from '../../../redux/modules/locations';
import { fetchAgentsAndBusCompanies } from '../../../redux/modules/companies';
import { showModal, setModalInfo } from '../../../redux/modules/modal';
import OrderRowForm from './OrderRowForm';
import UserModal from '../../UserModal/UserModalContainer';
import { userSelectNormalizer, companySelectNormalizer, locationNormalizer } from './normalizers';

import type { GlobalState } from '../../../redux/modules/index';
import type { OrdersState } from '../../../redux/modules/orders';
import type { UsersState } from '../../../redux/modules/users';
import type { LocationsState, LocationData } from '../../../redux/modules/locations';
import type { OrderRowsState, OrderRowFormData, OrderRow } from '../../../redux/modules/orderRows';
import type { AuthState } from '../../../redux/modules/auth';
import type { CompaniesState } from '../../../redux/modules/companies';
import type { ModalState } from '../../../redux/modules/modal';

type Dispatch = (action: any) => any;

type StateProps = {
  auth: AuthState,
  orders: OrdersState,
  orderRows: OrderRowsState,
  users: UsersState,
  locations: LocationsState,
  companies: CompaniesState,
  modal: ModalState,
};

type ActionProps = {
  fetchOrdersIfNeeded: (orderType?: string) => any,
  fetchOrderRowsIfNeeded: (orderId: string) => any,
  fetchLocationsIfNeeded: () => any,
  fetchDriversAndTourGuides: () => any,
  fetchAgentsAndBusCompanies: () => any,
  createOrderRow: (
    orderId: string,
    form: OrderRowFormData,
    successCallback: () => void,
    isShuttle: false
  ) => any,
  updateOrderRow: (
    rowId: string,
    orderId: string,
    form: OrderRowFormData,
    successCallback: () => void
  ) => any,
  createLocation: (location: LocationData) => any,
  getCurrentUser: () => any,
  showModal: (status: boolean) => any,
  setModalInfo: (info: any) => any,
};

type Props = StateProps &
  ActionProps & {
    t: string => string,
    history: RouterHistory,
    orderId: string,
    rowId?: string, // eslint-disable-line
  };

const normalizeInitialValues = (orderRow: OrderRow) => {
  const initialValues = { ...orderRow };

  if (orderRow.passByLocations && orderRow.passByLocations.length > 0) {
    initialValues.passByLocations = orderRow.passByLocations.map(value =>
      locationNormalizer(value)
    );
  }

  if (orderRow.busCompany) {
    initialValues.busCompany = companySelectNormalizer(orderRow.busCompany);
  }

  if (orderRow.tourGuideUser) {
    initialValues.tourGuideUser = userSelectNormalizer(orderRow.tourGuideUser);
  }

  if (orderRow.driverUser) {
    initialValues.driverUser = userSelectNormalizer(orderRow.driverUser);
  }

  return initialValues;
};

class OrderRowFormContainer extends Component<Props> {
  componentDidMount() {
    this.props
      .getCurrentUser()
      .then(() =>
        Promise.all([
          this.props.fetchDriversAndTourGuides(),
          this.props.fetchAgentsAndBusCompanies(),
          this.props.fetchLocationsIfNeeded(),
        ])
      )
      .then(() => {
        this.props.fetchOrdersIfNeeded();
        this.props.fetchOrderRowsIfNeeded(this.props.orderId);
      });
  }

  render() {
    const {
      t,
      history,
      orders,
      orderId,
      rowId,
      orderRows,
      users,
      locations,
      auth,
      companies,
    } = this.props;
    let initialValues = {};
    let orderRow;

    if (rowId) {
      orderRow = idx(orderRows, _ => _.list[orderId].entities.rows[rowId]);
    }

    if (orderRow) {
      initialValues = normalizeInitialValues(orderRow);
    }

    const order = orders.list.entities && orders.list.entities.orders[orderId];
    // Do not send async intialValues until it has value
    // otherwise redux-form will not initialize correctly
    const emptyInitialValues =
      Object.keys(initialValues).length === 0 && initialValues.constructor === Object;

    return (
      <div>
        <OrderRowForm
          t={t}
          order={order}
          orderId={orderId}
          rowId={rowId}
          users={users}
          auth={auth}
          passByLocations={emptyInitialValues ? undefined : initialValues.passByLocations}
          locations={locations}
          companies={companies}
          createLocation={this.props.createLocation}
          initialValues={emptyInitialValues ? undefined : initialValues}
          history={history}
          onSubmit={form => {
            ['title'].forEach(field => {
              if (!form[field]) {
                throw new SubmissionError({
                  [`${field}`]: '必須項目です',
                  _error: '全てご記入ください',
                });
              }

              if (field === 'passByLocations') {
                if (form[field] && form[field].length === 0) {
                  throw new SubmissionError({
                    [`${field}`]: '必須項目です',
                    _error: '全てご記入ください',
                  });
                }
              }
            });

            const successCallback = () => {
              history.push(`/orders/${orderId}/rows`);
            };

            if (rowId) {
              this.props.updateOrderRow(rowId, orderId, form, successCallback);
            } else {
              this.props.createOrderRow(orderId, form, successCallback, false);
            }
          }}
          showModal={this.props.showModal}
          setModalInfo={this.props.setModalInfo}
        />
        {this.props.modal.show && <UserModal t={t} />}
      </div>
    );
  }
}

// $FlowFixMe
const mapStateToProps = (state: GlobalState): StateProps => ({
  auth: state.auth,
  orders: state.orders,
  orderRows: state.orderRows,
  users: state.users,
  locations: state.locations,
  companies: state.companies,
  modal: state.modal,
});

// $FlowFixMe
const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
  fetchOrdersIfNeeded: bindActionCreators(fetchOrdersIfNeeded, dispatch),
  fetchOrderRowsIfNeeded: bindActionCreators(fetchOrderRowsIfNeeded, dispatch),
  createOrderRow: bindActionCreators(createOrderRow, dispatch),
  updateOrderRow: bindActionCreators(updateOrderRow, dispatch),
  fetchDriversAndTourGuides: bindActionCreators(fetchDriversAndTourGuides, dispatch),
  fetchAgentsAndBusCompanies: bindActionCreators(fetchAgentsAndBusCompanies, dispatch),
  fetchLocationsIfNeeded: bindActionCreators(fetchLocationsIfNeeded, dispatch),
  createLocation: bindActionCreators(createLocation, dispatch),
  getCurrentUser: bindActionCreators(getCurrentUser, dispatch),
  showModal: bindActionCreators(showModal, dispatch),
  setModalInfo: bindActionCreators(setModalInfo, dispatch),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(OrderRowFormContainer);
