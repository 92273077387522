// @flow

import axios from 'axios';
import idx from 'idx';
import { catchHandler } from './auth';
import baseUrl from '../../utils/baseUrl';
import { msgMapping } from '../../utils/msgType';
import type { GetState, Dispatch } from './index';

const directSendMail = (msgType: string, email: string, info: any) => (
  dispatch: Dispatch,
  getState: GetState
) => {
  const currentUser = idx(getState().auth, _ => _.currentUser);

  if (!currentUser) {
    return Promise.reject('No current user, login session expired');
  }

  const data = Object.assign(
    {},
    {
      to: email,
    },
    msgMapping('mail', msgType, `${currentUser.lastName} ${currentUser.firstName}`, info)
  );

  // $FlowFixMe
  return axios.post(`${baseUrl.host}/api/mail`, data, { withCredentials: true }).catch(error => {
    catchHandler(error);
  });
};

export default directSendMail;
