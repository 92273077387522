// @flow
/* eslint no-use-before-define: 0 */

import axios from 'axios';
import { normalize, schema } from 'normalizr';
import { toast } from 'react-toastify';
import { responseType, responseHandler, catchHandler } from '../modules/auth';
import baseUrl from '../../utils/baseUrl';
import { msgTypes } from '../../utils/msgType';
import directSendSMS from './sms';
import directSendMail from './mail';

import type { GetState, ThunkAction, Dispatch } from './index';

type InitUserState = {
  currentUser: string,
  isFetching: boolean,
  didInvalidate: boolean,
  list: [],
  listByType: [],
  driversAndTourGuides: [],
};

export type UsersState = {
  +currentUser: string,
  +isFetching: boolean,
  +didInvalidate: boolean,
  +list: NormalizedUsers,
  +listByType: NormalizedUsersByTypes,
  +driversAndTourGuides: NormalizedUsers,
};

export type User = {
  id: number,
  jobType: {
    id: number,
    title: string,
  },
  company: {
    id: number,
    name: string,
    phone: string,
  },
  email: string,
  firstName: string,
  lastName: string,
  localPhone: string,
  mobilePhone: string,
  role: number,
  createdAt: number,
  updatedAt: number,
};

export type UserForm = {
  id: number,
  jobType:
    | {
        id: number,
      }
    | {
        id: {
          value: number,
          text: string,
        },
      },
  company:
    | {
        id: number,
      }
    | {
        id: {
          value: number,
          text: string,
        },
      },
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  localPhone: string,
  mobilePhone: string,
  role: number,
  createdAt: number,
  updatedAt: number,
};

type UserDataToBackend = {
  email: string,
  password?: string,
  firstName: string,
  lastName: string,
  localPhone: string,
  mobilePhone: string,
  createdAt?: number, // Only create need this
  updatedAt: number,
  companyId: any,
  jobTypeId: any,
};

type PersonInfoToBackend = {
  email: string,
  password?: string,
  firstName: string,
  lastName: string,
  localPhone: string,
  mobilePhone: string,
  updatedAt: number,
};

type UserPasswordToBackend = {
  password: string,
  updatedAt: number,
};

export type UsersAction =
  | RequestUsersAction
  | ReceiveUsersAction
  | InvalidateUsersAction
  | RequestCreateUserAction
  | CreateUserSuccessAction
  | RequestUpdateUserAction
  | UpdateUserSuccessAction
  | RequestDriversAndTourguidesAction
  | ReceiveDriversAndTourguidesAction;

const REQUEST_USERS = '@@TGK/USERS/REQUEST_USERS';
const RECEIVE_USERS = '@@TGK/USERS/RECEIVE_USERS';
const INVALIDATE_USERS = '@@TGK/INVALIDATE_USERS';

const REQUEST_CREATE_USER = '@@TGK/USERS/REQUEST_CREATE_USER';
const CREATE_USER_SUCCESS = '@@TGK/USERS/CREATE_USER_SUCCESS';

const REQUEST_UPDATE_USER = '@@TGK/USERS/REQUEST_UPDATE_USER';
const UPDATE_USER_SUCCESS = '@@TGK/USERS/UPDATE_USER_SUCCESS';

const REQUEST_DRIVERS_AND_TOURGUIDES = '@@TGK/USERS/REQUEST_DRIVERS_AND_TOURGUIDES';
const RECEIVE_DRIVERS_AND_TOURGUIDES = '@@TGK/USERS/RECEIVE_DRIVERS_AND_TOURGUIDES';

type NormalizedUsers = {
  entities: {
    users: {
      [string]: User,
    },
  },
  result: Array<any>,
};

const normalizer = (data: Array<User>): NormalizedUsers => {
  const userSchema = new schema.Entity('users');
  const userListSchema = [userSchema];

  return normalize(data, userListSchema);
};

type NormalizedUsersByTypes = {
  entities: {
    admin: Object,
    operator: Object,
    tourGuide: Object,
    driver: Object,
  },
  result: Array<any>,
};

const normalizerByJobType = (data: Array<User>): NormalizedUsersByTypes => {
  const adminSchema = new schema.Entity('admin');
  const operatorSchema = new schema.Entity('operator');
  const tourGuideSchema = new schema.Entity('tourGuide');
  const driverSchema = new schema.Entity('driver');
  const usersArray = new schema.Array(
    {
      admin: adminSchema,
      operator: operatorSchema,
      tourGuide: tourGuideSchema,
      driver: driverSchema,
    },
    input => `${input.jobType.title}`
  );

  return normalize(data, usersArray);
};

const reducer = (
  state: InitUserState = {
    currentUser: '',
    isFetching: false,
    didInvalidate: false,
    list: [],
    listByType: [],
    driversAndTourGuides: [],
  },
  action: UsersAction
) => {
  switch (action.type) {
    case INVALIDATE_USERS: {
      return Object.assign({}, state, {
        didInvalidate: true,
      });
    }
    case REQUEST_USERS:
    case REQUEST_DRIVERS_AND_TOURGUIDES: {
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    }
    case RECEIVE_USERS: {
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        list: normalizer(action.data),
        listByType: normalizerByJobType(action.data),
      });
    }
    case REQUEST_UPDATE_USER:
    case REQUEST_CREATE_USER: {
      return Object.assign({}, state, {
        isPosting: true,
      });
    }
    case UPDATE_USER_SUCCESS:
    case CREATE_USER_SUCCESS: {
      return Object.assign({}, state, {
        isPosting: false,
      });
    }
    case RECEIVE_DRIVERS_AND_TOURGUIDES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        driversAndTourGuides: normalizer(action.data),
      });
    default: {
      return state;
    }
  }
};

type RequestUsersAction = {
  type: '@@TGK/USERS/REQUEST_USERS',
};

const requestUsers = (): RequestUsersAction => ({
  type: REQUEST_USERS,
});

type ReceiveUsersAction = {
  type: '@@TGK/USERS/RECEIVE_USERS',
  data: Array<User>,
};

const receiveUsers = (data): ReceiveUsersAction => ({
  type: RECEIVE_USERS,
  data,
});

type InvalidateUsersAction = {
  type: '@@TGK/INVALIDATE_USERS',
};

export const invalidateUsers = (): InvalidateUsersAction => ({
  type: INVALIDATE_USERS,
});

const fetchUsers = () => (dispatch: Dispatch) => {
  dispatch(requestUsers());

  return axios
    .get(`${baseUrl.host}/api/users`, { withCredentials: true })
    .then(response => {
      if (dispatch(responseHandler(response)) === responseType.SUCCESS) {
        dispatch(receiveUsers(response.data));
      }
    })
    .catch(error => {
      catchHandler(error);
    });
};

const shouldFetchUsers = (users: UsersState | InitUserState): boolean => {
  if (users.list.length === 0) {
    return true;
  } else if (users.isFetching) {
    return false;
  }

  return users.didInvalidate;
};

export const fetchUsersIfNeeded = (): ThunkAction => (dispatch: Dispatch, getState: GetState) => {
  if (shouldFetchUsers(getState().users)) {
    return dispatch(fetchUsers());
  }

  return Promise.resolve();
};

type RequestCreateUserAction = {
  type: '@@TGK/USERS/REQUEST_CREATE_USER',
};

const requestCreateUser = (): RequestCreateUserAction => ({
  type: REQUEST_CREATE_USER,
});

type CreateUserSuccessAction = {
  type: '@@TGK/USERS/CREATE_USER_SUCCESS',
};

const createUserSuccess = (): CreateUserSuccessAction => ({
  type: CREATE_USER_SUCCESS,
});

export const createUser = (form: UserForm, successCallback: () => void) => (dispatch: Dispatch) => {
  dispatch(requestCreateUser());

  const data: UserDataToBackend = {
    lastName: form.lastName,
    firstName: form.firstName,
    email: form.email,
    password: form.password,
    localPhone: form.localPhone,
    mobilePhone: form.mobilePhone,
    createdAt: +new Date(), // Only create need this
    updatedAt: +new Date(),
    companyId: form.company ? form.company.id.value || form.company.id : null,
    jobTypeId: form.jobType ? form.jobType.id.value || form.jobType.id : null,
    role: 2, // as default
  };

  return axios
    .post(`${baseUrl.host}/auth/signup`, data, { withCredentials: true })
    .then(response => {
      if (dispatch(responseHandler(response)) === responseType.SUCCESS) {
        dispatch(invalidateUsers());
        dispatch(createUserSuccess());
        dispatch(sendNotifications(msgTypes.NEW_USER, form.mobilePhone, form.email, form.password));
        successCallback();
      }
    })
    .catch(error => {
      catchHandler(error);
    });
};

type RequestUpdateUserAction = {
  type: '@@TGK/USERS/REQUEST_UPDATE_USER',
};

const requestUpdateUser = (): RequestUpdateUserAction => ({
  type: REQUEST_UPDATE_USER,
});

type UpdateUserSuccessAction = {
  type: '@@TGK/USERS/UPDATE_USER_SUCCESS',
};

const updateUserSuccess = (): UpdateUserSuccessAction => ({
  type: UPDATE_USER_SUCCESS,
});

export const updateUser = (id: string, form: UserForm, successCallback: () => void) => (
  dispatch: Dispatch
) => {
  dispatch(requestUpdateUser());
  const data: UserDataToBackend = {
    lastName: form.lastName,
    firstName: form.firstName,
    email: form.email,
    localPhone: form.localPhone,
    mobilePhone: form.mobilePhone,
    jobTypeId: form.jobType ? form.jobType.id.value || form.jobType.id : null,
    companyId: form.company ? form.company.id.value || form.company.id : null,
    updatedAt: +new Date(),
  };

  return axios
    .put(`${baseUrl.host}/api/users/${id}`, data, { withCredentials: true })
    .then(response => {
      if (dispatch(responseHandler(response)) === responseType.SUCCESS) {
        dispatch(invalidateUsers());
        dispatch(updateUserSuccess());
        successCallback();
      }
    })
    .catch(error => {
      catchHandler(error);
    });
};

export const updatePersonalInfo = (id: string, form: UserForm, successCallback: () => void) => (
  dispatch: Dispatch
) => {
  dispatch(requestUpdateUser());

  const data: PersonInfoToBackend = {
    lastName: form.lastName,
    firstName: form.firstName,
    email: form.email,
    localPhone: form.localPhone,
    mobilePhone: form.mobilePhone,
    updatedAt: +new Date(),
  };

  return axios
    .put(`${baseUrl.host}/api/users/${id}`, data, { withCredentials: true })
    .then(response => {
      if (dispatch(responseHandler(response)) === responseType.SUCCESS) {
        dispatch(invalidateUsers());
        dispatch(updateUserSuccess());
        successCallback();
      }
    })
    .catch(error => {
      catchHandler(error);
    });
};

export const updatePassword = (id: string, form: any, successCallback: () => void) => (
  dispatch: Dispatch
) => {
  dispatch(requestUpdateUser());

  const data: UserPasswordToBackend = {
    password: form.password,
    updatedAt: +new Date(),
  };

  return axios
    .put(`${baseUrl.host}/api/users/${id}`, data, { withCredentials: true })
    .then(response => {
      if (dispatch(responseHandler(response)) === responseType.SUCCESS) {
        dispatch(invalidateUsers());
        dispatch(updateUserSuccess());
        successCallback();
      }
    })
    .catch(error => {
      catchHandler(error);
    });
};

export const deleteUser = (id: string) => (dispatch: Dispatch) =>
  axios
    .delete(`${baseUrl.host}/api/users/${id}`, { withCredentials: true })
    .then(response => {
      if (dispatch(responseHandler(response)) === responseType.SUCCESS) {
        dispatch(fetchUsers());
      }
    })
    .catch(error => {
      catchHandler(error);
    });

const sendNotifications = (
  msgType: string,
  mobilePhone: string,
  email: string,
  defaultPassword: string
) => (dispatch: Dispatch) => {
  const msgInfo = {
    defaultPassword,
  };

  dispatch(directSendSMS(msgType, mobilePhone, msgInfo)).catch(errMsg => {
    toast.error(errMsg);
  });
  dispatch(directSendMail(msgType, email, msgInfo)).catch(errMsg => {
    toast.error(errMsg);
  });
};

// Fetch drivers and tourGuides
type RequestDriversAndTourguidesAction = {
  type: '@@TGK/USERS/REQUEST_DRIVERS_AND_TOURGUIDES',
};

const requestDriversAndTourguides = (): RequestDriversAndTourguidesAction => ({
  type: REQUEST_DRIVERS_AND_TOURGUIDES,
});

type ReceiveDriversAndTourguidesAction = {
  type: '@@TGK/USERS/RECEIVE_DRIVERS_AND_TOURGUIDES',
  data: Array<User>,
};

const receiveDriversAndTourguides = (data): ReceiveDriversAndTourguidesAction => ({
  type: RECEIVE_DRIVERS_AND_TOURGUIDES,
  data,
});

export const fetchDriversAndTourGuides = () => (dispatch: Dispatch) => {
  dispatch(requestDriversAndTourguides());

  return axios
    .get(`${baseUrl.host}/api/drivers-and-tour-guides`, {
      withCredentials: true,
    })
    .then(response => {
      if (dispatch(responseHandler(response)) === responseType.SUCCESS) {
        dispatch(receiveDriversAndTourguides(response.data));
      }
    })
    .catch(error => {
      catchHandler(error);
    });
};

export default reducer;
