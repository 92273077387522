// @flow
/* eslint no-use-before-define: 0 */

import axios from 'axios';
import { normalize, schema } from 'normalizr';
import { responseType, responseHandler, catchHandler } from '../modules/auth';
import baseUrl from '../../utils/baseUrl';

import type { GetState, ThunkAction, Dispatch } from './index';

type InitTypesState = {
  +isFetching: boolean,
  +isPosting: boolean,
  +didInvalidate: boolean,
  +companyTypes: [],
  +companyTypesMapping: {},
  +jobTypes: [],
  +jobTypesByCompanies: [],
};

export type TypesState = {
  +isFetching: boolean,
  +isPosting: boolean,
  +didInvalidate: boolean,
  +companyTypes: NormalizedCompanyTypes,
  +companyTypesMapping: CompanyTypesMapping,
  +jobTypes: NormalizedJobTypes,
  +jobTypesByCompanies: NormalizedJobTypesByCompany,
};

type Types = {
  companyTypes: Array<{ id: number, title: string }>,
  jobTypes: Array<{ id: number, title: string }>,
};

export type TypesAction = RequestTypesAction | ReceiveTypesAction | NormalizedCompanyTypesAction;

const REQUEST_TYPES = '@@TGK/TYPES/REQUEST_TYPES';
const RECEIVE_TYPES = '@@TGK/TYPES/RECEIVE_TYPES';
const NORMALIZE_BY_COMPANY_TYPES = '@@TGK/TYPES/NORMALIZE_BY_COMPANY_TYPES';

type NormalizedCompanyTypes = {
  entities: {
    companyTypes: {
      [string]: {
        id: number,
        title: string,
      },
    },
  },
  result: Array<any>,
};

type NormalizedJobTypes = {
  entities: {
    jobTypes: {
      [string]: {
        id: number,
        title: string,
        companyTypeId: number,
      },
    },
  },
  result: Array<any>,
};

type NormalizedJobTypesByCompany = {
  entities: {
    jobTypes: Object,
  },
  result: Array<any>,
};

const normalizer = (data, schemaName: string): NormalizedCompanyTypes | NormalizedJobTypes => {
  const typesSchema = new schema.Entity(schemaName);
  const typesArray = new schema.Array(typesSchema);

  return normalize(data, typesArray);
};

type CompanyTypesMapping = {
  [string]: number,
};

const mapping = (data): CompanyTypesMapping =>
  data.reduce(
    (acc, cur) => ({
      ...acc,
      [`${cur.title}`]: cur.id,
    }),
    {}
  );

const normalizerByCompanyType = (originalData: Types, types: TypesState) => {
  const companyTypes = types.companyTypes.entities.companyTypes;
  const tgkSchema = new schema.Entity('1');
  const agentSchema = new schema.Entity('2');
  const hotelSchema = new schema.Entity('3');
  const busSchema = new schema.Entity('4');
  const typesArray = new schema.Array(
    {
      [`${1}`]: tgkSchema,
      [`${2}`]: agentSchema,
      [`${3}`]: hotelSchema,
      [`${4}`]: busSchema,
    },
    input => `${companyTypes[input.companyTypeId].id}`
  );

  // $FlowFixMe
  return normalize(originalData.jobTypes, typesArray);
};

const reducer = (
  state: InitTypesState = {
    isFetching: false,
    isPosting: false,
    didInvalidate: false,
    companyTypes: [],
    companyTypesMapping: {},
    jobTypes: [],
    jobTypesByCompanies: [],
  },
  action: TypesAction
) => {
  switch (action.type) {
    case REQUEST_TYPES: {
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    }
    case RECEIVE_TYPES: {
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        companyTypes: normalizer(action.data.companyTypes, 'companyTypes'),
        companyTypesMapping: mapping(action.data.companyTypes),
        jobTypes: normalizer(action.data.jobTypes, 'jobTypes'),
      });
    }
    case NORMALIZE_BY_COMPANY_TYPES: {
      return Object.assign({}, state, {
        jobTypesByCompanies: normalizerByCompanyType(action.originalData, action.types),
      });
    }
    default: {
      return state;
    }
  }
};

type RequestTypesAction = {
  type: '@@TGK/TYPES/REQUEST_TYPES',
};

// Fetch Types
const requestTypes = (): RequestTypesAction => ({
  type: REQUEST_TYPES,
});

type ReceiveTypesAction = {
  type: '@@TGK/TYPES/RECEIVE_TYPES',
  data: Types,
};

const receiveTypes = (data: Types): ReceiveTypesAction => ({
  type: RECEIVE_TYPES,
  data,
});

type NormalizedCompanyTypesAction = {
  type: '@@TGK/TYPES/NORMALIZE_BY_COMPANY_TYPES',
  originalData: Types,
  types: TypesState,
};

const normalizeByCompanies = (
  originalData: Types,
  types: TypesState
): NormalizedCompanyTypesAction => ({
  type: NORMALIZE_BY_COMPANY_TYPES,
  originalData,
  types,
});

const fetchTypes = () => (dispatch: Dispatch, getState: GetState) => {
  dispatch(requestTypes());

  return axios
    .get(`${baseUrl.host}/api/types`, { withCredentials: true })
    .then(response => {
      if (dispatch(responseHandler(response)) === responseType.SUCCESS) {
        dispatch(receiveTypes(response.data));
      }

      return response.data;
    })
    .then(data => {
      dispatch(normalizeByCompanies(data, getState().types));
    })
    .catch(error => {
      catchHandler(error);
    });
};

const shouldFetchTypes = (types: InitTypesState | TypesState): boolean => {
  if (types.companyTypes.length === 0) {
    return true;
  } else if (types.isFetching) {
    return false;
  }

  return types.didInvalidate;
};

export const fetchTypesIfNeeded = (): ThunkAction => (dispatch: Dispatch, getState: GetState) => {
  if (shouldFetchTypes(getState().types)) {
    return dispatch(fetchTypes());
  }

  return Promise.resolve();
};

export default reducer;
