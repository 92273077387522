// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import idx from 'idx';

import type { Element } from 'react';
import type { FormProps } from 'redux-form';

import InputField from '../Shared/InputField';
import SelectField from '../Shared/SelectField';
import DefaultPassword from '../DefaultPassword/DefaultPassword';
import jobTitleMapping from '../../utils/jobTitleMapping';

import type { TypesState } from '../../redux/modules/types';
import type { CompaniesState } from '../../redux/modules/companies';

import styles from './UserModal.module.scss';

const EMPTY_ERROR_TEXT = '必須項目です';

const validate = values => {
  const errors = {};

  ['company', 'jobType', 'mobilePhone', 'lastName', 'firstName'].forEach(field => {
    if (!values[field]) {
      errors[field] = EMPTY_ERROR_TEXT;
    }

    if (field === 'company' || field === 'jobType') {
      if (!idx(values[field], _ => _.id.value) && !idx(values[field], _ => _.id)) {
        errors[field] = {
          id: EMPTY_ERROR_TEXT,
        };
      }
    }
  });

  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'メールアドレスを正しく入力してください';
  }

  return errors;
};

type DropDownItem = {
  text: string,
  value: any,
};

const companyList = (companies: CompaniesState): Array<DropDownItem> => {
  const companiesOptions = idx(companies, _ => _.agentsAndBusCompanies.entities.companies);

  if (companiesOptions) {
    return Object.keys(companiesOptions).map(id => ({
      text: idx(companiesOptions, _ => _[id].name) || '',
      value: idx(companiesOptions, _ => _[id].id) || '',
    }));
  }

  return [];
};

const jobList = (
  t: string => string,
  hasCompanyId?: any,
  types: TypesState,
  companies: CompaniesState
) => {
  let companyId;
  let companyTypeId;
  let jobTypes;

  if (
    hasCompanyId &&
    companies.agentsAndBusCompanies.entities &&
    types.jobTypesByCompanies.entities
  ) {
    if (hasCompanyId.value) {
      companyId = hasCompanyId.value;
    } else {
      companyId = hasCompanyId;
    }

    companyTypeId = companies.agentsAndBusCompanies.entities.companies[companyId].companyType.id;
    jobTypes = types.jobTypesByCompanies.entities[companyTypeId];

    if (jobTypes) {
      return Object.keys(jobTypes).reduce((acc: any, cur: string) => {
        if (jobTypes[cur].title === 'tourGuide' || jobTypes[cur].title === 'driver') {
          return acc.concat({
            text: jobTitleMapping(jobTypes[cur].id, t),
            value: jobTypes[cur].id,
          });
        }

        return acc;
      }, []);
    }
  }

  return [];
};

type UserFormProps = FormProps & {
  t: string => string,
  hasCompanyId?: {
    text: string,
    value: any,
  },
  companies: CompaniesState,
  types: TypesState,
};

let Form = (props: UserFormProps): Element<'form'> => {
  const { t, handleSubmit, reset, companies, pristine, submitting, hasCompanyId, types } = props;

  const handleCancel = () => {
    reset();
    props.close();
  };

  const companyOptions = companyList(companies);
  const jobOptions = jobList(t, hasCompanyId, types, companies);

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <section className="form-title">
        <h2 className="title">{t('userForm.create')}</h2>
        <p className="sub">全てご記入ください</p>
      </section>
      <fieldset>
        <Field
          name="email"
          component={InputField}
          type="text"
          label={t('userForm.email')}
          placeholder={t('userForm.placeholder.email')}
        />
      </fieldset>
      <fieldset>
        <Field
          name="company.id"
          component={SelectField}
          data={companyOptions}
          valueField="value"
          textField="text"
          label={t('userForm.company')}
          placeholder={t('userForm.placeholder.company')}
          required
          onChange={(e, newValue, previousValue) => {
            const newId = (newValue && newValue.value) || newValue;
            const previousId = (previousValue && previousValue.value) || previousValue;

            if (newId !== previousId) {
              // Reset the jobType Id
              props.change('jobTypeId', null);
            }
          }}
        />
      </fieldset>
      <fieldset>
        <Field
          name="jobType.id"
          component={SelectField}
          data={jobOptions}
          valueField="value"
          textField="text"
          label={t('userForm.jobType')}
          placeholder={t('userForm.placeholder.jobType')}
          disabled={!hasCompanyId}
          required
        />
      </fieldset>
      <fieldset>
        <Field
          name="lastName"
          component={InputField}
          type="text"
          label={t('userForm.lastName')}
          placeholder={t('userForm.placeholder.lastName')}
          required
        />
      </fieldset>
      <fieldset>
        <Field
          name="firstName"
          component={InputField}
          type="text"
          label={t('userForm.firstName')}
          placeholder={t('userForm.placeholder.firstName')}
          required
        />
      </fieldset>
      <fieldset>
        <Field
          name="mobilePhone"
          component={InputField}
          type="number"
          label={t('userForm.mobilePhone')}
          placeholder={t('userForm.placeholder.mobilePhone')}
          required
        />
      </fieldset>
      <fieldset>
        <Field
          name="localPhone"
          component={InputField}
          type="number"
          label={t('userForm.localPhone')}
          placeholder={t('userForm.placeholder.localPhone')}
        />
      </fieldset>
      <div className="btn-group">
        <button className="btn" onClick={handleCancel} type="button">
          {t('button.cancel')}
        </button>
        <button
          className="btn primary"
          onSubmit={handleSubmit}
          type="submit"
          disabled={pristine || submitting}
        >
          {t('button.submit')}
        </button>
      </div>
    </form>
  );
};

Form = reduxForm({
  // a unique name for the form
  form: 'userModal',
  validate,
})(Form);

// Decorate with connect to read form values
const selector = formValueSelector('userModal'); // <-- same as form name

const UserForm = connect(state => {
  // can select values individually
  const hasCompanyId = selector(state, 'company.id');

  return {
    hasCompanyId,
  };
})(Form);

type Props = {
  t: string => string,
  close: () => void,
  password: string,
};

export default class UserModal extends PureComponent<Props> {
  render() {
    return (
      <div className={styles['modal-bg']}>
        <div className={styles.modal}>
          {this.props.password ? (
            <DefaultPassword
              password={this.props.password}
              t={this.props.t}
              done={this.props.close}
            />
          ) : (
            <UserForm {...this.props} />
          )}
        </div>
      </div>
    );
  }
}
