// @flow

import i18next from 'i18next';
import baseUrl from './baseUrl';

type MsgTypes = {
  CREATE_ORDER: string,
  UPDATE_ORDER: string,
  DELETE_ORDER: string,
  CREATE_ORDER_ROW: string,
  UPDATE_ORDER_ROW: string,
  APPROVE_ORDER_ROW: string,
  DELETE_ORDER_ROW: string,
  NEW_USER: string,
};

export const msgTypes: MsgTypes = {
  CREATE_ORDER: 'createOrder',
  UPDATE_ORDER: 'updateOrder',
  DELETE_ORDER: 'deleteOrder',
  CREATE_ORDER_ROW: 'createOrderRow',
  UPDATE_ORDER_ROW: 'updateOrderRow',
  APPROVE_ORDER_ROW: 'approveOrderRow',
  DELETE_ORDER_ROW: 'deleteOrderRow',
  NEW_USER: 'newUser',
};

type Msg = {
  subject: string,
  content: string,
};

type Mapping = {
  [string]: Msg,
};

type UrlMapping = {
  [string]: string,
};

export const msgMapping = (
  platform: 'sms' | 'mail',
  action: string,
  user: string,
  info: any
): Msg => {
  const urlMapping: UrlMapping = {
    [`${msgTypes.CREATE_ORDER}`]: `${baseUrl.site}/orders/${info.orderId}/rows`,
    [`${msgTypes.UPDATE_ORDER}`]: `${baseUrl.site}/orders/${info.orderId}/rows`,
    [`${msgTypes.DELETE_ORDER}`]: `${baseUrl.site}/orders`,
    [`${msgTypes.CREATE_ORDER_ROW}`]: `${baseUrl.site}/orders/${info.orderId}/rows/diff/${info.orderRowId}`,
    [`${msgTypes.UPDATE_ORDER_ROW}`]: `${baseUrl.site}/orders/${info.orderId}/rows/diff/${info.orderRowId}`,
    [`${msgTypes.APPROVE_ORDER_ROW}`]: `${baseUrl.site}/orders/${info.orderId}/rows/diff/${info.orderRowId}`,
    [`${msgTypes.DELETE_ORDER_ROW}`]: `{baseUrl.site}/orders/${info.orderId}/rows`,
    [`${msgTypes.NEW_USER}`]: `${baseUrl.site}/account`,
  };

  const mapping: Mapping = {
    [`${msgTypes.CREATE_ORDER}`]: {
      subject: i18next.t(`${platform}.createOrder.subject`),
      content: i18next.t(`${platform}.createOrder.content`, {
        user,
        url: urlMapping[action],
      }),
    },
    [`${msgTypes.UPDATE_ORDER}`]: {
      subject: i18next.t(`${platform}.updateOrder.subject`),
      content: i18next.t(`${platform}.updateOrder.content`, {
        user,
        url: urlMapping[action],
      }),
    },
    [`${msgTypes.DELETE_ORDER}`]: {
      subject: i18next.t(`${platform}.deleteOrder.subject`),
      content: i18next.t(`${platform}.deleteOrder.content`, {
        user,
        url: urlMapping[action],
      }),
    },
    [`${msgTypes.CREATE_ORDER_ROW}`]: {
      subject: i18next.t(`${platform}.createOrderRow.subject`),
      content: i18next.t(`${platform}.createOrderRow.content`, {
        user,
        url: urlMapping[action],
      }),
    },
    [`${msgTypes.UPDATE_ORDER_ROW}`]: {
      subject: i18next.t(`${platform}.updateOrderRow.subject`),
      content: i18next.t(`${platform}.updateOrderRow.content`, {
        user,
        url: urlMapping[action],
      }),
    },
    [`${msgTypes.APPROVE_ORDER_ROW}`]: {
      subject: i18next.t(`${platform}.approveOrderRow.subject`),
      content: i18next.t(`${platform}.approveOrderRow.content`, {
        user,
        url: urlMapping[action],
      }),
    },
    [`${msgTypes.DELETE_ORDER_ROW}`]: {
      subject: i18next.t(`${platform}.deleteOrderRow.subject`),
      content: i18next.t(`${platform}.deleteOrderRow.content`, {
        user,
        url: urlMapping[action],
      }),
    },
    [`${msgTypes.NEW_USER}`]: {
      subject: i18next.t(`${platform}.newUser.subject`),
      content: i18next.t(`${platform}.newUser.content`, {
        user,
        password: info.defaultPassword,
        url: urlMapping[action],
      }),
    },
  };

  return mapping[action];
};
